import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faEye, faThumbsDown, faThumbsUp} from "@fortawesome/free-regular-svg-icons";

const StateMatchFormatter = (data) =>{

    var state =  data.row.state === 0 ? "Refusé" : (data.row.state === 1 ? "En attente" : "Validé")

    var bolder = false
    if (data.row.state === 1){
        if (data.row.isCandidatSender){
            bolder = true
            state = "En attente de votre réponse."
        } else {
            state = "En attente de la réponse du candidat"
        }
    }
    return (
        <div className={"py-3"}>
            <p className={"italic"}>
                <span className={bolder ? "blink_me" : ""} style={{
                    color : bolder ? "#BB2687" : "black",
                    fontWeight : bolder ? "bolder" : "normal"
                }}>{state}</span>

                { data.row.state === 1 && data.row.isCandidatSender && <span className={"italic mt-0"}><br/>Cliquez ici pour traiter ce match.</span>}
            </p>

        </div>
    )
}

export default StateMatchFormatter
