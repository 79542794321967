import React, {useEffect, useState} from 'react';
import Loader from 'react-loader-spinner';

const LoaderCustom = () => {


    return (
        <div>
            <div style={{
                width : 80,
                height : 80,
                margin: "auto",
                position : "absolute",
                left : "50%",
                top : "50%",
                marginTop : -40,
                marginLeft : -40
            }}><Loader styles={"m-auto"} type="ThreeDots" color="#BB2687" height={80} width={80}/></div>
        </div>
    );
};

export default LoaderCustom;
