import React, {useEffect, useState} from 'react';


function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
        width,
        height
    };
}
const Background = () => {
    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());
    const [finalArr, setFinalArr] = useState([])

    useEffect(() => {
        function handleResize() {
            setWindowDimensions(getWindowDimensions());
        }

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);


    function getRandomInt(max) {
        return Math.floor(Math.random() * Math.floor(max));
    }
    function getRandomArbitrary(min, max) {
        return Math.random() * (max - min) + min;
    }

    if (finalArr.length === 0) {
        var arr = []
        var pos = []

        function has(top, left, width) {
            for (var i = 0; i < pos.length; i++) {
                var p = pos[i]
                if (left > p.left && left < p.left + p.width && top > p.top && top < p.top + width) {
                    return true
                }
                if (left + width > p.left && left + width < p.left + p.width && top + width > p.top && top + width < p.top + width) {
                    return true
                }
            }
            return false
        }

        var i = 0;
        while (i < 25) {
            const number = getRandomInt(15) + 1
            var width = getRandomArbitrary(20, 150)
            var top = getRandomArbitrary(0, windowDimensions.height)
            var left = getRandomArbitrary(0, windowDimensions.width)
            if (!has(top, left, width)) {
                pos.push({top: top, left: left, width: width})

                arr.push(<img key={"imagebackougrnd"+i} className={"mb-8 mx-auto"} alt={"logo"}
                              style={
                                  {
                                      width: width,
                                      position: 'absolute',
                                      zIndex: -1000,
                                      opacity: getRandomArbitrary(5, 30) / 100,
                                      top: top,
                                      left: left
                                  }}
                              src={process.env.PUBLIC_URL + '/assets/forme/forme' + number + '.png'}/>)
                i++
            }
        }
        setFinalArr(arr)
    }
    return (
        <div className={""}>
            <div style={{zIndex : -1, position: "absolute", top: 0, left : 0, overflow: "hidden", width : windowDimensions.width, height : windowDimensions.height}}>
            {finalArr}
            </div>
        </div>
    );
};

export default Background;
