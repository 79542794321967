import React, {useState, useEffect} from 'react';
import { useParams} from 'react-router-dom';
import 'react-confirm-alert/src/react-confirm-alert.css';
import "react-datepicker/dist/react-datepicker.css";
import roomServices from "../../services/room";
import UserTile from "../user/UserTile";
import MatchWaitingMe from "./MatchWaitingMe";
import MatchDecline from "./MatchDecline";
import MatchValidate from "./MatchValidate";
import ChatRoom from "./ChatRoom";

const MatchDetail = () => {
    const {id} = useParams();
    const [room, setRoom] = useState(null)

    const [loading, setLoading] = useState(false)
    const [waitingState, setWaitingState] = useState(null)

    useEffect(() => {
        retreiveCurrentMatchAction()
    }, [id])

    const retreiveCurrentMatchAction = async () => {
        var room = await roomServices.getRoomById(id)
        setRoom(room)

        if (room.state === 1){
            if (room.isCandidatSender){
                setWaitingState("me")
            } else {
                setWaitingState("him")
            }
        } else {
            setWaitingState(null)
        }
        setLoading(false)
    }

    console.log(room)

    return (
        <div className={"p-2 md:p-6 pb-12"}>
            {room &&
                <div className={"grid md:grid-cols-4 gap-4"}>

                        <div className={"md:col-span-1"}>
                            <UserTile   user={room._user}/>
                        </div>


                    {loading &&
                    <div className={"md:col-span-3"}>
                        <p>Chargement ...</p>
                    </div>
                    }
                    {!loading &&
                    <div className={"md:col-span-3"}>
                        {waitingState === "me" && <MatchWaitingMe room={room} onLoading={setLoading} onUpdate={() => {
                            retreiveCurrentMatchAction()
                        }}/>}
                        {room.state === 0 && <MatchDecline room={room}/>}
                        {room.state === 2 && <MatchValidate room={room}/>}
                        {room.state === 2 && <ChatRoom room={room}/>}

                    </div>
                    }
                </div>
            }
        </div>
    );
};

export default MatchDetail;
