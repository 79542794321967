import React from 'react';

const SquareButton = ({className, onClick, text, style, disabled}) => {
    return (
        <button disabled={disabled || false} style={style} className={`w-full transition-2 hover:opacity-75 rounded py-2 font-bold ${className}`}
                onClick={onClick}>
            {text}
        </button>
    );
};

export default SquareButton;
