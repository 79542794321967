import Cookies from 'js-cookie';
import React, { useContext, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { dev } from '../../config';
import accountServices from '../../services/account';
import User from '../../shared/class/User';
import { InfoContext } from '../../shared/context/InfoContext';
import UserContext from '../../shared/context/UserContext';
import { toastError } from '../../shared/function/generic';
import SquareButton from '../commons/SquareButton';
import SquareInput from '../commons/SquareInput';

const Login = () => {
  const [form, setForm] = useState({ email: '', password: '' });
  const [pwdType, setPwdType] = useState('password');
  const history = useHistory();
  const context = useContext(UserContext);
  const { user, setUser } = useContext(InfoContext);

  if (dev && form.email === '') {
    /*setForm({
            email : '',//'admin@snct.com',
            password : ''//'SNCT2021'
        })*/
  }
  const login = async () => {
    try {
      const login = await accountServices.login(form.email, form.password);
      const user = new User(login);
      var a = context.update(user);

      setUser(user);
      Cookies.set('hiofukt', user.token);
      Cookies.set('hiofuktperm', '1');

      history.push(user.getNextViewLogin());
    } catch (e) {
      toastError(e.data);
    }
  };

  return (
    <div className="grid grid-cols-2 w-max m-auto gap-2">
      <div style={{ width: 350, margin: 'auto' }} className={'border-1-1 border-2  bg-white p-4 rounded-lg bg-opacity-90'}>
        <p className="color-1-1 font-bold text-2xl mb-4  text-center">Je suis une entreprise : </p>
        <form
          autoComplete="on"
          onKeyPress={(e) => {
            e.key === 'Enter' && login() && e.preventDefault();
          }}
          className={'mb-4'}
        >
          <label className={'block font-semibold'}>{'Email'}</label>
          <SquareInput
            type={'text'}
            className={'focus:border-blue-300 focus:border-2 mb-5'}
            name={'email'}
            setValues={setForm}
            autoComplete={'email'}
            state={form}
            value={form.email}
            placeholder={''}
          />
          <div className={'w-full '}>
            <label className={'inline-block font-semibold'}>{'Mot de passe'}</label>
            <p
              onClick={() => {
                pwdType === 'text' ? setPwdType('password') : setPwdType('text');
              }}
              className={'inline-block float-right link cursor-pointer transition-2 font-bold text-sm color-1-1'}
            >
              {pwdType === 'text' ? 'Cacher' : 'Voir'}
              {' le mot de passe'}
            </p>
          </div>
          <SquareInput
            type={pwdType}
            className={'focus:border-blue-300 focus:border-2'}
            name={'password'}
            setValues={setForm}
            autoComplete={'current-password'}
            state={form}
            value={form.password}
            placeholder={''}
          />
          <div className={'mb-4'}>
            <Link className={'font-bold link cursor-pointer transition-2 text-xs color-1-1'} to="/auth/forgotpwd">
              &nbsp;{"J'ai oublié mon mot de passe"}
            </Link>
          </div>
        </form>
        <SquareButton onClick={() => login()} text={'Se connecter'} className={'color-button-violet'} />
        <SquareButton onClick={() => history.push('/auth/register')} text={"S'inscrire"} className={'color-button-violet-outline mt-2'} />
      </div>
      <div
        style={{ width: 350, margin: 'auto', height: '100%' }}
        className={'flex flex-col justify-between border-1-1 border-2 bg-white p-4 rounded-lg bg-opacity-90'}
      >
        <div>
          <p className="color-1-1 font-bold text-2xl mb-4  text-center">Je suis un candidat : </p>
          <p>Je télécharge l’application sur smartphone pour visualiser et postuler aux offres d’emploi disponibles.</p>
        </div>
        <SquareButton
          onClick={() => {
            window.open('https://ctmjobs.app.link');
          }}
          text={'Télécharger'}
          className={'color-button-violet'}
        />
      </div>
    </div>
  );
};

export default Login;
