import { css, StyleSheet } from 'aphrodite';
import i18n from 'i18next';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useHistory, useLocation } from 'react-router-dom';
import companyServices from '../../services/company';
import UserContext from '../../shared/context/UserContext';
import SquareButton from '../commons/SquareButton';
import NavItem from './NavItem';

const HeaderAdmin = () => {
  const lang = i18n.language;
  const { t } = useTranslation('common');
  const [isOpen, setIsOpen] = useState(false);
  const context = useContext(UserContext);
  const user = context.data.user;
  const [sourceHover, setSourceHover] = useState('');
  const [waitingRoom, setWaitingRoom] = useState([]);
  const [openMenu, setOpenMenu] = useState(false);
  const styles = StyleSheet.create({
    container: {
      height: 60,
      borderTopWidth: 3,
      borderTopColor: '#FFCE0A',
      background: ' linear-gradient(90deg, rgba(187,38,135,1) 0%, rgba(88,37,110,1) 100%)',
      width: '100%',
    },
    menuMobile: {
      zIndex: 1000,
      background: ' linear-gradient(90deg, rgba(187,38,135,1) 0%, rgba(88,37,110,1) 100%)',
      width: '100%',
    },
  });

  var routes = [
    {
      label: 'Entreprises',
      label_en: 'Entreprises',
      route: '/admin/company',
      auth: ['admin', 'worker', 'agent'],
      source: 'main',
    },
    {
      label: 'Configuration',
      label_en: 'Configuration',
      route: '/admin/config',
      auth: ['admin', 'worker', 'agent'],
      source: 'main',
    },
    {
      label: 'Data',
      label_en: 'Data',
      route: '/admin/data',
      auth: ['admin', 'worker', 'agent'],
      source: 'main',
    },
    {
      label: 'Actualités',
      label_en: 'News',
      route: '/admin/news',
      auth: ['admin', 'worker', 'agent'],
      source: 'main',
    },
  ];

  const { pathname } = useLocation();

  const history = useHistory();

  if (user && user.admin && pathname.indexOf('dashboardadmin') == -1) {
    //   history.push("/admin")
  }

  const disconnect = () => {
    console.log('Disconnect from header');
    history.push('/logout');
  };

  const reloadWaitingCount = async () => {
    const matchs = await companyServices.getMyCompanyMatch();
    setWaitingRoom(matchs.filter((elem) => elem.state === 1));
  };

  useEffect(() => {
    reloadWaitingCount();
  }, [pathname]);
  useEffect(() => {}, [history, pathname, routes]);

  return (
    <div>
      {/*MOBILE */}
      <div className={'sm:hidden'} style={{ height: 60 }}></div>
      <header className={css(styles.container) + ' justify-center absolute inset-y-0 left-0 flex items-center sm:hidden'}>
        <NavItem>
          <button
            type="button"
            onClick={() => setOpenMenu(!openMenu)}
            className="inline-flex items-center justify-center p-2 rounded-md text-white focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
            aria-controls="mobile-menu"
            aria-expanded="false"
          >
            <span className="sr-only">Open main menu</span>

            <svg className="block h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="#FFFFFF" aria-hidden="true">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 6h16M4 12h16M4 18h16" />
            </svg>
            <svg className="hidden h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="#FFFFFF" aria-hidden="true">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
            </svg>
          </button>
        </NavItem>
        <NavItem>
          <img className={'h-10  mr-5 ml-3'} alt="CTM" src={process.env.PUBLIC_URL + '/assets/logo/default_logo.png'} />
        </NavItem>

        <NavItem onClick={() => setIsOpen(!isOpen)}>
          <img
            className="h-10 rounded-full"
            style={{
              width: '2rem',
              height: '2rem',
            }}
            src={user && user._company && user._company.logo ? user._company.logo : process.env.PUBLIC_URL + '/assets/default_user.png'}
            alt="Company logo"
          />
        </NavItem>
      </header>
      {/* ----- MOBILE */}

      <header className={css(styles.container) + ` md:inline-block hidden `}>
        <div style={{ width: '99%' }} className={'h-full block my-0 mx-auto'}>
          <ul className={`h-full float-left`}>
            <div className="relative text-white h-full  float-left px-2  flex items-center justify-center ">
              <img className={'h-10  mr-5 ml-3'} alt="CTM" src={process.env.PUBLIC_URL + '/assets/logo/logonew.png'} />
            </div>

            <div className={'md:inline-block hidden h-full'}>
              {routes.map((route, index) => {
                return (
                  <Link
                    key={index}
                    to={route.route}
                    onMouseEnter={() => {
                      setSourceHover(route.source);
                    }}
                    onMouseLeave={() => {
                      setSourceHover('');
                    }}
                  >
                    <NavItem
                      pathname={pathname}
                      route={route}
                      subMenu={route.children}
                      sourceHover={sourceHover === route.source}
                      additionalClass={pathname === route.route && 'bg-white bg-opacity-10'}
                    >
                      {lang === 'en' ? route.label_en : route.label}

                      {route.badge === true && waitingRoom.length > 0 && (
                        <span
                          style={{
                            backgroundColor: 'white',
                            color: '#58256E',
                            width: 20,
                            height: 20,
                            textAlign: 'center',
                            marginLeft: 5,
                            display: 'inline-block',
                            borderRadius: 30,
                          }}
                        >
                          {waitingRoom.length}
                        </span>
                      )}
                    </NavItem>
                  </Link>
                );
              })}
            </div>
          </ul>
          <ul onClick={() => setIsOpen(!isOpen)} className={`h-full float-right`}>
            <NavItem>
              <img
                className="w-10 h-10 rounded-full"
                src={user && user._company && user._company.logo ? user._company.logo : process.env.PUBLIC_URL + '/assets/default_user.png'}
                alt="Company logo"
              />
            </NavItem>
          </ul>
        </div>
      </header>

      {openMenu && (
        <div className={css(styles.menuMobile) + ' sm:hidden shadow-lg absolute '} id="mobile-menu">
          <div className="px-2 pt-2 pb-3 space-y-1 flex flex-col">
            {routes.map((route, index) => {
              return (
                <Link
                  key={index}
                  onClick={() => {
                    setOpenMenu(false);
                  }}
                  to={route.route}
                  onMouseEnter={() => {
                    setSourceHover(route.source);
                  }}
                  onMouseLeave={() => {
                    setSourceHover('');
                  }}
                  className={'w-full'}
                  style={{ height: 50 }}
                >
                  <NavItem
                    pathname={pathname}
                    route={route}
                    subMenu={route.children}
                    sourceHover={sourceHover === route.source}
                    additionalClass={'w-full ' + pathname === route.route && 'bg-white bg-opacity-10 w-full'}
                  >
                    {lang === 'en' ? route.label_en : route.label}

                    {route.badge === true && waitingRoom.length > 0 && (
                      <span
                        style={{
                          backgroundColor: 'white',
                          color: '#58256E',
                          width: 20,
                          height: 20,
                          textAlign: 'center',
                          marginLeft: 5,
                          display: 'inline-block',
                          borderRadius: 30,
                        }}
                      >
                        {waitingRoom.length}
                      </span>
                    )}
                  </NavItem>
                </Link>
              );
            })}
          </div>
        </div>
      )}

      <div className={`bg-white rounded-lg py-2 absolute right-0 mr-2 mt-2 z-50 shadow-xl ${!isOpen && 'hidden'}`} style={{ width: 230 }}>
        <img
          className="mt-2 mx-auto w-16 h-16 rounded-full"
          src={user && user._company && user._company.logo ? user._company.logo : process.env.PUBLIC_URL + '/assets/default_user.png'}
          alt="Company logo"
        />
        <h1 className={'py-2 text-center font-bold'}>{user && user._company && user._company.denomination}</h1>

        <hr style={{ width: '90%' }} className={'mx-auto my-2'} />
        <h1 className={'px-4 pb-1 font-bold'}>{t('header.myAccount')}</h1>
        <a href={'/dashboard/myprofil'} className={'block px-4 text-gray-800 hover:opacity-75 transition-2'}>
          {t('header.myProfile')}
        </a>

        <hr style={{ width: '90%' }} className={'mx-auto my-2'} />
        <SquareButton
          text={t('header.disconnect')}
          onClick={() => disconnect()}
          className={'color-button text-center mx-auto'}
          style={{ width: '90%', marginRight: '10%', marginLeft: '5%' }}
        />
        <hr style={{ width: '90%' }} className={'mx-auto my-2'} />

        <a href={'#'} className={'block text-xs text-center px-4 py-1 text-gray-800 hover:opacity-75 transition-2'}>
          {t('header.contactUs')}
        </a>
      </div>
    </div>
  );
};
export default HeaderAdmin;
