import React, {useState, useEffect, useContext} from 'react';
import 'react-confirm-alert/src/react-confirm-alert.css';
import "react-datepicker/dist/react-datepicker.css";
import {hasReadMessage} from "../../shared/function/generic";
import roomServices from "../../services/room";
import WSManager from "../../shared/Singleton/WSManager";
import UserContext from "../../shared/context/UserContext";

const MessageItem = ({message}) => {


    const isMe = message.company != null
    return (
        <div className={"bg-gray-100 "}>
        <div className={"max-w-prose w-auto mx-4 my-2 p-2 rounded-lg " + (isMe ? " bg-green-300 float-right" : " bg-gray-300 w-max " )  }
        >{message.content}</div>

        </div>
    )
}


const ChatRoom = ({room}) => {

    var context = useContext(UserContext)
    var [messages, setMessages] = useState([])
    var [newMessage, setNewMessage] = useState("")



    useEffect(() => {
       reloadData()
    }, [room])

    useEffect(() => {
        WSManager.shared().addListener("newMessage", "RoomDetail", async (data) => {
            console.log("RELOAD DATA GET NEW MESSAGE")
          await reloadData()
        })


        return function cleanupListener() {
            WSManager.shared().removeListener("newMessage", "RoomDetail")
        }
    }, [])

    const reloadData = async () => {
        if (room) {
            var messages = await roomServices.getMessages(room._id)
            setMessages(messages)
        }
    }

    const sendMessage = async () => {

        if (!newMessage || newMessage.length == 0){
            return
        }
        var tmp = messages
        var newMsg = {
            type : "text",
            content : newMessage,
            tmpId : "ID" + Math.random(),
            company : "_"
        }
        setMessages([newMsg].concat(tmp))

        await roomServices.sendMessage(room._id, {
            type:  "text",
            content : newMessage,
            tmpId : "ID"+Math.random()
        })
        setNewMessage("")
    }

    const checkReadMessage = async (elem) =>{
        if (!hasReadMessage(context.data.user.company, elem)){
            console.log("COUCOCOUCU")
            await roomServices.readMessage(elem._id)
        }
    }

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            sendMessage()
        }
    }

    return (
        <div className=" border-2 border-1-2 relative bg-gray-100  shadow overflow-hidden sm:rounded-lg w-full h-screen-2div3">
            <div class="bg-gray-100  chat-viewer flex flex-col-reverse overflow-y-auto">
                {messages.map((elem, index) => {
                    checkReadMessage(elem)
                    return <MessageItem key={"message"+index} message={elem}/>
                })}

            </div>
            <div className="absolute w-full flex bottom-0 justify-between main-bg-1 chat-sender" styles="bottom: 0px;">
                  <input
                    className="flex-grow m-2 py-2 px-4 mr-1 rounded border border-gray-300 bg-gray-200 resize-none"
                    rows="1"
                    placeholder="Message..."
                    styles="outline: none;"
                    value={newMessage}
                    onKeyDown={handleKeyDown}
                    onChange={(e) => {
                        const {value} = e.target;
                        setNewMessage(value)
                    }}
                    />

                <button className="m-2" styles="outline: none;" onClick={sendMessage}>
                    <svg
                        className="svg-inline--fa text-white fa-paper-plane fa-w-16 w-12 h-12 py-2 mr-2"
                        aria-hidden="true"
                        focusable="false"
                        data-prefix="fas"
                        data-icon="paper-plane"
                        role="img"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 512 512"
                    >
                        <path
                            fill="currentColor"
                            d="M476 3.2L12.5 270.6c-18.1 10.4-15.8 35.6 2.2 43.2L121 358.4l287.3-253.2c5.5-4.9 13.3 2.6 8.6 8.3L176 407v80.5c0 23.6 28.5 32.9 42.5 15.8L282 426l124.6 52.2c14.2 6 30.4-2.9 33-18.2l72-432C515 7.8 493.3-6.8 476 3.2z"
                        />
                    </svg>
                </button>
            </div>

        </div>
    );
};

export default ChatRoom;
