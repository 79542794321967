import React from 'react';
import { useHistory } from 'react-router-dom';
import SquareButton from '../components/commons/SquareButton';

const NotFound = () => {
  const history = useHistory();

  return (
    <div className={'flex h-screen'}>
      <div className={'m-auto text-center'}>
        <img className={'mb-8 mx-auto'} width={250} alt={'logo'} src={process.env.PUBLIC_URL + '/assets/logo/logonew.png'} />
        <h1 className={'text-6xl font-extrabold'}>Oops !</h1>
        <p className={'text-3xl font-bold'}>404 - Page non trouvée</p>
        <p>La page que vous cherchez n'existe pas.</p>
        <SquareButton
          onClick={() => {
            history.push('/');
          }}
          text={'Retourner en lieu sûr'}
          className={'color-button mt-5'}
        />
      </div>
    </div>
  );
};

export default NotFound;
