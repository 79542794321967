import React, {useState, useEffect} from 'react';
import 'react-confirm-alert/src/react-confirm-alert.css';
import "react-datepicker/dist/react-datepicker.css";
import Modal from 'react-modal';
import SquareButton from "./SquareButton";
import SquareInput from "./SquareInput";
import utilsServices from "../../services/utils";
import {toastError} from "../../shared/function/generic";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTimesCircle} from "@fortawesome/free-solid-svg-icons";


const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        maxWidth : "80%",
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)'
    }
};

const UploadFileModal = ({isOpen, close}) => {

    const [form, setForm] = useState({url : ""})
    const [type, setType] = useState(null)
    const [event, setEvents] = useState([])
    const [inputKey, setInputKey] = useState(Date.now())
    var _fileInput = null
    const [file, setFile] = useState([])
    const [isSending, setIsSending] = useState(false)

    useEffect(() => {
        setForm({url : ""})
        setType(null)
        setInputKey(Date.now())
        setFile([])
    }, [isOpen])

    const onChangeHandler = async (event) => {
        setEvents(event.target.files)
        //console.log(event.target.files)
        //uploadFile(event.target.files[0])


    }

    const uploadFiles = async () => {
        if (event ) {
            setIsSending(true)
            var result = []
            for (var i = 0; i < event.length; i++) {
               const url = await uploadFile(event[i])
                if (url){
                    result.push(url)
                }
            }
            setFile(result)
            setIsSending(false)
        }
    }
    useEffect(() => {
        uploadFiles()
    }, [event])

    const uploadFile = async (fileObj, number) => {
        if (fileObj == null){
            return null
        }
      //  setIsSending(true)
        const response = await utilsServices.uploadFile(fileObj)

        //utilsServices.uploadFile(fileObj).then(response => {
            const url = response.url
            //console.log(file)
            //var newFIle = [...file]
            //console.log("NEW FILE ")
            //console.log(newFIle)
            if (url){
               // newFIle.push(url)
               // console.log(newFIle)
               // setFile(newFIle)
                return url
            } else {
                toastError("Impossible d'envoyer l'image")
                return null
            }

            //setIsSending(false)
       // })
    }

    return (

        <Modal isOpen={isOpen}
               style={customStyles}
               shouldCloseOnOverlayClick={true}
               onRequestClose={() => {
                   close(null)
               }}>

            {!type &&
            <div>
                <p>Que souhaitez-vous ajouter ?</p>

                <div className={"grid grid-cols-2 gap-4 mt-2"}>
                    <SquareButton onClick={() => {
                        setType("photo")
                    }} text={"Des photos"} className={"color-button-violet"}/>

                    <SquareButton onClick={() => {
                        setType("video")
                    }} text={"Une vidéo"} className={"color-button-violet"}/>

                </div>
            </div>
            }
            {type === "video" &&
            <div>
                <p>Renseignez l'addresse URL Youtube de votre vidéo</p>
                <SquareInput type={"text"} className={"focus:border-blue-300 focus:border-2 mb-4"} name={"url"}
                             setValues={setForm}
                             autoComplete={"url"}
                             state={form} value={form.url} placeholder={""}/>
                <SquareButton onClick={() => {
                    if (form.url.indexOf("youtube") === -1 || form.url.indexOf("v=") === -1){
                        toastError("Merci de renseigner un lien Youtube uniquement")
                    } else {
                        close(form.url, "video")
                    }
                }} text={"Valider"} className={"color-button-violet"}/>
            </div>
            }
            {type === "photo" &&
            <div>
                <p>Ajoutez les photos que vous souhaitez</p>


                {file.length > 0 && !isSending && <div  className={" overflow-scroll justify-center content-center transition mt-2 mb-2"} style={{  maxHeight : "80%"}}>

                    <div className={"grid  gap-2 " + (file.length === 1 ? "grid-cols-1" : (file.length === 2 ? "grid-cols-2" : "grid-cols-3 "))}>
                        {file.map((elem, index) => {
                            return <div className={"relative"}>
                                <button

                                    className={"absolute right-0 top-1"}
                                    onClick={() => {
                                        var f = [...file]
                                        f.splice(index, 1)
                                        setFile(f)

                                    }
                                    }>
                                    <FontAwesomeIcon style={{marginRight: 5, color : "#BB2687" , fontSize : 22}} icon={faTimesCircle}/>
                                </button>
                                <img alt={"logo"} src={elem} style={{ objectFit : "contain", width : "100%", height : "100%", maxHeight : "50vh", maxWidth : "50vw"}}/>
                            </div>
                        })}

                    </div>
                </div>}

                {(file.length === 0 || isSending) &&
                <div onClick={() => _fileInput.click()}
                     className={"flex  overflow-scroll justify-center content-center cursor-pointer hover:bg-gray-200 hover:text-gray-100 transition mt-2 mb-2"}
                     style={{maxHeight: "80%"}}>

                    {isSending && <div className="loader"></div>}
                    {file.length == 0 && !isSending && <p style={{fontSize: 40}}> + </p>}

                    {file.length == 0 && !isSending &&
                    <input ref={fileInput => _fileInput = fileInput} type="file" multiple name="file" key={inputKey}
                           onChange={onChangeHandler} className={"hidden"} accept="image/*"/>}
                </div>
                }


                <SquareButton onClick={() => {
                    close(file, "photo")
                }} text={"Valider"} className={"color-button-violet"}/>
            </div>
            }

        </Modal>

    );
};

export default UploadFileModal;
