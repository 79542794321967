
import {getAuthToken} from "../shared/function/generic";
import request from "../shared/lib/AxiosWrap";



async function uploadFile(file,) {
    var formData = new FormData()
    formData.append("fileObject", file)
    return  request({
        url: `/v1/utils/upload`,
        method: 'PUT',
        data: formData,
        headers: {
            'Authorization': getAuthToken(),
            'Content-Type': 'multipart/form-data'
        }
    })
}
const utilsServices = {
    uploadFile
};

export default utilsServices;