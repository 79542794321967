import React, {useState, useContext, useEffect} from 'react';
import SquareInput from "../../commons/SquareInput";
import {useHistory, Link} from "react-router-dom";
import SquareButton from "../../commons/SquareButton";
import accountServices from "../../../services/account";
import {toastError} from "../../../shared/function/generic";
import User from "../../../shared/class/User";
import {dev} from '../../../config';
import Cookies from 'js-cookie';
import UserContext from "../../../shared/context/UserContext";
import configsServices from "../../../services/config";
import {arrayMove, SortableContainer, SortableElement, sortableHandle} from 'react-sortable-hoc';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowsAltV, faTimesCircle} from "@fortawesome/free-solid-svg-icons";

const DragHandle = sortableHandle(() => <span><FontAwesomeIcon style={{marginRight: 5, color :  "#BB2687", fontSize : 18}} icon={faArrowsAltV}/>
          </span>);

const SortableItem = SortableElement(({value, updateItem, onDeleteItem}) =>
    <li className={"bg-white px-4 py-2  w-full"} style={{display : "flex"}}>
        <DragHandle />
        <input className={` bg-gray-50 outline-none  rounded border p-2 py-1 border-gray-300 flex-grow`}
               type="text" value={value} onChange={updateItem} />
        <button
            className={"ml-2"}
            onClick={() => {
                onDeleteItem()
            }
            }>
            <FontAwesomeIcon style={{marginRight: 5}} icon={faTimesCircle}/>
        </button>
    </li>
);

const SortableList = SortableContainer(({items, updateItems}) => {
    return (
        <ul>
            {items.map((value, index) => (
                <SortableItem key={`item-`+value.id} index={index} value={value.value} updateItem={(event) => {
                    items[index].value =  event.target.value
                    updateItems(items)
                }} onDeleteItem={() => {
                    items.splice(index, 1);
                    updateItems(items)
                }} />
            ))}
        </ul>
    );
});

const ConfigOneElem = ({configTemplate, name, title, onUpdate, className = ""}) => {

    const [config, setConfig] = useState(configTemplate)
    const [newValue, setNewValue] = useState("")




    useEffect(() => {
        setConfig(configTemplate)
    }, [configTemplate])

    const onSortEnd = ({oldIndex, newIndex}) => {

        var olds = config[name]

        updateConfig({...config, [name] : arrayMove(olds, oldIndex, newIndex)})
    }

    const updateConfig = (value) => {
        setConfig(value)
        onUpdate(name, value[name])
    }
    const setForm = (a) => {
        setNewValue(a.newContractType)

    }
    if (!config){
        return (<div></div>)
    }

    return (
        <div className={"w-full bg-white p-4 border-gray-300 border-2 " + className}>
            <p className={"text-xl font-bold"}>{title}</p>


            <div className={"items-center justify-between mb-4"} style={{display : "flex"}}>
                <p>Ajouter :</p>
                <SquareInput type={"text"} className={"w-full focus:border-blue-300 flex-grow focus:border-2 mx-4"} parentClassName={"flex-grow"} name={"newContractType"}
                             setValues={setForm}
                             autoComplete={"newContractType"}
                             state={{}} value={newValue} placeholder={""}/>

                <SquareButton onClick={() => {
                    var items = config[name]
                    items.push({ id : Math.random(), value : newValue})
                    updateConfig({...config, items})
                    setNewValue("")
                }} text={"Ajouter"} className={"color-button-violet"} style={{width  :100}}/>


            </div>

            {config[name].length == 0 && <p>Aucun élément</p>}
            <SortableList distance={1} items={config[name]} onSortEnd={onSortEnd}  updateItems={(items) => {
                updateConfig({
                    ...config,
                    [name]: items
                })
            }}/>

        </div>
    );
};

export default ConfigOneElem;
