import { faEye, faThumbsDown, faThumbsUp } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import 'react-confirm-alert/src/react-confirm-alert.css';
import ReactDataGrid from 'react-data-grid';
import { useHistory } from 'react-router-dom';
import jobsServices from '../../services/jobs';
import { toastError } from '../../shared/function/generic';
import Pagination from '../commons/Pagination';
import SquareButton from '../commons/SquareButton';
import StatistiqueFormatter from './StatistiqueFormatter';

const JobsList = () => {
  const [infos, setInfos] = useState(null);
  const [jobs, setJobs] = useState([]);
  const [loading, setLoading] = useState(false);
  const history = useHistory();

  const fetchData = async (page = 0) => {
    console.log('FETCH DATA');
    setLoading(true);
    try {
      const response = await jobsServices.get(page);
      setInfos(response);
      setJobs(response.results);
      console.log(response);
    } catch (e) {
      toastError('Erreur lors de la récupération des offres');
    }
    setLoading(false);
  };

  const columns = [
    { key: 'name', name: 'Name' },
    { key: 'contractType', name: 'Type de contrat' },
    { key: 'stats', name: 'Statistique', formatter: StatistiqueFormatter },
  ];

  const create = async () => {
    history.push('/dashboard/jobs/create');
  };
  const moveToDetails = async (id) => {
    history.push(`/dashboard/jobs/${id}`);
  };
  const rowHeight = 50;

  const rowRenderer = ({ renderBaseRow, ...props }) => {
    var row = props.row || { archived: false };
    return <div style={{ color: row.archived ? '#bd2b18' : 'black' }}>{renderBaseRow(props)}</div>;
  };

  const rowStyle = ({ data }) => {
    const colorMap = {
      ca: '#7986cb',
      uk: '#ef9a9a',
    };

    return {
      color: colorMap[data.country],
    };
  };

  useEffect(() => {
    if (infos == null && !loading) {
      console.log('Reload data ' + loading + ' ' + infos);
      fetchData();
    }
  }, []);

  return (
    <div className={'p-6 pb-12'}>
      <div>
        <h1 className={'title-page inline-block font-bold'}>Liste de vos offres d'emploi</h1>
        <div className={'grid md:grid-cols-4 lg:grid-cols-6'}>
          <SquareButton onClick={() => create()} text={'+ Ajouter'} className={'color-button-violet md:col-start-4 lg:col-start-6'} />
        </div>

        {/*} <LoaderComponent fetch={fetchData} dependency={infos.page || null}> */}
        <div className={'md:hidden'}>
          {!loading && Object.keys(jobs).length > 0 ? (
            <div className={'mt-3'}>
              {jobs.map((elem, index) => {
                var totalSwipe = elem.totalSwipe;
                var acceptSwipe = elem.acceptSwipe;
                var declineSwipe = elem.declineSwipe;

                return (
                  <div
                    className={'bg-white p-4 mb-4 border-2 border-1-2 rounded ' + (elem.archived ? 'bg-red-50' : '')}
                    onClick={() => moveToDetails(elem._id)}
                  >
                    <p className={'text-xl font-bold'}>{elem.name}</p>
                    <p className={'mb-2'}>{elem.contractType}</p>
                    <div
                      style={{
                        display: 'flex',
                      }}
                      className={'flex-col'}
                    >
                      <p className={'w-full text-center'}>{elem.archived && 'Archivée'}</p>
                      <div
                        style={{
                          display: 'flex',
                        }}
                      >
                        <p
                          style={{
                            flex: '1 1 0',
                            textAlign: 'center',
                          }}
                        >
                          <FontAwesomeIcon style={{ marginRight: 5 }} icon={faEye} />
                          {totalSwipe}
                        </p>

                        <p
                          style={{
                            flex: '1 1 0',
                            textAlign: 'center',
                          }}
                        >
                          <FontAwesomeIcon style={{ marginRight: 5 }} icon={faThumbsUp} />
                          {acceptSwipe}{' '}
                        </p>
                        <p
                          style={{
                            flex: '1 1 0',
                            textAlign: 'center',
                          }}
                        >
                          <FontAwesomeIcon style={{ marginRight: 5 }} icon={faThumbsDown} />
                          {declineSwipe}
                        </p>
                      </div>
                    </div>
                  </div>
                );
              })}
              <Pagination fetch={fetchData} nbPages={infos.maxPage} page={parseInt(infos.page)} />
            </div>
          ) : loading ? (
            <p>Chargement ...</p>
          ) : (
            <p>Aucune offre d'emploi</p>
          )}
        </div>
        <div className={'md:block hidden'}>
          {!loading && Object.keys(jobs).length > 0 ? (
            <div className={'mt-3'}>
              <ReactDataGrid
                rowStyle={rowStyle}
                onRowClick={(i) => i >= 0 && moveToDetails(jobs[i]._id)}
                columns={columns}
                rowRenderer={rowRenderer}
                rowGetter={(i) => {
                  var doc = jobs[i] || {};

                  return doc;
                }}
                rowsCount={jobs.length}
                rows={jobs}
                rowHeight={rowHeight}
                minHeight={(jobs.length + 1) * rowHeight}
              />
              <Pagination fetch={fetchData} nbPages={infos.maxPage} page={parseInt(infos.page)} />
            </div>
          ) : loading ? (
            <p>Chargement ...</p>
          ) : (
            <p>Aucune offre d'emploi</p>
          )}
        </div>
      </div>
    </div>
  );
};

export default JobsList;
