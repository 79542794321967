import configsServices from '../../services/config';

const getScoreInt = (company, step, form) => {
  var total = 0;
  var current = 0;

  step.form.forEach((elem) => {
    if (elem.form) {
      var result = getScoreInt(company, elem, form);
      total += result.total;
      current += result.current;
    } else {
      total += elem.score;
      if (elem.idCompany) {
        current += company[elem.idCompany] || 0;
      } else {
        current += form[elem.id] ? elem.score : 0;
      }
    }
  });

  console.log('--->');
  console.log({ total: total, current: current });
  return { total: total, current: current };
};

export const scoreCompany = async (company) => {
  var score = {
    employerBrand: false,
    youngIntegration: false,
    tutor: false,
    percentageApprentice: 0,
    traineeNumber: 0,
    taxLearning: false,
    workPromotion: false,
    sse: 0,
    ...company.score,
  };

  const step = await configsServices.getNotations();
  console.log(step);
  var current = 0;

  var tot = 0;
  step.forEach((elem) => {
    if (elem.form) {
      console.log('1');
      var result = getScoreInt(company, elem, score);
      current += result.current;
      tot += result.total;
    } else {
      console.log('2');
      if (elem.idCompany) {
        current += company[elem.idCompany] || 0;
      } else {
        current += score[elem.id] ? elem.score : 0;
      }
    }
  });
  console.log(current);
  console.log('TOT ' + tot);
  console.log('--->' + parseFloat(parseFloat(current / 20).toFixed(2)));

  return parseFloat(parseFloat(current / 20).toFixed(2));
};
