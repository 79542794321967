import React, { useContext } from 'react';
import { Route, Switch, useHistory, useRouteMatch } from 'react-router-dom';
import Header from '../../components/sections/Header';

import LoaderComponent from '../../components/commons/Loader';
import UserContext from '../../shared/context/UserContext';
import CompanyDetailAdmin from './CompanyDetailAdmin';
import CompanyListAdmin from './CompanyListAdmin';
import AdministrationConfig from './config/AdministrationConfig';
import DataAdmin from './DataAdmin';
import JobDetailAdmin from './JobDetailAdmin';
import HeaderAdmin from '../sections/HeaderAdmin';
import CompanyNews from "./CompanyNews";

const DashboardAdmin = () => {
  let { url } = useRouteMatch();
  const context = useContext(UserContext);
  const history = useHistory();
  const fetchCurrent = async () => {};

  return (
    <div>
      <HeaderAdmin />
      <LoaderComponent fetch={fetchCurrent}>
        <div className={'h-full-header'}>
          <Switch>
            <Route exact path={`${url}/config`}>
              <AdministrationConfig />
            </Route>
            <Route exact path={`${url}/company`}>
              <CompanyListAdmin />
            </Route>
            <Route exact path={`${url}/company/:id`}>
              <CompanyDetailAdmin />
            </Route>
            <Route exact path={`${url}/jobs/:id`}>
              <JobDetailAdmin />
            </Route>
            <Route exact path={`${url}/data`}>
              <DataAdmin />
            </Route>
            <Route exact path={`${url}/news`}>
              <CompanyNews />
            </Route>
          </Switch>
        </div>
      </LoaderComponent>
    </div>
  );
};

export default DashboardAdmin;
