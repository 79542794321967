import React, { useContext, useEffect, useState } from 'react';
import 'react-confirm-alert/src/react-confirm-alert.css';
import ReactDataGrid from 'react-data-grid';
import 'react-datepicker/dist/react-datepicker.css';
import { useHistory } from 'react-router-dom';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import companyServices from '../../services/company';
import jobsServices from '../../services/jobs';
import LoaderCustom from '../../shared/class/LoaderCustom';
import UserContext from '../../shared/context/UserContext';
import { hasReadRoom, toastError } from '../../shared/function/generic';
import CandidatMatchFormatter from './formatter/CandidatMatchFormatter';
import LastMessageMatchFormatter from './formatter/LastMessageMatchFormatter';
import StateMatchFormatter from './formatter/StateMatchFormatter';
import TitleMatchFormatter from './formatter/TitleMatchFormatter';
const animatedComponents = makeAnimated();

const MatchsList = () => {
  const [indexList, setIndexList] = useState(1);

  const context = useContext(UserContext);

  var [waitingRoom, setWaitingRoom] = useState([]);
  var [validateRoom, setValidateRoom] = useState([]);
  var [refusedRoom, setRefusedRoom] = useState([]);
  var [allRoom, setAllRoom] = useState([]);
  var [filter, setFilter] = useState([]);
  const [loading, setLoading] = useState(false);
  const history = useHistory();

  const [jobs, setJobs] = useState([]);
  const reloadJobList = async () => {
    try {
      const response = await jobsServices.getAll();
      setJobs(
        response.results.map((elem) => {
          return {
            value: elem._id,
            label: elem.name,
          };
        })
      );
      console.log(response);
    } catch (e) {
      toastError('Erreur lors de la récupération des offres');
    }
  };
  const reloadData = async () => {
    setLoading(true);
    const matchs = await companyServices.getMyCompanyMatch();
    console.log(matchs);
    setWaitingRoom(matchs.filter((elem) => elem.state === 1));
    setValidateRoom(matchs.filter((elem) => elem.state === 2));
    setRefusedRoom(matchs.filter((elem) => elem.state === 0));
    setAllRoom(matchs);
    reloadJobList();
    setLoading(false);
  };

  useEffect(() => {
    reloadWithFilter();
  }, [allRoom, filter]);

  const reloadWithFilter = async () => {
    if (filter.length > 0) {
      var ids = filter.map((elem) => elem.value);
      setWaitingRoom(allRoom.filter((elem) => elem.state === 1 && elem._job && ids.indexOf(elem._job._id) !== -1));
      setValidateRoom(allRoom.filter((elem) => elem.state === 2 && elem._job && ids.indexOf(elem._job._id) !== -1));
      setRefusedRoom(allRoom.filter((elem) => elem.state === 0 && elem._job && ids.indexOf(elem._job._id) !== -1));
    } else {
      setWaitingRoom(allRoom.filter((elem) => elem.state === 1));
      setValidateRoom(allRoom.filter((elem) => elem.state === 2));
      setRefusedRoom(allRoom.filter((elem) => elem.state === 0));
    }
  };

  var currentList = indexList === 0 ? validateRoom : indexList === 1 ? waitingRoom : refusedRoom;
  useEffect(() => {
    reloadData();
  }, []);

  var columns = [
    { key: 'interestedby', name: 'Intéréssé par', formatter: TitleMatchFormatter },
    { key: 'name', name: 'Candidat', formatter: CandidatMatchFormatter },
    { key: 'state', name: 'Statut', formatter: StateMatchFormatter },
  ];
  if (indexList === 0) {
    columns = [
      { key: 'interestedby', name: 'Intéréssé par', formatter: TitleMatchFormatter },
      { key: 'name', name: 'Candidat', formatter: CandidatMatchFormatter },
      { key: 'lastmsg', name: 'Dernier message', formatter: LastMessageMatchFormatter },
      { key: 'state', name: 'Statut', formatter: StateMatchFormatter },
    ];
  }

  const moveToDetails = async (id) => {
    history.push(`/dashboard/matchs/${id}`);
  };

  const handleInputChange = (newValue, b) => {
    console.log(newValue);
    console.log(b);
    console.log('----');
    setFilter(newValue);
  };

  return (
    <div className={'p-6 pb-12'}>
      <div className={'mb-4'}>
        <div>
          <h1 className={'title-page inline-block font-bold'}>Liste de vos matchs</h1>
        </div>

        <div className={'grid md:grid-cols-2 gap-4 mt-4 mb-4'}>
          <div className={'hidden md:block'}>
            <p className={'font-thin text-sm'}>Trier par offres d'emploi</p>
            <Select
              closeMenuOnSelect={false}
              components={animatedComponents}
              placeholder={'Selectionner'}
              isMulti
              onChange={handleInputChange}
              options={jobs}
            />
          </div>
          <div className={'md:col-start-4 text-center'}>
            <span
              className={
                (indexList === 0 ? 'main-bg-1 text-white' : 'border-1-1 color-1-1 border') + ' cursor-pointer inline-block  rounded-l-lg mt-2'
              }
              style={indexList === 0 ? { padding: 8 } : { padding: 7, paddingLeft: 8, paddingRight: 8 }}
              onClick={() => {
                setIndexList(0);
              }}
            >
              Validé
            </span>
            <span
              className={(indexList === 1 ? 'main-bg-1 text-white' : 'border-1-1 color-1-1 border') + ' cursor-pointer inline-block  mt-2'}
              style={indexList === 1 ? { padding: 8 } : { padding: 7, paddingLeft: 8, paddingRight: 8 }}
              onClick={() => {
                setIndexList(1);
              }}
            >
              En attente{' '}
              <span
                style={{
                  fontSize: 15,
                  fontWeight: 'bold',
                  backgroundColor: indexList === 1 ? 'white' : '#58256E',
                  color: indexList === 1 ? '#58256E' : 'white',
                  width: 20,
                  height: 20,
                  textAlign: 'center',
                  marginLeft: 5,
                  display: 'inline-block',
                  borderRadius: 30,
                }}
              >
                {waitingRoom.length}
              </span>
            </span>
            <span
              className={
                (indexList === 2 ? 'main-bg-1 text-white' : 'border-1-1 color-1-1 border') + ' cursor-pointer inline-block rounded-r-lg mt-2'
              }
              style={indexList === 2 ? { padding: 8 } : { padding: 7, paddingLeft: 8, paddingRight: 8 }}
              onClick={() => {
                setIndexList(2);
              }}
            >
              Refusé
            </span>
          </div>

          <div className={'md:hidden'}>
            <p className={'font-thin text-sm'}>Trier par offres d'emploi</p>
            <Select
              closeMenuOnSelect={false}
              components={animatedComponents}
              placeholder={'Selectionner'}
              isMulti
              onChange={handleInputChange}
              options={jobs}
            />
          </div>
        </div>
      </div>

      {!loading && (
        <div className={'hidden md:block'}>
          <ReactDataGrid
            onRowClick={(i) => i >= 0 && moveToDetails(currentList[i]._id)}
            columns={columns}
            rowGetter={(i) => {
              var doc = currentList[i] || {};
              var hasRead = hasReadRoom(context.data.user.company, doc);
              doc.hasRead = hasRead;
              return doc;
            }}
            rowsCount={currentList.length}
            rows={currentList}
            rowHeight={50}
            minHeight={(currentList.length + 1) * 50 + 2}
          />
        </div>
      )}

      {!loading && (
        <div className={'md:hidden'}>
          {currentList.map((elem, index) => {
            var type = '';
            var title = '';
            if (elem._job) {
              type = 'Offre';
              title = elem._job.name;
            } else if (elem._company) {
              type = 'Entreprise';
              title = elem._company.denomination;
            }

            var userTitle = elem._user.firstName + ' ' + elem._user.lastName;
            var hasRead = elem.hasRead;
            var sender = null;
            var message = '';
            var messages = elem.messages;
            if (messages && messages.length > 0) {
              if (message._user) {
                sender = message._user.firstName + ' ' + message._user.lastName + ' :';
              } else {
                sender = message.content;
              }
              message = message.content;
            }

            var state = elem.state === 0 ? 'Refusé' : elem.state === 1 ? 'En attente' : 'Validé';

            if (elem.state === 1) {
              if (elem.isCandidatSender) {
                state = 'En attente de votre réponse.';
              } else {
                state = 'En attente de la réponse du candidat';
              }
            }
            var msgState = elem.state === 1 && elem.isCandidatSender && 'Cliquez ici pour traiter ce match';

            return (
              <div
                className={'bg-white p-4 mb-4 border-2 border-1-2 rounded ' + (elem.archived ? 'bg-red-50' : '')}
                onClick={() => moveToDetails(elem._id)}
              >
                <p>
                  <span className={'text-xl font-bold'}>
                    {type} {title}
                  </span>
                </p>
                <p className={''}>{userTitle}</p>
                {(sender || message) && (
                  <p>
                    {sender || ''} : {message || ''}
                  </p>
                )}
                <p className={'italic mt-4'}>{state}</p>
                <p className={'italic'}>{msgState}</p>
              </div>
            );
          })}
        </div>
      )}
      {!loading && currentList.length == 0 && <p className={'text-center italic my-4'}>Aucun match</p>}
      {loading && <LoaderCustom />}
    </div>
  );
};

export default MatchsList;
