import React, { useContext, useEffect, useState } from "react";

export const PrivacyView = () => {
    return (
        <div className={"rounded-xl p-1 md:w-3/4 lg:w-2/4 text-black"} style={{ margin: "auto" }}>
            <div>
                <p>
                    <b />
                </p>
                <h1 style={{ textAlign: "start", color: "black", fontSize: "28px" }}>
                    <b>Politique de confidentialité</b>
                </h1>
                <p />
                <p style={{ textAlign: "start", color: "black", fontSize: "18px" }}>
                    Dernière modification : 23 mai 2018
                    <br />
                    Le respect de votre confidentialité est ancré dans notre ADN. Depuis que nous avons créé UseKey, nous avons souhaité proposer nos services tout en gardant à
                    l'esprit un ensemble de principes forts en matière de confidentialité dans le traitement des données utilisateurs des solutions que nous éditions.
                    <br />
                    <br />
                    Si vous résidez dans un pays de l'Espace économique européen (Union européenne incluse) ou dans tout autre pays ou territoire inclus, vos services sont fournis
                    par UseKey, responsable du traitement de vos données lorsque vous utilisez nos services. La propriété des données collectées est quant à elle directement et
                    tacitement transférée à l’entité cliente (raison sociale) utilisatrice de nos services à destination de ses utilisateurs qui applique leurs propres politiques
                    de confidentialité. Le traitement des données peut dans certains cas être aussi transféré à l’entité bénéficiaire de nos services. Dans ces 2 cas, il convient
                    de se reporter aux CGU et à la politique de confidentialité dont les dispositions RGPD qui leurs sont propres.
                    <br />
                    <br />
                    Si vous résidez dans tout autre pays, vos services sont fournis eux aussi par UseKey qui est également responsable du traitement de vos données, et nous
                    appliquons les mêmes conditions de traitement et de transfert de propriété quelque soit le pays où nos services sont utilisés, hors cas spécifique, notamment si
                    le pays de destination à des obligations qui dépassent celles prévues par le RGPD.
                </p>
                <div style={{ textAlign: "start", color: "black", fontSize: "18.5px" }}>
                    Notre Politique de confidentialité (" Politique de confidentialité ") expose nos pratiques en matière d'informations (y compris de messagerie), et notamment des
                    informations que nous traitons dans le cadre de nos Services. Nous présentons par exemple les informations que nous collectons et les conséquences que cela a
                    sur vous. Nous vous expliquons également les mesures que nous prenons pour protéger la confidentialité de vos informations personnelles, telles que concevoir
                    nos solutions pour que les messages qui y sont envoyés ne soient pas stockés et vous donner le contrôle sur les personnes avec qui vous communiquez via les
                    services mis à disposition au bénéficiaire pour ses utilisateurs ; &nbsp;La présente Politique de confidentialité s'applique à tous nos Services, sauf mention
                    contraire.
                </div>
                <p style={{ textAlign: "start", color: "black", fontSize: "18px" }}>
                    Veuillez également consulter les Condition générales d’utilisation de UseKey ("Conditions d'utilisation ") qui décrivent les conditions régissant votre
                    utilisation de nos Services.
                </p>
                <p style={{ textAlign: "start", color: "black", fontSize: "18px" }}>Informations que nous recueillons</p>
                <p style={{ textAlign: "start", color: "black", fontSize: "18px" }}>
                    Les solutions UseKey doivent recevoir ou recueillir des informations pour exploiter, fournir, améliorer, comprendre, personnaliser, prendre en charge et
                    commercialiser ses Services, y compris lorsque vous installez ou utilisez nos Services ou que vous y accédez. Les types d'informations que nous recevons et
                    recueillons dépendent de la manière dont vous utilisez nos Services.
                </p>
                <p style={{ textAlign: "start", color: "black", fontSize: "18px" }}>
                    Informations que vous fournissez :<br />
                    <br />• Vos informations de compte. Vous fournissez votre numéro de téléphone mobile et des informations de base (y compris un nom de profil) pour créer un
                    compte sur les applicatifs que nous éditons aux lois applicables. Vous pouvez nous fournir une adresse e-mail. Vous pouvez également ajouter d'autres
                    informations dans votre compte, comme une photo de profil et des informations à votre sujet.
                </p>
                <p style={{ textAlign: "start", color: "black", fontSize: "18px" }}>
                    <br />• Vos messages. Nous conservons vos messages durant la prestation de nos Services. Une fois que vos messages (y compris vos discussions, photos, vidéos,
                    messages vocaux, fichiers et informations de partage de la localisation). Si un message ne peut pas être transmis immédiatement (par exemple si vous êtes hors
                    ligne), votre message n’est pas conservé sur nos serveurs et est supprimé. Pour améliorer les performances et transmettre les messages multimédias plus
                    efficacement, comme lorsqu'un grand nombre de personnes partagent une photo ou une vidéo populaire, nous pouvons conserver plus longtemps ce contenu sur nos
                    serveurs.
                    <br />
                    <br />• Vos connexions. Pour vous aider à organiser vos communications, nous pouvons vous aider à identifier les personnes qui utilisent nos solutions parmi vos
                    contacts. Vous pouvez créer ou rejoindre des groupes et des listes de diffusion, ou y être ajouté(e), et ceux-ci sont ensuite associés aux informations
                    relatives à votre compte.
                </p>
                <p style={{ textAlign: "start", color: "black", fontSize: "18px" }}>
                    <br />
                    <br />
                    Informations collectées automatiquement
                    <br />
                    <br />• Informations relatives à l'utilisation et à la connexion. Nous recueillons les informations concernant votre activité dans nos Services, telles que les
                    informations relatives aux services, diagnostics et performances. Cela inclut les informations concernant votre activité (y compris votre utilisation de nos
                    Services, vos paramètres et réglages dans nos Services, vos interactions avec les autres utilisateurs de nos Services ainsi que l'heure, la fréquence et la
                    durée de vos activités et interactions), vos fichiers journaux, les diagnostics, les incidents, les sites web ainsi que les journaux et rapports de
                    performances. Cela inclut également les informations concernant le moment où vous vous êtes inscrit(e) pour utiliser nos Services, les fonctionnalités que vous
                    utilisez, telles que notre messagerie, le statut ou les fonctionnalités de groupe, la photo de profil, l'actu, si vous êtes en ligne ou non, la dernière fois
                    que vous avez utilisé nos Services (votre " vu à ") et la dernière fois que vous avez mis à jour les informations de votre actu.
                    <br />
                    <br />• Informations sur l'appareil et données de connexion. Nous collectons des informations propres aux appareils et aux connexions lorsque vous installez nos
                    Services, que vous y accédez ou que vous les utilisez. Cela inclut les informations telles que le modèle de matériel, les informations sur le système
                    d'exploitation, le niveau de batterie, la force du signal, la version de l'application, les informations du navigateur, le réseau mobile, les données de
                    connexion, l'opérateur mobile ou le FAI, la langue et le fuseau horaire, l'IP, les informations concernant les activités sur l'appareil et les identifiants tels
                    que les identifiants de l'appareil.
                    <br />
                    <br />• Informations de localisation. Selon les fonctionnalités retenues sur les solutions éditées, nous pouvons collectons des informations de localisation de
                    l'appareil si vous utilisez nos fonctionnalités de localisation, comme lorsque vous choisissez de partager votre localisation avec vos contacts, de voir les
                    localisations proches ou celles que d'autres ont partagées avec vous à des fins de services contextuel, et à des fins de diagnostic et de dépannage, comme
                    lorsque vous avez des problèmes avec la fonctionnalité de localisation de notre application. Nous utilisons différentes technologies pour déterminer la
                    localisation, y compris l'IP, le GPS, les signaux Bluetooth et les informations concernant les points d'accès Wi-Fi, les balises et les tours de
                    télécommunication à proximité. &nbsp;
                    <br />
                    <br />
                    Informations de tiers
                    <br />
                    <br />• Informations à votre sujet fournies par des tiers. Nous recevons des informations à votre sujet de la part d'autres utilisateurs et entreprises. Par
                    exemple, lorsque des utilisateurs ou des entreprises que vous connaissez utilisent nos Services et que vous êtes invité à utiliser nos services par leurs
                    initiatives, peuvent fournir votre numéro de téléphone, votre nom et d'autres informations (comme des informations de leur carnet d'adresse mobile ou, dans le
                    cas d'entreprises, des informations supplémentaires vous concernant, telles que des identifiants uniques), et vous pouvez fournir les leurs, ou ils peuvent vous
                    envoyer un message, vous appeler ou envoyer des messages à des groupes dont vous faites partie. Nous exigeons que chaque utilisateur et chaque entreprise soit
                    légalement autorisé à recueillir, à utiliser ou à partager vos informations avant de nous les fournir.
                    <br />
                    <br />• Fournisseurs de services tiers. Nous travaillons avec des fournisseurs de services tiers afin de nous permettre d'exploiter, fournir, améliorer,
                    comprendre, personnaliser, prendre en charge et commercialiser nos Services. Par exemple, nous travaillons avec des entreprises afin de diffuser nos
                    applications, fournir notre infrastructure, les livrables et autres systèmes, donner des informations concernant la localisation, les cartes et les lieux,
                    traiter les paiements, nous permettre de savoir comment les personnes utilisent nos Services et de promouvoir nos Services, vous permettre de vous connecter à
                    des entreprises à l'aide de nos Services, mener des études et des recherches pour nous, et aider le service client. Ces entreprises peuvent nous communiquer des
                    informations vous concernant dans certaines circonstances. Les boutiques d'applications peuvent par exemple nous fournir des rapports pour nous aider à
                    diagnostiquer et à résoudre certains problèmes.
                    <br />
                    <br />• Services tiers. Nous vous permettons d'utiliser nos Services en lien avec des services tiers. Si vous utilisez nos Services en lien avec des services
                    tiers, nous pouvons recevoir des informations vous concernant, par exemple si vous utilisez le bouton de partage pour partager un article avec vos contacts,
                    groupes ou listes de diffusion dans le cadre de nos Services, ou si vous choisissez d'accéder à nos Services au moyen d’une API d’une connexion telle que
                    Linked’in, Facebook ou encore Tweeter ou autres systèmes de type ERP. Veuillez noter que lorsque vous utilisez les services de tiers, les conditions et
                    politiques de confidentialité de ces derniers régissent ces services.
                </p>
                <p style={{ textAlign: "start", color: "black", fontSize: "18px" }}>Notre utilisation des informations</p>
                <p style={{ textAlign: "start", color: "black", fontSize: "18px" }}>
                    Nous utilisons les informations dont nous disposons (en fonction de vos choix) pour exploiter, fournir, améliorer, comprendre, personnaliser, prendre en charge
                    et commercialiser nos Services. Voici de quelle façon :<br />
                    <br />• Nos Services. Nous utilisons les informations dont nous disposons afin d'exploiter et fournir nos Services, ce qui comprend le fait de fournir
                    l'assistance utilisateur et d'améliorer, corriger et personnaliser nos Services. Nous comprenons la façon dont les gens utilisent nos Services, et nous
                    analysons et utilisons les informations dont nous disposons pour évaluer et améliorer nos Services, rechercher, développer et tester de nouveaux services et de
                    nouvelles fonctionnalités, et réaliser des activités de dépannage. Nous nous servons également de vos informations pour vous répondre lorsque vous nous
                    contactez.
                    <br />
                    <br />• Protection et sécurité. Nous effectuons une vérification des comptes et des activités, et veillons à la sécurité dans nos services et en dehors. Nous
                    pouvons, par exemple, examiner toute activité suspecte ou toute infraction de nos Conditions d'utilisation et nous assurer que nos Services sont utilisés
                    légalement.
                    <br />
                    <br />• Communications concernant nos Services. Nous utilisons les informations dont nous disposons pour communiquer avec vous au sujet de nos Services et
                    fonctionnalités, et nous vous faisons part de nos conditions et de nos politiques ainsi que de mises à jour importantes. Nous pouvons vous envoyer des
                    publicités pour nos Services.
                    <br />
                    <br />• Bannière de publicité tierce. Nous pouvons autoriser les bannières de publicités tierces selon les solutions éditées et mesurer la performance de ces
                    bannières. &nbsp;
                    <br />
                    <br />• Messages Commerciaux. Nous vous autoriserons vous et les tiers, tels que des entreprises, à communiquer ensemble en utilisant nos services, notamment
                    pour des commandes, transactions et informations de rendez-vous, notifications d’alertes, des mises à jour de produits et service, ... Par exemple, vous
                    pourriez recevoir une notification pour être alerté d’une nouveauté. Les messages comprenant du contenu marketing que vous pourriez recevoir pourraient inclure
                    une offre pour quelque chose susceptible de vous intéresser. Nous ne voulons pas que votre expérience soit gâchée par du contenu indésirable. &nbsp;
                    <br />
                    <br />• Mesures, Analytics et autres services professionnels. Nous aidons les entreprises qui utilisent nos solutions à mesurer leur efficacité et la
                    distribution de leurs services et messages, et à savoir comment les gens interagissent avec elles dans nos Services.
                </p>
                <p style={{ textAlign: "start", color: "black", fontSize: "18px" }}>Informations que vous et nous partageons</p>
                <p style={{ textAlign: "start", color: "black", fontSize: "18px" }}>
                    Vous communiquez vos informations lorsque vous utilisez nos Services et que vous communiquez grâce à eux, et nous partageons vos informations pour nous
                    permettre d'exploiter, de fournir, d'améliorer, de comprendre, de personnaliser, de prendre en charge et de commercialiser nos Services.
                    <br />
                    <br />• Envoyer vos informations aux personnes avec qui vous avez choisi de communiquer. Vous partagez vos informations (y compris les messages) lorsque vous
                    utilisez nos Services et communiquez grâce à eux.
                    <br />
                    <br />• Informations sur le compte. Votre numéro de téléphone, vos informations de profil, les informations vous concernant, vos informations " vu à " et
                    confirmations sont consultables par toutes les personnes qui utilisent nos Services, selon les conditions de visibilités de ces informations de l’entité
                    bénéficiaire qui exploite et vous met à disposition nos services. &nbsp;
                    <br />
                    <br />• Vos contacts et les autres. Les utilisateurs et entreprises avec qui vous communiquez ou qui utilisent les mêmes services peuvent stocker ou partager
                    vos informations (notamment votre numéro de téléphone ou vos messages) avec d'autres personnes sur nos Services ou ailleurs. &nbsp;
                    <br />
                    <br />• Entreprises sur les solutions UseKey. Nous aidons les entreprises qui utilisent nos solutions à mesurer leur efficacité et la distribution de leurs
                    services et messages, et à savoir comment les gens interagissent avec elles dans nos Services.
                    <br />
                    <br />• Fournisseurs de services tiers. Nous travaillons avec des fournisseurs de services tiers afin de nous permettre d'exploiter, fournir, améliorer,
                    comprendre, personnaliser, prendre en charge et commercialiser nos Services. Lorsque nous partageons des informations avec des fournisseurs de services tiers de
                    cette manière, nous leur demandons d'utiliser vos informations au nom du bénéficiaire. Veuillez noter que lorsque nous sollicitons des fournisseurs de services
                    tiers, leurs propres conditions et politiques de confidentialité régissent votre utilisation de ces services.
                    <br />
                    <br />• Services tiers. Lorsque vous avez recours à des services tiers intégrés à nos Services, ils peuvent recevoir des informations sur ce que vous partagez
                    avec eux. Par exemple, si vous utilisez un service de sauvegarde de données intégré à nos Services (comme iCloud ou Google Drive), ce service peut recevoir des
                    informations que vous partagez avec lui. Si vous interagissez avec un service tiers lié à nos Services, il se peut que vous fournissiez des informations
                    directement à ce tiers. Veuillez noter que lorsque vous utilisez des services de tiers, leurs propres conditions et politiques de confidentialité régissent
                    votre utilisation de ces services.
                </p>
                <p style={{ textAlign: "start", color: "black", fontSize: "18px" }}>Cession, changement de contrôle et transfert</p>
                <p style={{ textAlign: "start", color: "black", fontSize: "18px" }}>
                    La totalité de nos droits et obligations dans le cadre de notre Politique de confidentialité est librement transférable par nous à toute société affiliée dans
                    le cadre d'une fusion, d'une acquisition, d'une restructuration ou d'une vente d'actifs, d'une demande des tribunaux ou dans d'autres cas, et nous pouvons
                    transférer vos informations à nos sociétés affiliées, successeurs qui assurerons le transfert des données utilement collectées.
                </p>
                <p style={{ textAlign: "start", color: "black", fontSize: "18px" }}>
                    Comment le Règlement général sur la protection des données s'applique à nos utilisateurs de la Région européenne
                </p>
                <p style={{ textAlign: "start", color: "black", fontSize: "18px" }}>
                    Nos fondements légaux pour le traitement des informations Nous recueillons, utilisons et partageons les informations dont nous disposons comme indiqué
                    ci-dessous :<br />
                    <br />• dans la mesure nécessaire pour remplir nos conditions ;<br />• selon votre consentement, que vous pouvez retirer à tout moment ;<br />• dans la mesure
                    nécessaire pour remplir nos obligations légales ;<br />• occasionnellement, pour protéger vos intérêts vitaux ou ceux des autres ;<br />• dans la mesure
                    nécessaire dans l'intérêt du public ;<br />• dans la mesure nécessaire pour nos intérêts légitimes (ou ceux des autres), notamment nos intérêts quant à la
                    fourniture d'un service innovant, pertinent, sûr et rentable à l'attention de nos utilisateurs, partenaires, et bénéficiaires, sauf si ces intérêts sont
                    supplantés par vos intérêts ou vos libertés et droits fondamentaux exigeant la protection de vos données personnelles. &nbsp;
                    <br />
                    <br />
                    Comment exercer vos droits :<br />‍<br />
                    En vertu du Règlement général sur la protection des données ou de toute autre législation applicable, vous avez le droit d’effectuer une demande de
                    consultation, de rectification, de transfert et de suppression de vos informations. Vous pouvez également limiter le traitement de vos informations ou vous y
                    opposer. Cela inclut le droit de vous opposer à notre traitement de vos informations pour du marketing direct et le droit de vous opposer à notre traitement de
                    vos informations lorsque nous effectuons une tâche dans l'intérêt public ou que nous poursuivons nos intérêts légitimes ou ceux d'un tiers. Vous pouvez accéder
                    à vos informations sur demande écrite à UseKey – Service Utilisateurs – 165 avenue de Bretagne / Euratechnologies – 59000 LILLE. &nbsp;Vous pouvez accéder aux
                    outils afin de rectifier, mettre à jour et effacer vos informations directement dans les solutions que nous éditons, tel que décrit dans la section Gérer et
                    supprimer vos informations. Si nous traitons vos informations sur la base de nos intérêts légitimes, des intérêts des tiers ou dans l'intérêt public, vous
                    pouvez vous opposer à ce traitement. Nous cesserons alors de traiter vos informations, à moins que le traitement repose sur des raisons impérieuses et légitimes
                    ou qu'il soit justifié d'un point de vue légal ou fonctionnel. Vous pouvez également vous opposer à notre traitement de vos informations et en savoir plus sur
                    les options disponibles pour limiter notre utilisation de vos informations. Lorsque nous utilisons vos informations pour le marketing direct de nos propres
                    Services, vous pouvez à tout moment vous opposer aux futurs messages de marketing et vous désinscrire à l'aide du lien de désinscription inclus dans ces
                    communications.
                </p>
                <p style={{ textAlign: "start", color: "black", fontSize: "18px" }}>Gestion et suppression de vos informations</p>
                <p style={{ textAlign: "start", color: "black", fontSize: "18px" }}>
                    Nous stockons les informations jusqu'à ce qu'il ne soit plus nécessaire de fournir nos services ou jusqu'à ce que votre compte soit supprimé, selon la première
                    de ces éventualités. Il s'agit d'une décision au cas par cas qui dépend d'aspects tels que la nature des informations, la raison de leur collecte et de leur
                    traitement et les besoins de conservations légaux ou opérationnels concernés.
                    <br />
                    <br />
                    Si vous souhaitez gérer, modifier, limiter ou supprimer vos informations, nous vous permettons de le faire avec les outils suivants :<br />• Modification de
                    votre numéro de téléphone mobile, de votre nom et de votre photo de profil, et de votre actu. Vous devez modifier votre numéro de téléphone mobile à l'aide de
                    notre fonctionnalité de changement de numéro intégrée dans nos solutions quand ce champs d’information est disponible. Vous pouvez également modifier votre nom
                    et votre photo de profil, votre actu et mots clés à tout moment.
                    <br />• Suppression de votre compte sur l’un de nos services. Vous pouvez supprimer votre compte sur nos service (notamment si vous voulez retirer votre
                    consentement relatif à notre utilisation de vos informations) à l'aide de notre fonctionnalité de suppression de compte intégrée à la plupart des solutions
                    éditées pour nos clients, ou en faire la demande auprès du Bénéficiaire / entité qui met à disposition nos services à ses utilisateurs. &nbsp;Lorsque vous
                    supprimez votre compte, vos messages non envoyés sont supprimés de nos serveurs, ainsi que toute autre information dont nous n'avons plus besoin pour exploiter
                    et fournir nos Services. Gardez à l'esprit que si vous supprimez nos Services de votre appareil sans faire appel à la suppression de votre compte, nous pouvons
                    stocker vos informations plus longtemps. N'oubliez pas que la suppression de votre compte n'a aucune incidence sur les informations dont d'autres personnes
                    disposent à votre sujet, comme les copies des messages que vous leur avez envoyés ou vos publications.
                </p>
                <p style={{ textAlign: "start", color: "black", fontSize: "18px" }}>Droit et protection</p>
                <p style={{ textAlign: "start", color: "black", fontSize: "18px" }}>
                    Nous collectons, utilisons, conservons et partageons vos informations si nous pensons de bonne foi qu'elles sont nécessaires, dans la limite du raisonnable,
                    pour : (a) répondre à des procédures juridiques ou à des demandes gouvernementales conformément à la législation ou à la réglementation applicables ; (b)
                    appliquer nos Conditions d'utilisation ainsi que toute autre condition ou politique applicables, notamment pour les examens d'éventuelles infractions ; (c)
                    détecter, examiner, prévenir et traiter la fraude et toute autre activité illégale ou les problèmes techniques ou de sécurité ; (d) protéger les droits, la
                    propriété et la sécurité des personnes, notamment pour empêcher tout décès ou toute blessure corporelle imminents.
                </p>
                <p style={{ textAlign: "start", color: "black", fontSize: "18px" }}>Nos activités internationales</p>
                <p style={{ textAlign: "start", color: "black", fontSize: "18px" }}>
                    UseKey peut etre amené à développer des solutions à destination d’une utilisation hors du territoire Européen, hors du territoire d’application de la RGPD. Dans
                    ce cas, UseKey a fait le choix de maintenir les mêmes conditions de protection des données personnelles lorsqu’elles sont applicables dans le pays de
                    destination.
                </p>
                <p style={{ textAlign: "start", color: "black", fontSize: "18px" }}>Mises à jour de notre Politique</p>
                <p style={{ textAlign: "start", color: "black", fontSize: "18px" }}>
                    Nous vous informerons avant d'effectuer toute modification de la présente Politique de confidentialité et vous donnerons la possibilité de consulter la version
                    révisée avant que vous choisissiez de continuer à utiliser les Services.
                </p>
                <p style={{ textAlign: "start", color: "black", fontSize: "18px" }}>Coordonnées</p>
                <p style={{ textAlign: "start", color: "black", fontSize: "18px" }}>
                    Si vous êtes dans la Région européenne
                    <br />
                    Il est possible de contacter le Délégué à la protection des données pour UseKey Pour toute question concernant notre Politique de confidentialité, veuillez nous
                    contacter par courrier à : &nbsp; UseKey Attn: Privacy Policy &nbsp;165 avenue de Bretagne / Euratechnologies &nbsp;59000 LILLE &nbsp;
                    <br />
                    Si vous n'êtes pas dans la Région européenne
                    <br />
                    Pour toute question concernant notre Politique de confidentialité, veuillez nous contacter par courrier à : &nbsp;UseKey Attn : Privacy Policy 165 avenue de
                    Bretagne / Euratechnologies. &nbsp;59000 Lille.
                </p>
                <p style={{ textAlign: "start", color: "black", fontSize: "18px" }}>Notre traitement de vos informations</p>
                <p style={{ textAlign: "start", color: "black", fontSize: "18px" }}>
                    Conformément à la loi européenne, les entreprises doivent disposer d’une base légale pour traiter des données. Vous avez des droits particuliers en fonction de
                    la base légale que nous utilisons. Nous vous expliquons ces droits ci-dessous. Sachez que, quelle que soit la base légale utilisée, vous avez toujours le droit
                    de demander l’accès à vos données, leur rectification et leur suppression conformément au Règlement général sur la protection des données (« RGPD »). &nbsp;
                    <br />
                    <br />
                    Pour toutes les personnes ayant la capacité de contracter, le traitement de données que nous réalisons est nécessaire à l’exécution du contrat auquel vous êtes
                    partie. Dans le cadre de la fourniture de nos services au titre du contrat, les usages principaux des données sont :<br />• fournir, améliorer, personnaliser et
                    prendre en charge nos Services comme décrit dans « Nos Services » ;<br />• favoriser la sécurité ;<br />• stocker ou traiter vos données dans l’UE ;<br />•
                    communiquer avec vous, par exemple, au sujet de problèmes liés aux Services. &nbsp;
                    <br />
                    <br />
                    Ces usages sont expliqués plus en détail dans notre Politique de confidentialité. Nous utiliserons les données en notre possession afin d’offrir ces services.
                    Si vous choisissez de ne pas fournir certaines données, cela peut affecter la qualité de votre expérience avec les solutions que nous éditons. &nbsp;
                    <br />
                    Lorsque nous traitons les données que vous nous transmettez et qui sont nécessaires à l’exécution du contrat auquel vous êtes partie, vous avez le droit de
                    demander à ce qu’elles bénéficient de la portabilité conformément au RGPD. Pour exercer vos droits, reportez vous à notre Politique de confidentialité. &nbsp;
                    <br />
                    <br />
                    Les autres bases légales sur lesquelles nous nous appuyons dans certaines circonstances, lorsque nous traitons vos données, sont les suivantes :<br />
                    Votre consentement :<br />• la collecte et l’utilisation des informations que nous sommes autorisés à recevoir par l’intermédiaire des paramètres ou réglages de
                    l’appareil que vous avez activé (comme l’accès à vos coordonnées GPS, à votre appareil photo ou à vos photos) afin que nous puissions proposer les
                    fonctionnalités et les services décrits lorsque vous activez les paramètres ou réglages.
                    <br />
                    <br />
                    Lorsque nous traitons les données que vous nous communiquez avec votre consentement, vous avez le droit de retirer votre consentement à tout moment et de
                    demander à ce que ces données que vous nous avez communiquées bénéficient de la portabilité conformément au RGPD.
                    <br />
                    <br />
                    <br />
                    Nos intérêts légitimes ou ceux d’un tiers, à moins que ne prévalent vos intérêts ou vos libertés et droits fondamentaux (« intérêts légitimes ») :<br />
                    Pour les personnes qui n’ont pas atteint l’âge de la majorité (moins de 18 ans dans la plupart des pays européens) et qui ne sont pas entièrement habilitées à
                    conclure un contrat exécutoire, il est possible que nous ne puissions pas traiter les données personnelles sur la base de la nécessité contractuelle. Toutefois,
                    lorsqu’une telle personne utilise nos Services, les points suivants sont dans nos intérêts légitimes :<br />• fournir, améliorer, personnaliser et prendre en
                    charge nos Services ;<br />• favoriser la sécurité ; et
                    <br />• communiquer avec vous, par exemple, au sujet de problèmes liés aux Services. Les intérêts légitimes sur lesquels nous nous appuyons pour ce traitement
                    sont les suivants :<br />• créer, fournir, prendre en charge et maintenir des fonctionnalités et des Services innovants qui permettent aux personnes n’ayant pas
                    atteint l’âge de la majorité de s’exprimer, de communiquer, de découvrir des informations et des entreprises susceptibles de les intéresser et d’interagir avec
                    elles, de créer une communauté et d’utiliser les outils et les fonctionnalités qui améliorent leur bien-être.
                    <br />• sécuriser notre plate-forme et notre réseau, vérifier les comptes et leur activité, lutter contre les comportements nuisibles, détecter et éviter les
                    contenus indésirables et autres mauvaises expériences, veiller à ce que nos Services soient exempts de contenu nuisible ou inapproprié, enquêter sur les
                    activités suspectes ou les violations de nos conditions ou règles et assurer la protection des personnes qui n’ont pas atteint l’âge de la majorité, notamment
                    en évitant les exploitations et autres dangers auxquels ces personnes sont particulièrement exposées.
                    <br />
                    <br />
                    <br />
                    Pour tout le monde, y compris les personnes mineures :<br />• fournir des mesures, des analyses et d’autres services professionnels dans le cadre desquels nous
                    traitons des données en tant que responsable du traitement. Les intérêts légitimes sur lesquels nous nous appuyons pour ce traitement sont les suivants :<br />•
                    fournir des rapports précis et fiables à des entreprises et autres partenaires, pour garantir une tarification juste et des statistiques précises sur les
                    performances, et pour démontrer la valeur réalisée par nos partenaires grâce à nos Services ; et
                    <br />• dans l’intérêt des entreprises, bénéficiaires, et autres partenaires, pour les aider à comprendre leurs clients utilisateurs et à améliorer leurs
                    affaires, à valider nos modèles de tarification et à évaluer l’efficacité et la distribution de leurs services et messages, et comprendre la façon dont les
                    personnes interagissent avec eux dans le cadre de nos Services.
                    <br />• vous fournir des communications marketing. Les intérêts légitimes sur lesquels nous nous appuyons pour ce traitement sont les suivants :<br />•
                    promouvoir les services UseKey et ceux de nos partenaires et effectuer du marketing direct ;<br />• échanger des informations, notamment avec les forces de
                    l’ordre, et répondre aux demandes légales. Les intérêts légitimes sur lesquels nous nous appuyons pour ce traitement sont les suivants :<br />• empêcher et
                    combattre la fraude, les violations de nos conditions ou politiques, ou toute autre activité nuisible ou illégale ; nous protéger nous-mêmes (notamment nos
                    droits, notre propriété ou nos Produits), nos bénéficiaires, notamment dans le cadre d’enquêtes ou de demandes réglementaires ; ou empêcher tout décès ou
                    dommage corporel imminent.
                    <br />• échanger des informations avec nos partenaires et clients et nos différentes entités pour favoriser la sécurité. Les intérêts légitimes sur lesquels
                    nous nous appuyons pour ce traitement sont les suivants :<br />• sécuriser les systèmes et lutter contre les indésirables, les menaces, les abus ou les
                    violations et promouvoir la sécurité et la protection des solutions, produits de UseKey. &nbsp;
                    <br />
                    <br />
                    <br />
                    Vous avez le droit de refuser et de limiter ledit traitement ; pour exercer vos droits, reportez vous à la section concernée à l’exercice de vos droits &nbsp;de
                    notre Politique de confidentialité. &nbsp;
                    <br />
                    <br />
                    Nous tenons compte de plusieurs facteurs lorsque nous évaluons une demande d’opposition, notamment, les attentes raisonnables de nos utilisateurs, les avantages
                    et les risques pour vous, pour nous, pour les bénéficiaires et leurs utilisateurs ou pour les tiers ; et tout autre moyen disponible pour atteindre le même
                    objectif, pouvant être moins invasif et ne nécessitant aucun effort disproportionné. Votre opposition sera maintenue et nous suspendrons le traitement de vos
                    informations, sauf si ce traitement s’appuie sur des raisons impérieuses et légitimes ou s’il est requis par la loi. &nbsp;
                    <br />
                    <br />
                    Si vous n’avez pas atteint l’âge de la majorité dans votre pays et si vous n’avez qu’une capacité juridique limitée pour conclure un contrat, nous tiendrons
                    compte du fait que vous êtes mineur(e), et évaluerons nos intérêts légitimes et équilibrerons vos intérêts et vos droits en conséquence. &nbsp;
                    <br />
                    <br />
                    Conformité à une obligation légale :<br />
                    <br />• traiter les données lorsque la loi l’exige, notamment lorsqu’il existe une demande légale valide pour certaines données. &nbsp;
                    <br />
                    <br />
                    Protection de vos intérêts vitaux ou de ceux d’une autre personne :<br />• les intérêts vitaux sur lesquels nous nous appuyons pour ce traitement comprennent la
                    protection de votre vie ou de votre intégrité physique ou de celle d’autres personnes, et nous nous appuyons dessus afin de lutter contre les comportements
                    nuisibles et de promouvoir la sécurité, par exemple, lorsque nous examinons des signalements de comportements nuisibles ou lorsqu’une personne a besoin d’aide.
                    <br />
                    <br />
                    Tâches accomplies dans l’intérêt du public :<br />• mener des recherches et promouvoir la sécurité, comme décrit plus en détail dans notre Politique de
                    confidentialité, lorsque cela est nécessaire dans l’intérêt du public conformément au droit de l’Union européenne ou de l’État membre applicable. &nbsp;
                    <br />
                    <br />
                    Lorsque nous sommes amenés à traiter vos données pour une tâche accomplie dans l’intérêt du public, vous avez le droit de vous opposer à ce traitement et de
                    demander à limiter son étendue. Lorsque nous évaluons une opposition, nous tenons compte de plusieurs facteurs, notamment : les attentes raisonnables de
                    l’utilisateur ; les avantages et les risques pour vous et les tiers ; et tout autre moyen disponible pour atteindre le même objectif, pouvant être moins invasif
                    et ne nécessitant aucun effort disproportionné. &nbsp;
                    <br />
                    <br />
                    <br />
                    Votre opposition sera maintenue et nous suspendrons le traitement de vos informations, sauf si ce traitement s’appuie sur des raisons impérieuses et légitimes
                    ou s’il est requis par la loi. &nbsp;
                    <br />
                    <br />
                    Questions et litiges. Contactez-nous si vous avez des questions ou des préoccupations relatives à notre politique de confidentialité et de protection des
                    données. Vous avez la possibilité de résoudre tout litige entre nous
                </p>
                <p style={{ textAlign: "start", color: "black", fontSize: "18px" }}>À propos des cookies</p>
                <p style={{ textAlign: "start", color: "black", fontSize: "18px" }}>
                    Un cookie est un petit fichier texte qu'un site que vous avez visité demande à votre moteur de recherche de stocker dans votre ordinateur ou téléphone portable.
                </p>
                <p style={{ textAlign: "start", color: "black", fontSize: "18px" }}>Comment nous utilisons les cookies</p>
                <p style={{ textAlign: "start", color: "black", fontSize: "18px" }}>
                    Nous utilisons les cookies pour comprendre, sécuriser, gérer et fournir nos Services.
                    <br />
                    Par exemple, nous utilisons les cookies pour :<br />• Vous offrir une solution au nom du Bénéficiaire pour le Web et le bureau et d'autres Services web et
                    mobiles, améliorer vos expériences, comprendre comment nos Services sont utilisés et personnaliser nos Services ;<br />• Comprendre lesquels de nos Q&amp;R sont
                    les plus populaires et pour vous donner du contenu pertinent en lien avec nos Services ;<br />• Se souvenir de vos choix, tels qu'une préférence linguistique,
                    ou même pour personnaliser nos Services en fonction de vos préférences ; et
                    <br />• Classer nos Q&amp;R sur notre site par ordre de popularité, comprendre les utilisateurs de mobile versus les utilisateurs d'ordinateurs lorsqu'ils sont
                    sur nos Services Web, ou comprendre la popularité et effectivité de certaines de nos pages web.
                </p>
                <p style={{ textAlign: "start", color: "black", fontSize: "18px" }}>Comment contrôler les cookies</p>
                <p style={{ textAlign: "start", color: "black", fontSize: "18px" }}>
                    Vous pouvez suivre les instructions fournies par votre moteur de recherche ou appareil (en général, situé sous "Paramètres" ou "Préférences") pour modifier vos
                    paramètres de cookies. Veuillez noter que si vous désactivez les cookies dans votre moteur de recherche ou appareil, certains de nos Services risquent de ne pas
                    fonctionner correctement.
                </p>
            </div>
        </div>
    );
};
