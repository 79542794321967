import React from 'react';
import SquareButton from "../../commons/SquareButton";

const SelectCreateCompany = ({onSelected}) => {

    return (
        <div className={"main-bg-1 rounded-2xl p-2 text-white h-full"} >
            <div className={"border-2 rounded-xl p-4 border-white h-full relative flex flex-col justify-between"}>
            <p className={"font-bold text-xl"}>Votre entreprise n'est pas encore inscrite ?</p>
                <p> Inscrivez-la en 2 minutes</p>


                <SquareButton onClick={() => onSelected()} text={"Inscrire mon entreprise"} className={"text-white bg-white bg-opacity-20"}/>

            </div>
        </div>
    );
};

export default SelectCreateCompany;
