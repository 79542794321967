import { getAuthToken } from "../shared/function/generic";
import request from "../shared/lib/AxiosWrap";

async function get(page) {
    return request({
        url: `/v1/recruiter/job?page=${page}&nbrByPage=10`,
        method: "GET",
        headers: { Authorization: getAuthToken() },
    });
}

async function getAll() {
    return request({
        url: `/v1/recruiter/job/all`,
        method: "GET",
        headers: { Authorization: getAuthToken() },
    });
}

async function count() {
    return request({
        url: `/v1/recruiter/job/count`,
        method: "GET",
        headers: { Authorization: getAuthToken() },
    });
}
async function create(job) {
    return request({
        url: `/v1/recruiter/job`,
        method: "PUT",
        data: job,
        headers: { Authorization: getAuthToken() },
    });
}

async function getById(id) {
    return request({
        url: `/v1/recruiter/job/${id}`,
        method: "GET",
        headers: { Authorization: getAuthToken() },
    });
}

async function deleteJob(id) {
    return request({
        url: `/v1/recruiter/job/${id}`,
        method: "DELETE",
        headers: { Authorization: getAuthToken() },
    });
}

async function updateById(id, job) {
    return request({
        url: `/v1/recruiter/job/${id}`,
        method: "POST",
        data: job,
        headers: { Authorization: getAuthToken() },
    });
}

async function jobActive(id) {
    return request({
        url: `/v1/jobs/${id}/active`,
        method: "GET",
        headers: { Authorization: getAuthToken() },
    });
}

async function jobInactive(id) {
    return request({
        url: `/v1/jobs/${id}/inactive`,
        method: "GET",
        headers: { Authorization: getAuthToken() },
    });
}

const jobsServices = {
    get,
    getAll,
    count,
    create,
    getById,
    deleteJob,
    updateById,
    jobActive,
    jobInactive,
};

export default jobsServices;
