import React from 'react';
import {Link} from "react-router-dom";
import i18n from "i18next";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import { faSortDown, faSortUp} from "@fortawesome/free-solid-svg-icons";

const subMenuStyles = {
    subMenu : {
        top : "100%",
        left : "0",
        zIndex: 10000,
        backgroundColor: '#BB2687'
    },
    subNav : {
        height : 50
    }
};

const NavItem = ({children, onClick, additionalClass, subMenu, sourceHover, pathname}) => {
    const lang = i18n.language;
    return (
        <div className={`relative text-white h-full table float-left px-2 hover:bg-white hover:bg-opacity-20 cursor-pointer ${additionalClass}`}>
            <li className={"table-cell align-middle cursor-pointer transition-2 my-auto px-3 text-sm font-bold"}
                onClick={onClick}>
                {children} {subMenu != null && subMenu.length > 0 &&
            <FontAwesomeIcon
                icon={sourceHover ? faSortUp : faSortDown}/>
            }
            </li>
            {subMenu && sourceHover &&
            <div className="absolute subMenu" style={subMenuStyles.subMenu}>
                {subMenu.map((route, index) => {
                    return <Link

                            key={index}
                            to={route.route}
                        >
                            <NavItem

                                additionalClass={(pathname === route.route ?   'bg-gray-800' : "") + " subFacturation w-full" }
                            >{lang === 'en' ? route.label_en : route.label}

                            </NavItem>

                        </Link>

                })
                }
            </div>
            }
        </div>
    );
};

export default NavItem;
