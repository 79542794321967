import React from 'react';
import 'react-confirm-alert/src/react-confirm-alert.css';
import 'react-datepicker/dist/react-datepicker.css';
//import {faEye, faStar} from "@fortawesome/free-regular-svg-icons";


const SpanSelected = ({ form, name, setForm }) => {
  return (
    <div className={'col-span-1 text-right'}>
      <span
        className={(form[name] ? 'main-bg-1 text-white' : 'border-1-1 color-1-1 border') + ' cursor-pointer inline-block  rounded-l-lg '}
        style={
          form[name]
            ? { padding: 8 }
            : {
                padding: 7,
                paddingLeft: 8,
                paddingRight: 8,
              }
        }
        onClick={() => {
          var obj = { ...form };
          obj[name] = true;
          setForm(obj);
        }}
      >
        Oui
      </span>
      <span
        className={(!form[name] ? 'main-bg-1 text-white' : 'border-1-1 color-1-1 border') + ' cursor-pointer inline-block rounded-r-lg'}
        style={
          !form[name]
            ? { padding: 8 }
            : {
                padding: 7,
                paddingLeft: 8,
                paddingRight: 8,
              }
        }
        onClick={() => {
          var obj = { ...form };
          obj[name] = false;
          setForm(obj);
        }}
      >
        Non
      </span>
    </div>
  );
};

export default SpanSelected;
