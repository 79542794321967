import React, { useContext, useEffect, useState } from 'react';
import 'react-confirm-alert/src/react-confirm-alert.css';
import 'react-datepicker/dist/react-datepicker.css';
import accountServices from '../../services/account';
import { InfoContext } from '../../shared/context/InfoContext';
import UserContext from '../../shared/context/UserContext';
import { toastError, toastSuccess } from '../../shared/function/generic';
import SquareButton from '../commons/SquareButton';
import SquareInput from '../commons/SquareInput';

const MyProfilComponent = () => {
  const context = useContext(UserContext);
  const [form, setForm] = useState({});
  const { user, setUser } = useContext(InfoContext);

  console.log(user);

  useEffect(() => {
    if (user) {
      console.log(user);
      setForm(user);
    }
  }, [user]);

  const save = async () => {
    const u = await accountServices.updateUser(form.firstName, form.lastName, form.fonction);
    toastSuccess('Votre profil a été modifié avec succès. ');
  };

  const updatePassword = async () => {
    if (!form.newPassword || form.newPassword.length < 5) {
      return toastError('Mot de passe trop court');
    }
    if (form.newPassword !== form.newPasswordConfirmation) {
      return toastError('Les mots de passe saisis ne sont pas identiques. ');
    }
    await accountServices.updatePassword(form.newPassword);
    setForm({
      ...form,
      newPassword: '',
      newPasswordConfirmation: '',
    });
    toastSuccess('Le mot de passe a été modifié avec succès. ');
  };
  return (
    <div className={'grid grid-cols-1 md:grid-cols-2 gap-4 px-4 py-4'}>
      <div className={'border-2 rounded-lg p-4 bg-white h-full relative bg-opacity-90 border-1-2 border-2 '}>
        <p className={'text-center font-bold mb-4 text-xl'}>Votre profil</p>
        <form
          onKeyPress={(e) => {
            e.key === 'Enter' && save() && e.preventDefault();
          }}
          className={'mb-4'}
        >
          <div className={'grid md:grid-cols-2 gap-4'}>
            <div className={'col-span-1'}>
              <label className={'block font-semibold'}>{'Prénom'}</label>
              <SquareInput
                type={'text'}
                className={'focus:border-blue-300 focus:border-2 md:mb-5'}
                name={'firstName'}
                setValues={setForm}
                state={form}
                value={form.firstName}
                placeholder={''}
              />
            </div>
            <div className={'col-span-1'}>
              <label className={'block font-semibold'}>{'Nom'}</label>
              <SquareInput
                type={'text'}
                className={'focus:border-blue-300 focus:border-2 md:mb-5'}
                name={'lastName'}
                setValues={setForm}
                state={form}
                value={form.lastName}
                placeholder={''}
              />
            </div>

            <div className={'col-span-1'}>
              <label className={'block font-semibold'}>{'Fonction'}</label>
              <SquareInput
                type={'text'}
                className={'focus:border-blue-300 focus:border-2 md:mb-5'}
                name={'fonction'}
                setValues={setForm}
                state={form}
                value={form.fonction}
                placeholder={''}
              />
            </div>
          </div>
        </form>

        <SquareButton
          onClick={() => {
            save();
          }}
          text={'Sauvegarder'}
          className={'text-white main-bg-1 bg-opacity-20 text-xl px-5 rounded-none'}
        />
      </div>

      <div className={'border-2 rounded-lg p-4 bg-white h-full relative bg-opacity-90 border-1-2 border-2'}>
        <p className={'text-center font-bold mb-4 text-xl'}>Modifier votre mot de passe</p>
        <form
          onKeyPress={(e) => {
            e.key === 'Enter' && save() && e.preventDefault();
          }}
          className={'mb-4'}
        >
          <div className={'grid md:grid-cols-2 gap-4'}>
            <div className={'col-span-1'}>
              <label className={'block font-semibold'}>{'Mot de passe'}</label>
              <SquareInput
                type={'password'}
                className={'focus:border-blue-300 focus:border-2 md:mb-5'}
                name={'newPassword'}
                setValues={setForm}
                state={form}
                value={form.newPassword}
                placeholder={''}
              />
            </div>
            <div className={'col-span-1'}>
              <label className={'block font-semibold'}>{'Confirmation'}</label>
              <SquareInput
                type={'password'}
                className={'focus:border-blue-300 focus:border-2 md:mb-5'}
                name={'newPasswordConfirmation'}
                setValues={setForm}
                state={form}
                value={form.newPasswordConfirmation}
                placeholder={''}
              />
            </div>
          </div>
        </form>

        <SquareButton
          onClick={() => {
            updatePassword();
          }}
          text={'Modifier'}
          className={'text-white main-bg-1 bg-opacity-20 text-xl px-5 rounded-none'}
        />
      </div>
    </div>
  );
};

export default MyProfilComponent;
