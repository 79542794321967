import React from 'react';
import 'react-confirm-alert/src/react-confirm-alert.css';
import "react-datepicker/dist/react-datepicker.css";

const MatchDecline = ({room}) => {
    return (
        <div className="border-2 border-1-2 bg-white shadow overflow-hidden sm:rounded-lg w-full p-4">
            <p className={""}>Le match n'a pas abouti !</p>
        </div>
    );
};

export default MatchDecline;
