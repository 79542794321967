import { faStar } from "@fortawesome/free-regular-svg-icons/faStar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useCallback, useContext, useEffect, useState } from "react";
import Autocomplete from "react-google-autocomplete";
import { useHistory, useParams } from "react-router";
import Select from "react-select";
import ReactTags from "react-tag-autocomplete";
import adminServices from "../../services/admin";
import configsServices from "../../services/config";
import { InfoContext } from "../../shared/context/InfoContext";
import UserContext from "../../shared/context/UserContext";
import { toastSuccess } from "../../shared/function/generic";
import { scoreCompany } from "../../shared/function/scoreCompany";
import DetailFileModal from "../commons/DetailFileUpload";
import LogoUpdate from "../commons/LogoUpdate";
import SquareButton from "../commons/SquareButton";
import SquareInput from "../commons/SquareInput";
import UploadFileModal from "../commons/UploadFileModal";
import ScoreCompany from "../company/ScoreCompany";
import PromotionCell from "../jobs/PromotionCell";
import CompanyJobListAdmin from "./CompanyJobListAdmin";

const CompanyDetailAdmin = () => {
    const [form, setForm] = useState(null);
    const context = useContext(UserContext);
    const [scoreOpen, setScoreOpen] = useState(false);
    const [newFileOpen, setNewFileOpen] = useState(false);
    const [fileDetail, setFileDetail] = useState(null);
    const [actitivies, setActivities] = useState([]);
    const [score, setScore] = useState(0);
    const { user, setUser } = useContext(InfoContext);
    var _tagsInput = null;
    const params = useParams();
    const history = useHistory();
    const [logo, setLogo] = useState(null);
    const updateUrlPicture = useCallback(
        (url) => {
            setLogo(url);
        },
        [form]
    );

    const reloadData = async () => {
        console.log("RELOAD DATA");
        if (params.id) {
            const activitiesArr = await configsServices.getActivities();
            setActivities(
                activitiesArr.map((e) => {
                    return { value: e, label: e };
                })
            );
            var myCompany = await adminServices.getAdminCompanyById(params.id);
            if (typeof myCompany.activity === "string") {
                myCompany.activity = [myCompany.activity];
            }
            setForm(myCompany);
            const score = await scoreCompany(myCompany);
            setScore(score);
        }
    };

    useEffect(() => {
        reloadData();
    }, []);

    const save = async () => {
        await adminServices.updateAdminCompany(params.id, form);
        toastSuccess("Modification sauvegardé");
        reloadData();
        /*const result = await companyServices.updateMyCompany(form)
         */
    };

    const saveForce = async (form) => {
        await adminServices.updateAdminCompany(params.id, form);
        toastSuccess("Modification sauvegardé");
        reloadData();
        /*const result = await companyServices.updateMyCompany(form)
    toastSuccess("Modification sauvegardé")
    reloadData()*/
    };

    if (!form) {
        return null;
    }
    return (
        <div className={"grid md:grid-cols-6 p-1 gap-4 md:mx-10 grid-cols-1"} style={{ paddingBottom: 80 }}>
            <div className={"border-2 rounded-lg p-4 bg-white h-full relative bg-opacity-90 border-1-2 border-2 mb-4 md:col-span-4"}>
                <div className={"grid md:grid-cols-4 h-full"}>
                    <p className={"md:col-span-3 self-center "}>
                        <strong>Vous souhaitez inviter un collaborateur à representer l'entreprise ?</strong>
                        <br />
                        Demandez lui de s'inscire à ce lien : <br />
                        <a href={"https://ctm-jobs-website.herokuapp.com"} className={"inline underline font-bold"}>
                            https://ctm-jobs-website.herokuapp.com
                        </a>
                        , en renseignant le code suivant :
                    </p>
                    <p className={"text-center self-center text-center "}>
                        <p
                            className={"border-1-2 font-black text-xl border-2 border-dashed rounded m-auto"}
                            style={{
                                width: 100,
                            }}
                        >
                            {form.code}
                        </p>
                    </p>
                </div>
            </div>
            <div className={"border-2 rounded-lg p-4 bg-white h-full relative bg-opacity-90 border-white border-2 mb-4 pb-0 md:col-span-2 main-bg-1"}>
                <div className={"w-full text-center"}>
                    <p className={"text-white font-bold"}>Votre note :</p>
                    <p className={"text-3xl text-white font-bold"}>
                        {score}/5 <FontAwesomeIcon style={{ marginRight: 5 }} icon={faStar} />
                    </p>
                    <SquareButton
                        onClick={() => {
                            setScoreOpen(true);
                        }}
                        text={"Modifier le formulaire"}
                        className={"text-white bg-white bg-opacity-20 mt-4"}
                    />
                </div>
            </div>
            <div className={"border-2 rounded-lg p-4 bg-white h-full relative bg-opacity-90 border-1-2 border-2 md:col-span-4"}>
                <p className={"text-center font-bold mb-4 text-xl"}>Modification de votre entreprise</p>
                <form
                    onKeyPress={(e) => {
                        e.key === "Enter" && save() && e.preventDefault();
                    }}
                    className={"mb-4"}
                >
                    <div className={"grid md:grid-cols-2 gap-4"}>
                        <div className={"col-span-1 md:hidden"}>
                            <label className={"block font-semibold"}>{"Logo"}</label>
                            <LogoUpdate url={form.logo} onUpdate={updateUrlPicture}></LogoUpdate>
                        </div>
                        <div className={"col-span-1"}>
                            <label className={"block font-semibold"}>{"Raison sociale"}</label>
                            <SquareInput
                                type={"text"}
                                className={"focus:border-blue-300 focus:border-2 md:mb-5"}
                                name={"denomination"}
                                setValues={setForm}
                                autoComplete={"organization"}
                                state={form}
                                value={form.denomination}
                                placeholder={""}
                            />
                        </div>
                        <div className={"col-span-1 hidden sm:block"}>
                            <label className={"block font-semibold"}>{"Logo"}</label>
                            <LogoUpdate url={form.logo} onUpdate={updateUrlPicture}></LogoUpdate>
                        </div>
                    </div>
                    <div className={"grid grid-cols-2 gap-4 mt-4 md:mt-0"}>
                        <div className={"col-span-2"}>
                            <label className={"block font-semibold"}>{"Adresse"}</label>
                            <Autocomplete
                                className={"block bg-gray-50 outline-none w-full rounded border p-2 focus:border-blue-300 py-1 border-gray-300 mt-2 mb-5"}
                                types={["address"]}
                                defaultValue={(form.place || {}).formatted_address}
                                onPlaceSelected={(place) => {
                                    setForm({
                                        ...form,
                                        place: place,
                                    });
                                }}
                            ></Autocomplete>
                        </div>
                    </div>

                    <div className={"grid md:grid-cols-2 md:gap-4"}>
                        <div className={"col-span-1"}>
                            <label className={"block font-semibold"}>{"Nombre de salariés"}</label>
                            <SquareInput
                                type={"number"}
                                className={"focus:border-blue-300 focus:border-2 mb-5"}
                                name={"employeesNumber"}
                                setValues={setForm}
                                state={form}
                                value={form.employeesNumber}
                                placeholder={""}
                            />
                        </div>

                        <div className={"col-span-1"}>
                            <label className={"block font-semibold"}>{"Activité"}</label>
                            {form.activity && (
                                <Select
                                    placeholder={"Sélectionner"}
                                    defaultValue={((form || {}).activity || []).map((e) => {
                                        return {
                                            value: e,
                                            label: e,
                                        };
                                    })}
                                    isMulti
                                    onChange={(newValue, b) => {
                                        setForm({
                                            ...form,
                                            activity: newValue.map((e) => e.value),
                                        });
                                    }}
                                    name={"activity"}
                                    options={actitivies}
                                />
                            )}
                            {/*<select
                                className={`transition-2 mt-1 block py-1 bg-gray-100 outline-none w-full rounded border p-2 border-gray-300 `}
                                value={form.activity} onChange={(event) => {


                                setForm({
                                    ...form,
                                    activity : event.target.value
                                })
                            }}>
                                <option value={""}>Non-défini</option>
                                {actitivies.map((elem, index) => <option key={"optionsactivity"+index} value={elem}>{elem}</option>)}
                            </select>*/}
                        </div>
                    </div>

                    <label className={"block font-semibold mt-4 md:mt-0"}>
                        {"Pourquoi nous rejoindre ?"} <span className={"light italic font-light"}>Mots clés</span>
                    </label>
                    <ReactTags
                        classNames={{
                            root: "block bg-gray-50 outline-none w-full rounded border p-2 focus:border-blue-300 py-1 border-gray-300 mt-2 mb-5",
                        }}
                        ref={(node) => {
                            try {
                                _tagsInput = node.input;
                            } catch (e) {}
                        }}
                        tags={form.tags}
                        allowNew={true}
                        delimiterChars={[" "]}
                        placeholder={(form.tags || []).length >= 6 ? "6 maximum" : "Ajouter un mot clef"}
                        handleDelete={(elem) => {
                            var tags = form.tags;
                            tags.splice(elem, 1);
                            setForm({
                                ...form,
                                tags,
                            });
                            if (tags.length < 6) {
                                try {
                                    _tagsInput.input.disabled = false;
                                } catch (e) {}
                            }
                        }}
                        handleAddition={(elem) => {
                            var tags = form.tags;
                            tags.push(elem);
                            setForm({
                                ...form,
                                tags,
                            });
                            if (tags.length >= 6) {
                                try {
                                    _tagsInput.input.disabled = true;
                                } catch (e) {}
                            }
                        }}
                    />

                    <div className={"grid grid-cols-2 gap-4"}>
                        <div>
                            <label className={"block font-semibold"}>{"Candidature spontanée"}</label>
                            <span
                                className={
                                    (form.spontaneousApplication ? "main-bg-1 text-white" : "border-1-1 color-1-1 border") + " cursor-pointer inline-block  rounded-l-lg mt-2"
                                }
                                style={form.spontaneousApplication ? { padding: 8 } : { padding: 7, paddingLeft: 8, paddingRight: 8 }}
                                onClick={() => {
                                    setForm({
                                        ...form,
                                        spontaneousApplication: true,
                                    });
                                }}
                            >
                                Oui
                            </span>
                            <span
                                className={
                                    (!form.spontaneousApplication ? "main-bg-1 text-white" : "border-1-1 color-1-1 border") + " cursor-pointer inline-block rounded-r-lg mt-2"
                                }
                                style={!form.spontaneousApplication ? { padding: 8 } : { padding: 7, paddingLeft: 8, paddingRight: 8 }}
                                onClick={() => {
                                    setForm({
                                        ...form,
                                        spontaneousApplication: false,
                                    });
                                }}
                            >
                                Non
                            </span>
                        </div>

                        <div>
                            <label className={"block font-semibold"}>{"Ouverture à l'international"}</label>
                            <span
                                className={(form.international ? "main-bg-1 text-white" : "border-1-1 color-1-1 border") + " cursor-pointer inline-block  rounded-l-lg mt-2"}
                                style={form.international ? { padding: 8 } : { padding: 7, paddingLeft: 8, paddingRight: 8 }}
                                onClick={() => {
                                    setForm({
                                        ...form,
                                        international: true,
                                    });
                                }}
                            >
                                Oui
                            </span>
                            <span
                                className={(!form.international ? "main-bg-1 text-white" : "border-1-1 color-1-1 border") + " cursor-pointer inline-block rounded-r-lg mt-2"}
                                style={!form.international ? { padding: 8 } : { padding: 7, paddingLeft: 8, paddingRight: 8 }}
                                onClick={() => {
                                    setForm({
                                        ...form,
                                        international: false,
                                    });
                                }}
                            >
                                Non
                            </span>
                        </div>

                        <div>
                            <label className={"block font-semibold"}>{"Adhérent SNCT"}</label>
                            <span
                                className={(form.isSNCT ? "main-bg-1 text-white" : "border-1-1 color-1-1 border") + " cursor-pointer inline-block  rounded-l-lg mt-2"}
                                style={form.isSNCT ? { padding: 8 } : { padding: 7, paddingLeft: 8, paddingRight: 8 }}
                                onClick={() => {
                                    setForm({
                                        ...form,
                                        isSNCT: true,
                                    });
                                }}
                            >
                                Oui
                            </span>
                            <span
                                className={(!form.isSNCT ? "main-bg-1 text-white" : "border-1-1 color-1-1 border") + " cursor-pointer inline-block rounded-r-lg mt-2"}
                                style={!form.isSNCT ? { padding: 8 } : { padding: 7, paddingLeft: 8, paddingRight: 8 }}
                                onClick={() => {
                                    setForm({
                                        ...form,
                                        isSNCT: false,
                                    });
                                }}
                            >
                                Non
                            </span>
                        </div>

                        <div>
                            <label className={"block font-semibold"}>{"Membre Associé SNCT"}</label>
                            <span
                                className={(form.isMemberAsso ? "main-bg-1 text-white" : "border-1-1 color-1-1 border") + " cursor-pointer inline-block  rounded-l-lg mt-2"}
                                style={form.isMemberAsso ? { padding: 8 } : { padding: 7, paddingLeft: 8, paddingRight: 8 }}
                                onClick={() => {
                                    setForm({
                                        ...form,
                                        isMemberAsso: true,
                                    });
                                }}
                            >
                                Oui
                            </span>
                            <span
                                className={(!form.isMemberAsso ? "main-bg-1 text-white" : "border-1-1 color-1-1 border") + " cursor-pointer inline-block rounded-r-lg mt-2"}
                                style={!form.isMemberAsso ? { padding: 8 } : { padding: 7, paddingLeft: 8, paddingRight: 8 }}
                                onClick={() => {
                                    setForm({
                                        ...form,
                                        isMemberAsso: false,
                                    });
                                }}
                            >
                                Non
                            </span>
                        </div>
                        <div>
                            <label className={"block font-semibold"}>{"Marque Employeur"}</label>
                            <span
                                className={(form.isMarqueEmployeur ? "main-bg-1 text-white" : "border-1-1 color-1-1 border") + " cursor-pointer inline-block  rounded-l-lg mt-2"}
                                style={form.isMarqueEmployeur ? { padding: 8 } : { padding: 7, paddingLeft: 8, paddingRight: 8 }}
                                onClick={() => {
                                    setForm({
                                        ...form,
                                        isMarqueEmployeur: true,
                                    });
                                }}
                            >
                                Oui
                            </span>
                            <span
                                className={(!form.isMarqueEmployeur ? "main-bg-1 text-white" : "border-1-1 color-1-1 border") + " cursor-pointer inline-block rounded-r-lg mt-2"}
                                style={!form.isMarqueEmployeur ? { padding: 8 } : { padding: 7, paddingLeft: 8, paddingRight: 8 }}
                                onClick={() => {
                                    setForm({
                                        ...form,
                                        isMarqueEmployeur: false,
                                    });
                                }}
                            >
                                Non
                            </span>
                        </div>
                    </div>
                </form>
            </div>

            <div className={"border-2 rounded-lg p-4 bg-white h-full relative bg-opacity-90 border-1-2 border-2 mb-4 md:col-span-2"}>
                <p className={"text-center font-bold  text-xl"}>Promotion de l'entreprise</p>
                <p className={"text-center  mb-4 text-xs"}>Ajouter des vidéos et des photos pour promouvoir votre entreprise</p>
                <div className={"grid grid-cols-3 gap-3 mb-3"}>
                    {(form.files || []).map((elem, index) => {
                        return (
                            <PromotionCell
                                elem={elem}
                                index={index}
                                setFileDetail={setFileDetail}
                                onDelete={() => {
                                    var files = form.files || [];
                                    files = files.filter((elem2) => {
                                        return elem2.url !== elem.url;
                                    });
                                    setForm({
                                        ...form,
                                        files: files,
                                    });
                                    saveForce({
                                        ...form,
                                        files: files,
                                    });
                                }}
                            />
                        );
                    })}
                </div>
                <SquareButton
                    onClick={() => {
                        setNewFileOpen(true);
                    }}
                    text={"Ajouter"}
                    className={"color-button-violet"}
                />
            </div>

            <ScoreCompany
                isOpen={scoreOpen}
                company={form}
                close={async (score) => {
                    setForm({
                        ...form,
                        score: score,
                    });

                    saveForce({
                        ...form,
                        score: score,
                    });

                    setScoreOpen(false);
                }}
            />

            <UploadFileModal
                isOpen={newFileOpen}
                close={(url, type) => {
                    var files = form.files || [];

                    if (type === "photo") {
                        var obj = url.map((elem) => {
                            return { url: elem, type: type };
                        });
                        files = files.concat(obj);
                    } else {
                        files.push({
                            url: url,
                            type: type,
                        });
                    }
                    setForm({
                        ...form,
                        files: files,
                    });

                    saveForce({
                        ...form,
                        files: files,
                    });
                    setNewFileOpen(false);
                }}
            />

            <DetailFileModal
                isOpen={fileDetail !== null}
                close={(needDelete, item) => {
                    if (needDelete) {
                        var files = form.files || [];
                        files = files.filter((elem) => {
                            return elem.url !== item.url;
                        });
                        setForm({
                            ...form,
                            files: files,
                        });
                    }
                    setFileDetail(null);
                }}
                file={fileDetail}
            />

            <div className="md:col-span-6 col-span-1 ">
                <CompanyJobListAdmin company={form} />
            </div>

            {form._recruiters && (
                <div className="md:col-span-6 col-span-1 ">
                    <div className={"p-6 pb-12"}>
                        <div>
                            <h1 className={"title-page inline-block font-bold"}>Liste des recruteurs</h1>

                            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-6 gap-4">
                                {form._recruiters.map((elem) => {
                                    return (
                                        <div className={"bg-white p-4 mb-4 border-2 border-1-2 rounded " + (elem.archived ? "bg-red-50" : "")}>
                                            <p className={"text-xl font-bold"}>
                                                {elem.firstName} {elem.lastName}
                                            </p>
                                            <p className={"mb-2"}>
                                                <a href={"mailto:" + elem.email}>{elem.email}</a>
                                            </p>
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    </div>
                </div>
            )}

            <div
                style={{
                    position: "fixed",
                    left: 0,
                    right: 0,
                }}
                className={"bottom-2 mx-2"}
            >
                <SquareButton
                    onClick={() => {
                        save();
                    }}
                    text={"Sauvegarder"}
                    className={"text-white main-bg-1 bg-opacity-20 text-xl px-5 rounded-none"}
                />
            </div>

            <div className={"bottom-2 mx-2  w-full md:col-span-6"}>
                <SquareButton
                    onClick={async () => {
                        if (
                            window.confirm(
                                "Êtes-vous sur de vouloirs supprimés cette entreprise ? Les offres d'emploi, recruteurs, conversations, messages liés à l'entreprise seront tous supprimés. Cela est irréversible."
                            )
                        ) {
                            await adminServices.deleteAdminCompany(form._id);
                            history.push("/admin/company");
                        }
                    }}
                    text={"Supprimer"}
                    className={"text-white bg-red-600  text-xl px-5 rounded-none"}
                />
            </div>
        </div>
    );
};

export default CompanyDetailAdmin;
