import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faEye, faThumbsDown, faThumbsUp} from "@fortawesome/free-regular-svg-icons";

const StatistiqueFormatter = (data) =>{


    var totalSwipe = data.row.totalSwipe
    var acceptSwipe = data.row.acceptSwipe
    var declineSwipe = data.row.declineSwipe


    return (
        <div style={{
            display : "flex",

        }} className={"flex-col"}>
            <p className={"w-full text-center"}>{data.row.archived && "Archivée" }</p>
        <div style={{
            display : "flex"
        }}>

            <p
               style={{
                flex : "1 1 0",
                textAlign : "center"
            }}><FontAwesomeIcon style={{marginRight : 5}} icon={faEye} />{totalSwipe}</p>

            <p  style={{
                flex : "1 1 0",
                textAlign : "center"
            }}><FontAwesomeIcon style={{marginRight : 5}} icon={faThumbsUp} />{acceptSwipe}   </p>
            <p  style={{
                flex : "1 1 0",
                textAlign : "center"
            }}><FontAwesomeIcon style={{marginRight : 5}} icon={faThumbsDown} />{declineSwipe}</p>

        </div>
        </div>
           )
}

export default StatistiqueFormatter
