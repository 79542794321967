import React from 'react';
import Cookies from 'js-cookie';
import {Redirect, Route} from 'react-router-dom';

const PublicRoute = ({component: Component, ...rest}) => {
    return (
        <Route
            {...rest}
            render={props =>
                !Cookies.get('hiofukt') ? (
                    <Component {...props} />
                ) : (
                    <Redirect to="/dashboard"/>
                )
            }
        />
    );
};

export default PublicRoute;