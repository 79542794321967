import React, {useState, useEffect} from 'react';
import 'react-confirm-alert/src/react-confirm-alert.css';
import "react-datepicker/dist/react-datepicker.css";
import userServices from "../../services/users";
import UserTile from "../user/UserTile";
import SquareButton from "../commons/SquareButton";
import Autocomplete from "react-google-autocomplete";
import companyServices from "../../services/company";

const SearchList = () => {
    const [loading, setLoading] = useState(false)
    const [users, setUsers] = useState([])
    const [place, setPlace] = useState({})
    const [sendingResponse, setSendingReponse] = useState(false)
    const [isEmpty, setIsEmpty] = useState(false)

    const reloadData = async () => {
        try {
            setIsEmpty(false)
            setLoading(true)
            var users = await userServices.getCards()
            setUsers(users)
            setLoading(false)
            if (users.length == 0) {
                setIsEmpty(true)
            }
        } catch (e) {}
    }

    const reloadPlace = async () => {
        try {
            const myCompany = await companyServices.getMyCompany()
            setPlace(myCompany.place || {})
        } catch (e){}
    }
    useEffect(() => {
        reloadPlace()
        reloadData()
    }, [])

    const accept = async () => {
        setSendingReponse(true)
        await userServices.acceptUser(users[0]._id)
        var old = users
        old.shift()
        setUsers(old)
        if (old.length == 0){
            reloadData()
        }
        setSendingReponse(false)
    }

    const decline = async () => {
        setSendingReponse(true)
        await userServices.declineUser(users[0]._id)
        var old = users
        old.shift()
        setUsers(old)
        if (old.length == 0){
            reloadData()
        }
        setSendingReponse(false)
    }
    return (
        <div className={"p-6 pb-12"}>
            {loading && <p>Chargement ...</p>}
            {!loading  &&
                <div style={{
                    display : "flex",
                    alignItems: "center",
                    flexDirection : "column"
                }}>
                    <div className={"w-full flex-col sm:w-2/4 flex sm:flex-row justify-evenly items-center mb-2"}>

                            <label className={"block font-semibold"}>{"Zone de recherche : "}</label>

                            <Autocomplete
                                className={"block bg-gray-50 outline-none w-full sm:w-4/6 rounded border p-2 focus:border-blue-300 py-1 border-gray-300 "}
                                types={['address']}
                                defaultValue={place.formatted_address}
                                onPlaceSelected={(place) => {
                                    setPlace(place)
                                }}
                            >
                            </Autocomplete>

                    </div>

                    <div style={{
                        minWidth  : "50%",
                        maxWidth : "99%"
                    }}>
                        {isEmpty &&
                        <div className={"border-2 rounded-lg p-4 text-center mt-10 mb-10 bg-white w-1/2 m-auto h-full relative bg-opacity-90 border-1-2 border-2 mb-4 md:col-span-4"}>
                            Aucun profil à vous présenter
                            <SquareButton onClick={() => {

reloadData()
                            }} text={"Recharger"} className={"text-white main-bg-1 rounded-xl ml-2 bg-opacity-20 mt-5"}/>
                        </div>
                        }
                        {users.length > 0 && isEmpty === false &&
                        <UserTile user={users[0]}/>
                        }
                    </div>

                    {!isEmpty &&
                    <div className={"flex flex-row mt-4 w-full sm:w-2/4"}>

                        <SquareButton onClick={() => {
                            decline()
                        }} text={"Pas interessé"}
                                      className={"w-1/4  text-white border-1-1 border color-1-1 rounded-xl  mr-2 bg-opacity-20"}/>

                        <SquareButton onClick={() => {

                            accept()
                        }} text={"Interessé"} className={"text-white main-bg-1 rounded-xl ml-2 bg-opacity-20"}/>
                    </div>
                    }

                </div>

            }
        </div>
    );
};

export default SearchList;
