import React, {useState, useContext, useEffect, useCallback} from 'react';
import SquareInput from "../../commons/SquareInput";
import {useHistory} from "react-router-dom";
import SquareButton from "../../commons/SquareButton";
import Autocomplete from 'react-google-autocomplete';
import LogoUpdate from "../../commons/LogoUpdate";
import configsServices from "../../../services/config";
import ReactTags from 'react-tag-autocomplete'
import companyServices from "../../../services/company";
import {toastError} from "../../../shared/function/generic";
import UserContext from "../../../shared/context/UserContext";
import accountServices from "../../../services/account";
import User from "../../../shared/class/User";
import {InfoContext} from "../../../shared/context/InfoContext";
import Select from "react-select";

const CreateCompany = () => {
    const [form, setForm] = useState({place : null, employeesNumber : 0, activity : "", tags : [], spontaneousApplication : true, international : false})
    const [actitivies, setActivities] = useState([])

    const context = useContext(UserContext);
    const history = useHistory()
    var _tagsInput = null;
    const {user, setUser} = useContext(InfoContext)
    const reloadActivities = async () => {
        const activitiesArr = await configsServices.getActivities()

        setActivities(activitiesArr.map(e => {
            return  { value: e, label: e }
        }))
    }
    useEffect( () => {
        reloadActivities()
    }, [])

    const createCompany = async () => {

        if (form.denomination == null || form.denomination === ""){
            return toastError("Merci de remplir la raison social de votre entreprise")
        }
        await companyServices.createCompany(form)
        const newUser = await accountServices.token()
        context.update({...context.data, user: new User(newUser)})
        setUser(newUser)
        history.push("/dashboard")
    }

    const [logo, setLogo] = useState(null)
    const updateUrlPicture = useCallback((url) => {
        setLogo(url)
    }, [form])

    useEffect(() =>{
        if (logo) {
            setForm({
                ...form,
                logo: logo
            })
        }
    }, [logo])

    useEffect(() => {
        console.log("Form was updated")
        console.log(form)
    }, [form])

    console.log("Reload vu")
    console.log(form) // ICI il est bien à false
    return (
        <div className={"rounded-xl p-1"} style={{maxWidth : 800, margin:"auto"}}>
            <div className={"border-2 rounded-lg p-4 bg-white h-full relative bg-opacity-90 border-1-2 border-2"}>
                <p className={"text-center font-bold mb-4 text-xl"}>Création de votre entreprise</p>
                <form onKeyPress={(e) => { e.key === 'Enter' && createCompany() && e.preventDefault() }} className={"mb-4"}>
                    <div className={"grid grid-cols-1 md:grid-cols-2 gap-4"}>
                        <div className={"col-span-1"}>
                        <label className={"block font-semibold"}>{"Raison sociale"}</label>
                        <SquareInput type={"text"} className={"focus:border-blue-300 focus:border-2 mb-5"} name={"denomination"}
                                     setValues={setForm}
                                     autoComplete={"organization"}
                                     state={form} value={form.denomination} placeholder={""}/>
                        </div>
                        <div className={"col-span-1"}>
                            <label className={"block font-semibold"}>{"Logo"}</label>
                            <LogoUpdate onUpdate={updateUrlPicture}></LogoUpdate>
                        </div>
                    </div>
                    <div className={"grid grid-cols-2 gap-4"}>
                        <div className={"col-span-2"}>
                            <label className={"block font-semibold"}>{"Adresse"}</label>
                            <Autocomplete
                            className={"block bg-gray-50 outline-none w-full rounded border p-2 focus:border-blue-300 py-1 border-gray-300 mt-2 mb-5"}
                                types={['address']}
                            onPlaceSelected={(place) => {
                                setForm({
                                    ...form,
                                    place : place
                                })
                            }}
                            >
                            </Autocomplete>
                        </div>
                    </div>

                    <div className={"grid grid-cols-1 md:grid-cols-2 gap-4"}>
                    <div className={"col-span-1"}>
                        <label className={"block font-semibold"}>{"Nombre de salariés"}</label>
                        <SquareInput type={"number"} className={"focus:border-blue-300 focus:border-2 mb-5"} name={"employeesNumber"}
                                     setValues={setForm}
                                     state={form} value={form.employeesNumber} placeholder={""}/>
                    </div>

                    <div className={"col-span-1"}>
                        <label className={"block font-semibold"}>{"Activité"}</label>

                        <Select
                            placeholder={"Sélectionner"}
                            defaultValue={((form || {}).activity || []).map(e => {
                                return {
                                    value: e,
                                    label: e
                                }
                            })}
                            isMulti
                            onChange={(newValue, b) => {
                                setForm({
                                    ...form,
                                    activity: newValue.map(e => e.value)
                                })
                            }}
                            name={"activity"}
                            options={actitivies}
                        />

                    </div>
                    </div>


                    <label className={"block font-semibold"}>{"Pourquoi nous rejoindre ?"} <span className={"light italic font-light"}>Mots clés</span></label>
                    <ReactTags
                        classNames={{ root: "block bg-gray-50 outline-none w-full rounded border p-2 focus:border-blue-300 py-1 border-gray-300 mt-2 mb-5" }}
                        ref={node => {
                            try {
                                _tagsInput = node.input
                            } catch (e) {}}}
                        tags={form.tags}
                        allowNew={true}
                        delimiterChars={[' ']}
                        placeholder={"Ajouter un mot clef"}
                        handleDelete={(elem) => {
                            var tags = form.tags
                            tags.splice(elem, 1)
                            setForm({
                                ...form,
                                tags
                            })
                            if (tags.length < 6){
                                try {
                                    _tagsInput.input.disabled = false
                                } catch(e) {

                                }
                            }
                        }}
                        handleAddition={(elem) => {
                            var tags = form.tags
                            tags.push(elem)
                            setForm({
                                ...form,
                                tags
                            })
                            if (tags.length >= 6){
                                try {
                                    _tagsInput.input.disabled = true
                                } catch(e) {

                                }
                            }
                        }}
                    />

                    <div className={"grid grid-cols-2 gap-4"}>
                        <div>
                            <label className={"block font-semibold"}>{"Candidature spontanée"}</label>
                            <span className={ (form.spontaneousApplication ? "main-bg-1 text-white" : "border-1-1 color-1-1 border") +  " cursor-pointer inline-block  rounded-l-lg mt-2"}
                                  style={form.spontaneousApplication ? {padding : 8} : {padding : 7, paddingLeft : 8, paddingRight : 8}}
                                onClick={() => {
                                    setForm({
                                        ...form,
                                                spontaneousApplication : true
                                            })
                                }}
                            >
                                Oui
                            </span>
                            <span className={ (!form.spontaneousApplication ? "main-bg-1 text-white" : "border-1-1 color-1-1 border") + " cursor-pointer inline-block rounded-r-lg mt-2"}
                                  style={!form.spontaneousApplication ? {padding : 8} : {padding : 7, paddingLeft : 8, paddingRight : 8}}
                                  onClick={() => {
                                      setForm({
                                          ...form,
                                          spontaneousApplication : false
                                      })
                                  }}
                            >
                                Non
                            </span>
                        </div>


                        <div>
                            <label className={"block font-semibold"}>{"Ouverture à l'international"}</label>
                            <span className={ (form.international ? "main-bg-1 text-white" : "border-1-1 color-1-1 border") +  " cursor-pointer inline-block  rounded-l-lg mt-2"}
                                  style={form.international ? {padding : 8} : {padding : 7, paddingLeft : 8, paddingRight : 8}}
                                  onClick={() => {
                                      setForm({
                                          ...form,
                                          international : true
                                      })
                                  }}
                            >
                                Oui
                            </span>
                            <span className={ (!form.international ? "main-bg-1 text-white" : "border-1-1 color-1-1 border") + " cursor-pointer inline-block rounded-r-lg mt-2"}
                                  style={!form.international ? {padding : 8} : {padding : 7, paddingLeft : 8, paddingRight : 8}}
                                  onClick={() => {
                                      setForm({
                                          ...form,
                                          international : false
                                      })
                                  }}
                            >
                                Non
                            </span>
                        </div>
                    </div>



                </form>
                <div className={"flex justify-end"}>
                    <SquareButton onClick={() => {
                        createCompany()
                    }} text={"Inscrire mon entreprise"} className={"text-white main-bg-1 bg-opacity-20"}/>
                </div>
            </div>
        </div>
    );
};

export default CreateCompany;
