import React, {useEffect, useState} from 'react';
import {css, StyleSheet} from "aphrodite";

const Pagination = ({fetch, nbPages, page}) => {
    const [currentPage, setCurrentPage] = useState(0);

    var arr = Array.apply(null, Array((nbPages || 0) + 1)).map(function (x, i) {
        return i + 1;
    }).filter(function (elem){
        var min = parseInt(page) - 5
        var max = parseInt(page) + 5
        if (min < 0){
            min = 0
            max = 11
        }
        if (elem > min && elem < max){

            return true
        }
        return false
    });

    const styles = StyleSheet.create({
        pagination: {
            ':hover': {
                backgroundColor: '#58256E',
                color: 'white',
                fontWeight: 'bold'
            }
        },
        selected: {
            backgroundColor: '#BB2687',
            color: 'white',
            fontWeight: 'bold'
        }
    });

    useEffect(() => {
        setCurrentPage(page);
    }, [page]);

    const changePage = (index) => {
        setCurrentPage(index);
        fetch(index)
    };

    const changeWithArrow = (value) => {
        if (parseInt(currentPage + value) < 0 || parseInt(currentPage + value) > nbPages)
            return 0;
        changePage(parseInt(currentPage + value));
    };

    return arr && (
        <div className={"w-full my-2 outline-none relative text-center"}>
            <div className={"inline-block "} >
                <span onClick={() => changeWithArrow(-1)}
                      className={"inline-block transition-2 px-4 py-2 cursor-pointer"}>&laquo;</span>
                {arr.map((index) => {
                    return (
                        <span key={Math.random() + "-" + index} onClick={() => changePage(index - 1)}
                              className={`rounded-full inline-block black transition-2 mx-1 px-4 py-2 cursor-pointer ${index === parseInt(currentPage + 1) && css(styles.selected)} ${css(styles.pagination)}`}>{index}</span>
                    )
                })}
                <span onClick={() => changeWithArrow(1)}
                      className={"inline-block transition-2 px-4 py-2 cursor-pointer"}>&raquo;</span>
                <p className={"italic"}>{nbPages + 1} pages</p>
            </div>
        </div>
    );
};

export default Pagination;
