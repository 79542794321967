import React, {useState, useEffect, useContext} from 'react';
import companyServices from "../../services/company";
import TitleMatchFormatter from "../matchs/formatter/TitleMatchFormatter";
import CandidatMatchFormatter from "../matchs/formatter/CandidatMatchFormatter";
import StateMatchFormatter from "../matchs/formatter/StateMatchFormatter";
import LastMessageMatchFormatter from "../matchs/formatter/LastMessageMatchFormatter";
import ReactDataGrid from "react-data-grid";
import {hasReadRoom} from "../../shared/function/generic";
import UserContext from "../../shared/context/UserContext";
import {useHistory} from 'react-router-dom';

export const JobDetailMatch = ({jobId}) => {

    const [matchs, setMatchs] = useState([])
    const history = useHistory()
    const context = useContext(UserContext)
    const reloadMatch = async () => {

        const matchs = await companyServices.getMyCompanyMatch()
        setMatchs(matchs.filter((elem) => {
            return elem._job && elem._job._id === jobId
        }))
    }
    useEffect(() => {
        reloadMatch()
    }, [jobId])

    var columns = [
            {key: 'interestedby', name: 'Intéréssé par', formatter: TitleMatchFormatter},
            {key: 'name', name: 'Candidat', formatter: CandidatMatchFormatter},
            {key: 'lastmsg', name: 'Dernier message', formatter: LastMessageMatchFormatter},
            {key: 'state', name: 'Statut', formatter: StateMatchFormatter},
        ];


    const moveToDetails = async (id) => {
        history.push(`/dashboard/matchs/${id}`)
    }

    return (
        <div className={"rounded-xl p-1 md:w-4/4"} style={{margin: "auto"}}>
            <div className={"border-2 rounded-lg p-4 bg-white h-full relative bg-opacity-90 border-1-2 border-2"}>
                <p className={"text-center font-bold mb-4 text-xl"}>
                    Liste des matchs
                </p>

                <div className={"hidden md:block"}>

                    <ReactDataGrid

                        onRowClick={(i) => i >= 0 && moveToDetails(matchs[i]._id)}
                        columns={columns}
                        rowGetter={i => {
                            var doc = matchs[i] || {}
                            var hasRead = hasReadRoom(context.data.user.company, doc)
                            doc.hasRead = hasRead
                            return doc
                        }}
                        rowsCount={matchs.length}
                        rows={matchs}
                        rowHeight={50}
                        minHeight={(matchs.length + 1) * 50 + 2}/>

                </div>

                <div className={"md:hidden"}>
                    {matchs.map((elem, index) => {
                        var type = ""
                        var title =  ""
                        if (elem._job){
                            type = "Offre"
                            title = elem._job.name
                        } else if (elem._company){
                            type = "Entreprise"
                            title = elem._company.denomination
                        }


                        var userTitle =  elem._user.firstName + " " + elem._user.lastName
                        var hasRead = elem.hasRead
                        var sender = null
                        var message = ""
                        var messages =  elem.messages
                        if (messages && messages.length > 0){

                            if (message._user){
                                sender = message._user.firstName + " " + message._user.lastName + " :"
                            } else {
                                sender =  message.content
                            }
                            message = message.content

                        }

                        var state =  elem.state === 0 ? "Refusé" : (elem.state === 1 ? "En attente" : "Validé")

                        if (elem.state === 1){
                            if (elem.isCandidatSender){
                                state = "En attente de votre réponse."
                            } else {
                                state = "En attente de la réponse du candidat"
                            }
                        }
                        var msgState = elem.state === 1 && elem.isCandidatSender && "Cliquez ici pour traiter ce match"



                        return (<div className={"bg-white p-4 mb-4 border-2 border-1-2   rounded " + (elem.archived ? "bg-red-50" : "") } onClick={() => moveToDetails(elem._id)}>
                            <p ><span className={"text-xl font-bold"}>{type} {title}</span></p>
                            <p className={""}>{userTitle}</p>
                            {(sender || message) && <p>{sender || ""} : {message || ""}</p>}
                            <p className={"italic mt-4"}>{state}</p>
                            <p className={"italic"}>{msgState}</p>

                        </div>)
                    })}


                </div>


            </div>
        </div>
    )
}
