import React, {useEffect, useState} from 'react'
import adminServices from "../../services/admin";
import {useHistory} from "react-router-dom";

const CompanyNews = () => {
  const history = useHistory();
  const [objects, setObjects] = useState(null);

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    const res = await adminServices.getNews(10);

    setObjects(res);
  }

  const getLogo = (company) => {
    if (company.logo && company.logo.length > 0) {
      return company.logo;
    }
    return (
      "https://eu.ui-avatars.com/api/?background=BB2687&color=FFF&rounded=true&name=" +
      company.denomination
    );
  };

  return (
    <div className="grid grid-cols-2 gap-6">
      <div className="space-y-2">
        <p className="text-2xl font-bold ml-10">Dernières entreprises</p>
        {objects && objects.companies && objects.companies.map(company => {
          return (
            <div onClick={() => {
              history.push("/admin/company/" + company._id)
            }} className="bg-white cursor-pointer border rounded gap-4 flex  justify-center items-center py-2">
              <img src={getLogo(company)} alt="logo" className="w-14 h-14 object-contain"/>
              <div>
                <p className="font-bold">{company.denomination}</p>
                <p className={"text-sm"}><strong>Date de création :</strong> {new Date(company.mongoose_created_at).toLocaleDateString()}</p>
              </div>
            </div>
          );
        })}
      </div>
      <div className="space-y-2">
        <p className="text-2xl font-bold ml-10">Dernières offres d'emplois</p>
        {objects && objects.jobs && objects.jobs.map(job => {
          return (
            <div onClick={() => {
              history.push("/admin/jobs/" + job._id)
            }} className="bg-white cursor-pointer border rounded gap-4 flex flex-col justify-center items-center py-2">
              <div>
                <p className="font-bold">{job.name}</p>
                <p className={"text-sm"}><strong>Entreprise :</strong> {job?.company?.denomination}</p>
                <p className={"text-sm"}><strong>Date de création :</strong> {new Date(job.mongoose_created_at).toLocaleDateString()}</p>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default CompanyNews;
