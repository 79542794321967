import React, { useContext, useEffect, useState } from "react";
import UserContext from "../../shared/context/UserContext";
import { InfoContext } from "../../shared/context/InfoContext";
import jobsServices from "../../services/jobs";
import companyServices from "../../services/company";
import SquareButton from "../commons/SquareButton";
import { useHistory } from "react-router";

export const DashboardMain = () => {
  const history = useHistory();
  const { user, setUser } = useContext(InfoContext);
  const context = useContext(UserContext);

  const [jobsCount, setJobsCount] = useState({ count: 0, archived: 0 });
  const [matchs, setMatch] = useState({
    total: 0,
    waiting: 0,
    validate: 0,
    refused: 0,
  });
  const reloadData = async () => {
    const count = await jobsServices.count();
    setJobsCount(count);

    const matchs = await companyServices.getMyCompanyMatch();
    var waitingCount = matchs.filter((elem) => elem.state === 1).length;
    var validateCount = matchs.filter((elem) => elem.state === 2).length;
    var refusedCount = matchs.filter((elem) => elem.state === 0).length;
    setMatch({
      total: matchs.length,
      waiting: waitingCount,
      validate: validateCount,
      refused: refusedCount,
    });
  };
  useEffect(() => {
    reloadData();
  }, [user]);
  return (
    <div
      className={"rounded-xl p-1 md:w-3/4 lg:w-2/4"}
      style={{ margin: "auto" }}
    >
      <div
        className={
          "border-2 rounded-lg p-4 bg-white h-full relative bg-opacity-90 border-1-2 border-2"
        }
      >
        {user && (
          <div>
            <p className={"text-xl  mb-4"}>
              Bonjour{" "}
              <span className={"text-3xl font-black"}>
                {user.firstName} {user.lastName}
              </span>
            </p>

            {user._company && (
              <div>
                <p className={"mb-4"}>
                  Vous representez la société{" "}
                  <span className={"font-black"}>
                    {user._company.denomination}
                  </span>
                </p>

                <div className={"grid grid-cols-1 md:grid-cols-3 my-4 mt-8"}>
                  <div>
                    <div
                      className={
                        "p-4 text-white font-bold main-bg-1 rounded flex flex-col items-center justify-center"
                      }
                    >
                      <p className={"text-sm"}>
                        Nombre d'offres d'emploi active
                      </p>
                      <p className={"font-black text-5xl mt-2"}>
                        {jobsCount.count}
                      </p>
                    </div>
                    <SquareButton
                      onClick={() => {
                        history.push("/dashboard/jobs");
                      }}
                      text={"Voir mes offres"}
                      className={"color-button-violet mb-2 mt-2"}
                      style={{ width: "100%" }}
                    />
                  </div>

                  <div className={"md:col-start-3 mt-10 md:mt-0"}>
                    <div
                      className={
                        "  p-4 color-1-2  font-bold border-1-2 border-2 rounded flex flex-col items-center justify-center"
                      }
                    >
                      <p className={"text-sm"}>
                        Nombre d'offres d'emploi archivée
                      </p>
                      <p className={"font-black text-5xl mt-2"}>
                        {jobsCount.archived}
                      </p>
                    </div>

                    <SquareButton
                      onClick={() => {
                        history.push("/dashboard/jobs");
                      }}
                      text={"Créer une offre"}
                      className={"color-button-violet mt-2"}
                      style={{ width: "100%" }}
                    />
                  </div>

                  <div className={"md:col-span-3 mt-10 md:mt-4"}>
                    <div
                      className={
                        "  p-4 color-1-2  font-bold border-1-2 border-2 rounded flex flex-col items-center justify-center"
                      }
                    >
                      <p className={"text-sm"}>Vos matchs</p>
                      <div className={"grid grid-cols-3 text-center w-full"}>
                        <div className={"font-black text-5xl mt-2"}>
                          <p>{matchs.validate}</p>
                          <p className={"font-thin text-sm"}>Validée</p>
                        </div>
                        <div
                          className={
                            "font-black text-5xl mt-2 " +
                            (matchs.waiting > 0 ? "blink_me" : "")
                          }
                        >
                          <p className={""}>{matchs.waiting}</p>
                          <p className={"font-thin text-sm"}>En attente</p>
                        </div>
                        <div className={"font-black text-5xl mt-2"}>
                          <p>{matchs.refused}</p>
                          <p className={"font-thin text-sm"}>Refusé</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className={"md:col-start-3"}>
                    <SquareButton
                      onClick={() => {
                        history.push("/dashboard/matchs");
                      }}
                      text={"Voir mes matchs"}
                      className={"color-button-violet mt-2"}
                      style={{ width: "100%" }}
                    />
                  </div>
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};
