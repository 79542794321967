import { css, StyleSheet } from 'aphrodite';
import React, { useContext } from 'react';
import { Route, Switch, useHistory, useRouteMatch } from 'react-router-dom';
import ForgotPwd from '../components/authentification/ForgotPwd';
import ForgotPwdUser from '../components/authentification/ForgotPwdUser';
import Login from '../components/authentification/Login';
import Register from '../components/authentification/Register';
import SelectCompany from '../components/authentification/SelectCompany';
import UserContext from '../shared/context/UserContext';

const Authentication = () => {
  const history = useHistory();
  let match = useRouteMatch();
  const context = useContext(UserContext);
  const styles = StyleSheet.create({
    container: {
      minWidth: 350,
      maxWidth: '100%',
      paddingLeft: '5%',
      paddingRight: '5%',
    },
  });

  if (context.data.user && context.data.user._company) {
    history.push('/dashboard');
  }

  return (
    <div className={'flex h-screen '}>
      <div className={'mx-auto mt-10 w-full'}>
        <div className={css(styles.container)}>
          <img className={'mb-8 mx-auto'} alt="logo" width={250} src={process.env.PUBLIC_URL + '/assets/logo/logonew.png'} />

          <Switch>
            <Route exact path={`${match.path}/login`} component={Login} />
            <Route path={`${match.path}/register`} component={Register} />
            <Route exact path={'/auth/selectcompany'} component={SelectCompany} />
            <Route exact path={`${match.path}/forgotpwd`} component={ForgotPwd} />
            <Route exact path={`${match.path}/forgotpwduser`} component={ForgotPwdUser} />
          </Switch>
        </div>
      </div>
    </div>
  );
};

export default Authentication;
