

import React from 'react';

const LastMessageMatchFormatter = (data) =>{
    var messages =  data.row.messages
    if (messages && messages.length > 0){
        var message = messages[0]
        var sender = ""
        if (message._user){
            sender = message._user.firstName + " " + message._user.lastName + " :"
        } else {
            sender =  message.content
        }
        return (
            <div >

                <p className={"italic"}>
                    {!data.row.hasRead &&
                    <p style={{
                            backgroundColor: "#BB2687",
                            width: 10,
                            height: 10,
                            borderRadius: 10,
                            marginRight: 5,
                            display: "inline-block"
                        }}/>
                    }
                    {sender} {message.content}</p>
            </div>
        )
    }

    return (
        <div>
            <p className={"italic"}>Aucun message</p>
        </div>
    )
}

export default LastMessageMatchFormatter
