import request from '../shared/lib/AxiosWrap';
import {getAuthToken} from "../shared/function/generic";


async function getCards() {
    return request({
        url : `/v1/users/cards`,
        method : 'POST',
        headers: {'Authorization': getAuthToken()}
    })
}


async function acceptUser(userId) {
    return request({
        url : `/v1/users/${userId}/accept`,
        method : 'POST',
        headers: {'Authorization': getAuthToken()}
    })
}

async function declineUser(userId) {
    return request({
        url : `/v1/users/${userId}/decline`,
        method : 'POST',
        headers: {'Authorization': getAuthToken()}
    })
}





const userServices = {
    getCards,
    acceptUser,
    declineUser
};

export default userServices;
