import React, { useState, useEffect} from 'react';
import utilsServices from "../../services/utils";
import {toastError} from "../../shared/function/generic";

const LogoUpdate = ({url, onUpdate}) => {
    var _fileInput = null
    const [file, setFile] = useState(url)
    const [isSending, setIsSending] = useState(false)

    useEffect(() => {
        setFile(url)
    }, [url])
    const onChangeHandler = async (event) => {

        uploadFile(event.target.files[0])
    }


    const uploadFile = async (file, number) => {
        if (file == null){
            return
        }
        setIsSending(true)
        utilsServices.uploadFile(file).then(response => {
            const url = response.url
            if (url){
                setFile(url)
                onUpdate(url)
            } else {
                toastError("Impossible d'envoyer l'image")
            }
            setIsSending(false)
        })
    }

    return (
        <div onClick={() => _fileInput.click()} className={"overflow-hidden bg-gray-300 rounded-full flex flex-wrap justify-center content-center cursor-pointer hover:bg-gray-200 hover:text-gray-100 transition"} style={{ width : 65, height : 65}}>
            {file && !isSending && <img alt={"logo"} src={file}/> }
            {isSending && <div className="loader"></div>}
            {!file && !isSending && <p style={{fontSize : 40}}> + </p>}
            <input ref={fileInput => _fileInput = fileInput} type="file" name="file" onChange={onChangeHandler} className={"hidden"} accept="image/*"/>
        </div>
    );
};

export default LogoUpdate;
