import { getAuthToken } from '../shared/function/generic';
import request from '../shared/lib/AxiosWrap';

async function getRoomById(roomId) {
  return request({
    url: `/v1/rooms/${roomId}`,
    method: 'GET',
    headers: { Authorization: getAuthToken() },
  });
}

async function acceptById(roomId) {
  return request({
    url: `/v1/rooms/${roomId}/accept`,
    method: 'POST',
    headers: { Authorization: getAuthToken() },
  });
}

async function declineById(roomId) {
  return request({
    url: `/v1/rooms/${roomId}/decline`,
    method: 'POST',
    headers: { Authorization: getAuthToken() },
  });
}

async function getMessages(roomId) {
  return request({
    url: `/v1/rooms/${roomId}/messages`,
    method: 'GET',
    headers: { Authorization: getAuthToken() },
  });
}

async function sendMessage(roomId, data) {
  return request({
    url: `/v1/rooms/${roomId}/message`,
    method: 'POST',
    data: data,
    headers: { Authorization: getAuthToken() },
  });
}

async function readMessage(messageId) {
  return request({
    url: `/v1/messages/${messageId}/read`,
    method: 'POST',
    headers: { Authorization: getAuthToken() },
  });
}

const roomServices = {
  getRoomById,
  acceptById,
  declineById,
  getMessages,
  sendMessage,
  readMessage,
};

export default roomServices;
