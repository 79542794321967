import React, {useState, useContext} from 'react';
import SquareInput from "../../commons/SquareInput";
import {useHistory} from "react-router-dom";
import SquareButton from "../../commons/SquareButton";
import companyServices from "../../../services/company";
import {toastError} from "../../../shared/function/generic";
import accountServices from "../../../services/account";
import User from "../../../shared/class/User";
import UserContext from "../../../shared/context/UserContext";
import {InfoContext} from "../../../shared/context/InfoContext";
const JoinCompany = () => {
    const [form, setForm] = useState({code: ""})
    const context = useContext(UserContext);
    const history = useHistory()
    const {user, setUser} = useContext(InfoContext)
    const joinCompany = async () => {
        try {
            await companyServices.join(form.code)
            const newUser = await accountServices.token()
            context.update({...context.data, user: new User(newUser)})
            setUser(newUser)
            history.push("/dashboard")
        } catch (e){
            toastError(e.data)
        }

    }
    return (
        <div className={"main-bg-1 rounded-2xl p-2 text-white"}>
            <div className={"border-2 rounded-xl p-4 border-white flex flex-col justify-between"}>

            <p className={"font-bold text-xl"}>Votre entreprise est déjà inscrite ? </p>
            <p>Rentrez le code d'invitation pour la rejoindre</p>
            <form onKeyPress={(e) => { e.key === 'Enter' && joinCompany() && e.preventDefault() }} className={"mb-4 mt-4"}>
                <label className={"block font-semibold"}>{"Code d'invitation"}</label>
                <SquareInput type={"text"} className={"focus:border-blue-300 focus:border-2 mb-5 text-black"} name={"code"}
                             setValues={setForm}
                             state={form} value={form.code} placeholder={"Code"}/>

            </form>
            <SquareButton onClick={() => joinCompany()} text={"Rejoindre"} className={"text-white bg-white bg-opacity-20"}/>


            </div>

        </div>
    );
};

export default JoinCompany;
