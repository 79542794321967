import Cookies from 'js-cookie';
import {toast} from "react-toastify";

export const toastError = (message) => {

    var msg = message
    if( (typeof message === "object" ) && (message !== null) )
    {
        msg = msg.toString()
    }

    toast.error(msg, {
        autoClose : 2500,
        position: toast.POSITION.TOP_RIGHT
    })
}

export const toastSuccess = (message) => {
    toast.success(message, {
        autoClose : 1500,
        position: toast.POSITION.TOP_RIGHT
    })
}
export const stringToColour = (str) => {
    if (str == null){
        return "#000000"
    }
    var hash = 0;
    for (var i = 0; i < str.length; i++) {
        hash = str.charCodeAt(i) + ((hash << 5) - hash);
    }
    var colour = '#';
    for (var j = 0; j < 3; j++) {
        var value = (hash >> (j * 8)) & 0xFF;
        colour += ('00' + value.toString(16)).substr(-2);
    }
    return colour;
}

export const textColorFromColorBackground = (colorHex) => {
    if (colorHex) {
        var r = parseInt(colorHex.slice(1, 3), 16),
            g = parseInt(colorHex.slice(3, 5), 16),
            b = parseInt(colorHex.slice(5, 7), 16);

        const brightness = Math.round(((parseInt(r) * 299) +
            (parseInt(g) * 587) +
            (parseInt(b) * 114)) / 1000);

        const textColour = (brightness > 125) ? '#000000' : '#FFFFFF';
        return textColour

    }
    return "#000000"
}


export const  hasReadRoom = (companyId, room) => {
    if (!room){
        return true
    }
    var hasRead = false
    var userId = companyId
    if (room.messages && room.messages.length > 0) {
        if (room.messages[0].readAt && room.messages[0].readAt.length > 0){
            var index = room.messages[0].readAt.findIndex(elem => {
                return elem.user === userId
            })
            hasRead = index !== -1
        }
    } else {
        hasRead = true
    }
    return hasRead
}


export const  hasReadMessage = (companyId, message) => {
    if (!message){
        return true
    }
    var hasRead = false
    var userId = companyId

        if (message.readAt && message.readAt.length > 0){
            var index = message.readAt.findIndex(elem => {
                return elem.user === userId
            })
            hasRead = index !== -1
        }

    return hasRead
}

export const generateId = () => {
    return 'id-' + Math.random().toString(36).substr(2, 16);
};

export const getAuthToken = () => {
    return Cookies.get('hiofukt');
};
