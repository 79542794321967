

import React from 'react';
const CandidatMatchFormatter = (data) =>{

    var title =  data.row._user.firstName + " " + data.row._user.lastName

    return (
        <div>
            <p>{title}</p>
        </div>
    )
}

export default CandidatMatchFormatter
