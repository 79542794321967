import React, { useEffect, useState } from "react";
import { BsSearch } from "react-icons/bs";
import { useHistory } from "react-router";
import adminServices from "../../services/admin";
const CompanyListAdmin = () => {
    const [companies, setCompanies] = useState([]);
    const [allCompanies, setAllCompanies] = useState([]);
    const [search, setSearch] = useState("");
    const history = useHistory();
    const reloadList = async () => {
        var arr = await adminServices.getAdminCompany();
        setAllCompanies(arr);
        console.log(arr);
    };

    useEffect(() => {
        if (search && search.length > 0) {
            setCompanies(
                allCompanies.filter((e) => {
                    return e.denomination.toLowerCase().indexOf(search.toLowerCase()) != -1;
                })
            );
        } else {
            setCompanies(allCompanies);
        }
    }, [allCompanies, search]);
    const getLogo = (company) => {
        if (company.logo && company.logo.length > 0) {
            return company.logo;
        }
        return "https://eu.ui-avatars.com/api/?background=BB2687&color=FFF&rounded=true&name=" + company.denomination;
    };
    useEffect(() => {
        reloadList();
    }, []);

    return (
        <div className={"w-full bg-white p-4 border-gray-300 border-2 "}>
            <p class="text-2xl font-bold mb-3">Liste des entreprises ({allCompanies.length})</p>
            <div class="flex justify-center w-max items-center border rounded px-2 py-2 mb-3 relative text-gray-600">
                <input class="focus:outline-none mr-2" type="search" value={search} onChange={(e) => setSearch(e.target.value)} name="search" placeholder="Search" />
                <BsSearch />
            </div>

            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-6 gap-4">
                {companies.map((e) => {
                    return (
                        <div
                            onClick={() => {
                                history.push("/admin/company/" + e._id);
                            }}
                            className="cursor-pointer border rounded gap-4 flex flex-col justify-center items-center py-2"
                        >
                            <img src={getLogo(e)} className="w-1/3" />

                            <p className="font-bold">{e.denomination}</p>
                        </div>
                    );
                })}
            </div>
        </div>
    );
};

export default CompanyListAdmin;
