import React, {useState} from 'react';
import SquareInput from "../commons/SquareInput";
import {useHistory} from "react-router-dom";
import SquareButton from "../commons/SquareButton";
import accountServices from "../../services/account";
import {toastError, toastSuccess} from "../../shared/function/generic";
import {dev} from '../../config';
const queryString = require('query-string');



const ForgotPwdUser = (navigation) => {
    const [form, setForm] = useState({email : "", password:  ""})
    const history = useHistory()
    const [id] = useState(queryString.parse(navigation.location.search).id)
    const [pwdType, setPwdType] = useState('password');
    const [isEnd, setIsEnd] = useState(false)
    if (dev && form.email === ''){
        setForm({
            email : 'remi@usekey.fr'
        })
    }
    const sendEmail = async () => {
        try {
            await accountServices.forgotPasswordUser(form.email)
            toastSuccess("Email envoyé")
        } catch (e){

            toastError(e.data)
        }
    }

    const updatePassword = async () => {

            try {
                console.log(form.password)
                await accountServices.updateForgotPasswordUser(form.password, id)
                toastSuccess("Mot de passe modifié. Vous pouvez vous connecter")
                setIsEnd(true)
            } catch (e){

                toastError(e.data)
            }


    }


    if (!id) {
        return (
            <div style={{width: 350, margin: "auto"}} className={"bg-white p-4 rounded-lg bg-opacity-90"}>
                <p>Mot de passe modifié. Vous pouvez vous connecter</p>

            </div>
        );
    } else if (isEnd) {
        return (
            <div style={{width: 350, margin: "auto"}} className={"bg-white p-4 rounded-lg bg-opacity-90"}>
                <p>Renseigner votre nouveau mot de passe</p>
                <form autoComplete="on" onKeyPress={(e) => {
                    e.key === 'Enter' && sendEmail() && e.preventDefault()
                }} className={"mb-4 mt-4"}>
                    <div className={"w-full "}>
                        <label className={"inline-block font-semibold"}>{"Mot de passe"}</label>
                        <p onClick={() => {
                            pwdType === 'text' ? setPwdType('password') : setPwdType('text')
                        }}
                           className={"inline-block float-right link cursor-pointer transition-2 font-bold text-sm color-1-1"}>
                            {pwdType === 'text' ? "Cacher" : "Voir"}
                            {" le mot de passe"}</p>
                    </div>
                    <SquareInput type={pwdType} className={"focus:border-blue-300 focus:border-2"} name={"password"}
                                 setValues={setForm}
                                 autoComplete={"current-password"}
                                 state={form} value={form.password} placeholder={""}/>
                </form>
                <SquareButton onClick={() => updatePassword()} text={"Modifier"} className={"color-button-violet"}/>

            </div>
        );
    } else {
        return (
            <div style={{width: 350, margin: "auto"}} className={"bg-white p-4 rounded-lg bg-opacity-90"}>
                <p>Renseigner votre nouveau mot de passe</p>
                <form autoComplete="on" onKeyPress={(e) => {
                    e.key === 'Enter' && sendEmail() && e.preventDefault()
                }} className={"mb-4 mt-4"}>
                    <div className={"w-full "}>
                        <label className={"inline-block font-semibold"}>{"Mot de passe"}</label>
                        <p onClick={() => {
                            pwdType === 'text' ? setPwdType('password') : setPwdType('text')
                        }}
                           className={"inline-block float-right link cursor-pointer transition-2 font-bold text-sm color-1-1"}>
                            {pwdType === 'text' ? "Cacher" : "Voir"}
                            {" le mot de passe"}</p>
                    </div>
                    <SquareInput type={pwdType} className={"focus:border-blue-300 focus:border-2"} name={"password"}
                                 setValues={setForm}
                                 autoComplete={"current-password"}
                                 state={form} value={form.password} placeholder={""}/>
                </form>
                <SquareButton onClick={() => updatePassword()} text={"Modifier"} className={"color-button-violet"}/>

            </div>
        );
    }
};

export default ForgotPwdUser;
