
import React, { useState, createContext, useEffect } from "react";


export const InfoContext = createContext(null)

export const InfoProvider = props => {
    const [user, setUser] = useState(null)


    var value = {user, setUser}


    return (
        <InfoContext.Provider value={value}>

            {props.children}
        </InfoContext.Provider>
    );
}
