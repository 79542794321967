import React, { useContext} from 'react';
import {useHistory} from "react-router-dom";
import UserContext from "../../shared/context/UserContext";
import Cookies from "js-cookie";
import {InfoContext} from "../../shared/context/InfoContext";

const Logout = () => {
    const {user, setUser} = useContext(InfoContext)
    const context = useContext(UserContext);
    const history = useHistory()
        context.update({})
    setUser(null)
        Cookies.remove('hiofukt');
        Cookies.remove('hiofuktperm');
        history.push("/")
    return (
        <div style={{width : 350, margin : "auto"}} className={"bg-white p-4 rounded-lg bg-opacity-90"}>

        </div>
    );
};

export default Logout;
