import React, {useState} from 'react';
import JoinCompany from "./SelectCompany/JoinCompany";
import SelectCreateCompany from "./SelectCompany/SelectCreateCompany";
import CreateCompany from "./SelectCompany/CreateCompany";

const SelectCompany = () => {
    const [isCreating, setIsCreating] = useState(false)
    return (
        <div style={{maxWidth : 850, margin : "auto"}}>
            {isCreating && <CreateCompany></CreateCompany>}
            {!isCreating &&
            <div className={"grid grid-cols-1 md:grid-cols-2 gap-4"}>
                <JoinCompany className={"col-span-1"}/>
                <SelectCreateCompany className={"col-span-1"} onSelected={() => {
                    setIsCreating(true)
                }}/>
            </div>
            }
        </div>
    );
};

export default SelectCompany;
