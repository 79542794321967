import request from '../shared/lib/AxiosWrap';
import {getAuthToken} from "../shared/function/generic";

async function createCompany(company) {
    return request({
        url: `/v1/recruiter/company`,
        method: 'PUT',
        data : company,
        headers: {'Authorization': getAuthToken()}
    });
}


async function join(code) {
    return request({
        url: `/v1/recruiter/company/join`,
        method: 'POST',
        data : {
            code : code
        },
        headers: {'Authorization': getAuthToken()}
    });
}

async function getMyCompany(){
    return request({
        url: `/v1/recruiter/company`,
        method: 'GET',
        headers: {'Authorization': getAuthToken()}
    });
}


async function getMyCompanyMatch(){
    return request({
        url: `/v1/recruiter/company/matchs`,
        method: 'GET',
        headers: {'Authorization': getAuthToken()}
    });
}


async function updateMyCompany(company) {
    return request({
        url: `/v1/recruiter/company`,
        method: 'POST',
        data : company,
        headers: {'Authorization': getAuthToken()}
    });
}



async function deleteMyCompany(company) {
    return request({
        url: `/v1/recruiter/company`,
        method: 'DELETE',
        data : company,
        headers: {'Authorization': getAuthToken()}
    });
}
const companyServices = {
    updateMyCompany,
    getMyCompany,
    createCompany,
    getMyCompanyMatch,
    deleteMyCompany,
    join
};

export default companyServices;
