import React, {useState, useEffect} from 'react';
import {useHistory, useParams} from 'react-router-dom';
import DatePicker from "react-datepicker";
import 'react-confirm-alert/src/react-confirm-alert.css';
import "react-datepicker/dist/react-datepicker.css";
import {toastError, toastSuccess} from "../../shared/function/generic";
import SquareButton from "../commons/SquareButton";
import SquareInput from "../commons/SquareInput";
import Autocomplete from "react-google-autocomplete";
import configsServices from "../../services/config";
import jobsServices from "../../services/jobs";
import {confirmAlert} from 'react-confirm-alert';
import utilsServices from "../../services/utils";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEye} from "@fortawesome/free-regular-svg-icons";
import {faCross, faDownload, faTimesCircle, faWindowClose} from "@fortawesome/free-solid-svg-icons";
import { Editor } from 'react-draft-wysiwyg';
import '../../styles/react-draft.css';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import { EditorState, convertToRaw, convertFromRow, ContentState } from 'draft-js';
import {Document, Page, pdfjs} from "react-pdf";
import Modal from "react-modal";
import {css, StyleSheet} from "aphrodite";
import {JobDetailMatch} from "./JobDetailMatch";

pdfjs.GlobalWorkerOptions.workerSrc=`//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`




const styles = StyleSheet.create({
    canvasContent: {
        width : 'fit-content',
        margin : 'auto',
        backgroundColor : '#a2a2a2',
        padding : 10
    },
    imagePreview : {
        height: 150,
        display: 'inline',
        marginRight: 15
    },
    pageContent : {
        backgroundColor: 'red'
    },
    closeButton : {
        top : 60,
        right : 70
    },
    downloadButton : {
        top : 60,
        right : 100
    },
    equipment : {
        cursor: 'pointer'
    }
});





const JobDetail = () => {
    const [contractType, setContractType] = useState([])
    const [levelEducation, setLevelEducation] = useState([])
    const history = useHistory();
    const [editorState, setEditorState] = useState(EditorState.createEmpty())
    const [modalDocumentIsOpen,setIsOpen] = React.useState({ open : false, url : ''});
    const [form, setForm] = useState({})
    const {id} = useParams();

    const [isExpand, setIsExpand] = useState(true)
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);


    var _fileInput = null
    const [file, setFile] = useState(null)
    const [isSending, setIsSending] = useState(false)
    const [numPages, setNumPages] = useState(null);

    const onDocumentLoadSuccess = ({ numPages }) => {
        setNumPages(numPages);
    }

    const onChangeHandler = async (event) => {
        uploadFile(event.target.files[0])
    }

    useEffect(() => {
        setForm({
            ...form,
            file : file
        })
    }, [file])

    const uploadFile = async (file, number) => {
        if (file == null) {
            return
        }
        setIsSending(true)
        utilsServices.uploadFile(file).then(response => {
            const url = response.url
            if (url) {
                setFile({name: file.name, url: url})
            } else {
                toastError("Impossible d'envoyer l'image")
            }
            setIsSending(false)
        })
    }

    useEffect(() => {
        const retreiveCurrentJobAction = async () => {
            if (id !== "create") {
                setIsExpand(true)

                try {
                    const job = await jobsServices.getById(id)
                    setForm(job)
                    if (job.startDate && job.startDate.trim().length > 0) {
                        setStartDate(new Date(job.startDate))
                    }
                    if (job.endDate && job.endDate.trim().length > 0) {
                        setEndDate(new Date(job.endDate))
                    }
                    if (job.description) {
                        var contentBlock = htmlToDraft(job.description)

                        const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
                        const editorState = EditorState.createWithContent(contentState);
                        setEditorState(editorState)
                    }
                    setFile(job.file || null)
                } catch (e) {

                    console.log(e)
                    toastError(e)
                    //history.push("/dashboard/jobs")
                }
            } else {
                setIsExpand(true)
            }
        }
        retreiveCurrentJobAction()
    }, [id])

    function updateFormData() {
        const setFormUpdate = async () => {
            setForm({
                ...form,
                startDate: startDate,
                endDate: endDate
            })
        }
        setFormUpdate()
    }

    useEffect(updateFormData, [startDate, endDate])

    const reloadConfig = async () => {
        const contractType = await configsServices.getContractType()
        setContractType(contractType)
        const levelEducation = await configsServices.getLevelEducation()
        setLevelEducation(levelEducation)
    }
    useEffect(() => {
        reloadConfig()
    }, [])


    const deleteCurrentJob = async () => {
        try {
            await jobsServices.deleteJob(id)
            history.push("/dashboard/jobs")
        } catch (e) {
            toastError(e)
        }
    }

    const archiveCurrentJob = async () => {
        setForm({
            ...form,
            archived : true
        })
        await jobsServices.updateById(id, {
            ...form,
            archived : true
        })
        toastSuccess("Cette offre est archivée")
    }

    const unarchiveCurrentJob = async () => {
        setForm({
            ...form,
            archived : false
        })
        await jobsServices.updateById(id, {
            ...form,
            archived : false
        })
        toastSuccess("Cette offre est archivée")
    }
    const isValid = (value) => {
        return value && value.length > 0
    }
    const save = async () => {
        try {
            if (id === "create") {
                console.log(form)
                if (!isValid(form.name)) {
                    toastError("Merci de remplir le titre de l'offre d'emploi")
                    return
                } else if (!isValid(form.contractType)) {
                    toastError("Merci de remplir le type de contrat de l'offre d'emploi")
                    return
                } else if (!form.place){

                    toastError("Merci de remplir le lieu de l'offre d'emploi grâce à l'autocompletion")
                    return
                }
                const newJob = await jobsServices.create(form)

                history.push("/dashboard/jobs")
            } else {
                await jobsServices.updateById(id, form)
                toastSuccess("Modification sauvegardé")
            }

        } catch (e) {
            toastError(e.data)
        }
    }

    const closeModal = () => {
        setIsOpen({ open : false, url : '', pictures : [], urlDownload : ''})
    }
    return (
        <div className={"rounded-xl p-1 md:w-3/4"} style={{margin: "auto"}}>
            <div className={"border-2 rounded-lg p-4 bg-white h-full relative bg-opacity-90 border-1-2 border-2"}>
                <p className={"text-center font-bold mb-4 text-xl"}>
                    {id === "create" ? "Création" : "Modification"} d'une offre d'emploi
                    {!isExpand && <span onClick={() => setIsExpand(true)} className={"absolute right-5 cursor-pointer px-2 rounded-lg select-none color-button-violet"}>&#8595;</span>}
                    {isExpand && <span onClick={() => setIsExpand(false)} className={"absolute right-5 cursor-pointer px-2 rounded-lg select-none color-button-violet"}>&#8593;</span>}
                </p>

                {isExpand &&
                    <div>
                <form onKeyPress={(e) => {
                    e.key === 'Enter' && e.target.name !== "description" && save() && e.preventDefault()
                }} className={"mb-4"}>
                    <div className={"grid grid-cols-1 sm:grid-cols-2 gap-4"}>
                        <div className={"col-span-1"}>
                            <label className={"block font-semibold"}>{"Titre *"}</label>
                            <SquareInput type={"text"} className={"focus:border-blue-300 focus:border-2 sm:mb-5"}
                                         name={"name"}
                                         setValues={setForm}
                                         state={form} value={form.name} placeholder={""}/>
                        </div>
                        <div className={"col-span-1 mb-4 sm:mb-0"}>
                            <label className={"block font-semibold"}>{"Type de contrat *"}</label>
                            <select
                                className={`transition-2 mt-1 block py-1 bg-gray-100 outline-none w-full rounded border p-2 border-gray-300 `}
                                value={form.contractType} onChange={(event) => {


                                setForm({
                                    ...form,
                                    contractType: event.target.value
                                })
                            }}>
                                <option value={""}>Non-défini</option>
                                {contractType.map((elem, index) => <option key={"contractType" + index}
                                                                           value={elem}>{elem}</option>)}
                            </select>
                        </div>
                    </div>
                    <div className={"grid grid-cols-1 sm:grid-cols-2 gap-4"}>
                        <div className={"col-span-2"}>
                            <label className={"block font-semibold"}>{"Lieu du poste *"}</label>
                            <Autocomplete
                                className={"block bg-gray-50 outline-none w-full rounded border p-2 focus:border-blue-300 py-1 border-gray-300 mt-2 mb-5"}
                                types={['address']}
                                componentRestrictions={{country: "fr"}}
                                placeHolder={"Rechercher une adresse"}
                                defaultValue={(form.place || {}).formatted_address}
                                onPlaceSelected={(place) => {
                                    setForm({
                                        ...form,
                                        place: place
                                    })
                                }}
                            >
                            </Autocomplete>
                        </div>
                    </div>

                    <div className={"grid grid-cols-1 sm:grid-cols-2 gap-4 mb-4"}>
                    <div>
                        <label className={"block font-semibold"}>{"Télétravail"}</label>
                        <span
                            className={(form.telework ? "main-bg-1 text-white" : "border-1-1 color-1-1 border") + " cursor-pointer inline-block  rounded-l-lg mt-2"}
                            style={form.telework ? {padding: 8} : {padding: 7, paddingLeft: 8, paddingRight: 8}}
                            onClick={() => {
                                setForm({
                                    ...form,
                                    telework: true
                                })
                            }}
                        >
                                Oui
                            </span>
                        <span
                            className={(!form.telework ? "main-bg-1 text-white" : "border-1-1 color-1-1 border") + " cursor-pointer inline-block rounded-r-lg mt-2"}
                            style={!form.telework ? {padding: 8} : {padding: 7, paddingLeft: 8, paddingRight: 8}}
                            onClick={() => {
                                setForm({
                                    ...form,
                                    telework: false
                                })
                            }}
                        >
                                Non
                            </span>
                    </div>
                    </div>


                    <div className={"grid grid-cols-1 sm:grid-cols-2 gap-4"}>

                        <div className={"col-span-1"}>
                            <label className={"block font-semibold"}>{"Niveau de formation"}</label>
                            <select
                                className={`transition-2 mt-1 block py-1 bg-gray-100 outline-none w-full rounded border p-2 border-gray-300 `}
                                value={form.levelEducation} onChange={(event) => {


                                setForm({
                                    ...form,
                                    levelEducation: event.target.value
                                })
                            }}>
                                <option value={""}>Non-défini</option>
                                {levelEducation.map((elem, index) => <option key={"levelEducation" + index}
                                                                             value={elem}>{elem}</option>)}
                            </select>
                        </div>
                        <div className={"col-span-1"}>
                            <label className={"block font-semibold"}>{"Nombre de postes à pouvoir"}</label>
                            <SquareInput type={"number"} className={"focus:border-blue-300 focus:border-2 mb-5"}
                                         name={"positionNumber"}
                                         setValues={setForm}
                                         state={form} value={form.positionNumber} placeholder={""}/>
                        </div>
                    </div>


                    <div className={"grid grid-cols-1 sm:grid-cols-2 gap-4"}>

                        <div className={"col-span-1"}>
                            <label className={"block font-semibold"}>{"Date de début du contrat"}</label>
                            <DatePicker
                                className="z-50 appearance-none w-full block mr-2 bg-gray-100 text-gray-700 border leading-normal border-gray-300 border rounded py-1 px-4 sm:mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                                style={{zIndex: 10000}}
                                wrapperClassName={"w-full"}
                                selected={startDate}
                                onChange={date => setStartDate(date)}
                                selectsStart
                                startDate={startDate}

                                dateFormat="dd/MM/yyyy"
                            />
                        </div>
                        {form.contractType !== "CDI" && form.contractType !== "" && form.contractType !== undefined &&
                        <div className={"col-span-1"}>
                            <label className={"block font-semibold"}>{"Date de fin de contrat"}</label>
                            <DatePicker
                                className="appearance-none w-full block mr-2 bg-gray-100 text-gray-700 border leading-normal border-gray-300 border rounded py-1 px-2 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                                wrapperClassName={"w-full"}
                                selected={endDate}
                                onChange={date => setEndDate(date)}
                                selectsStart
                                startDate={endDate}

                                dateFormat="dd/MM/yyyy"
                            />
                        </div>
                        }
                    </div>


                    <div className={"grid grid-cols-1 sm:grid-cols-2 gap-4 mb-4"}>
                        <div className={"col-span-1"}>
                            <label className={"block font-semibold"}>{"Rémunération brute (en €)"}</label>
                            <SquareInput type={"text"} className={"focus:border-blue-300 focus:border-2 mb-0 sm:mb-5"}
                                         name={"remuneration"}
                                         setValues={setForm}
                                         state={form} value={form.remuneration} placeholder={""}/>
                        </div>

                        <div>
                            <label className={"block font-semibold"}>{"Déplacements"}</label>
                            <span
                                className={(form.shifting ? "main-bg-1 text-white" : "border-1-1 color-1-1 border") + " cursor-pointer inline-block  rounded-l-lg mt-2"}
                                style={form.shifting ? {padding: 8} : {padding: 7, paddingLeft: 8, paddingRight: 8}}
                                onClick={() => {
                                    setForm({
                                        ...form,
                                        shifting: true
                                    })
                                }}
                            >
                                Oui
                            </span>
                            <span
                                className={(!form.shifting ? "main-bg-1 text-white" : "border-1-1 color-1-1 border") + " cursor-pointer inline-block rounded-r-lg mt-2"}
                                style={!form.shifting ? {padding: 8} : {padding: 7, paddingLeft: 8, paddingRight: 8}}
                                onClick={() => {
                                    setForm({
                                        ...form,
                                        shifting: false
                                    })
                                }}
                            >
                                Non
                            </span>
                        </div>


                    </div>


                    <label className={"block font-semibold"}>{"Présentation du poste et des missions"}</label>

                    <Editor
                        editorState={editorState}
                        toolbar={{
                            options: ['inline', 'blockType', 'fontSize', 'list', 'textAlign', 'history'],
                        }}
                        onEditorStateChange={(e) => {
                            console.log(e)
                            setEditorState(e)
                            setForm({
                                ...form,
                                description: draftToHtml(convertToRaw(e.getCurrentContent()))
                            })
                        }}
                    />

                </form>

                    <div className={"grid grid-cols-1 sm:grid-cols-2 gap-4 mb-4"}>
                        <div className={"col-span-1"}>
                            <label className={"block font-semibold"}>{"Pièce jointe "}<span className={"font-light italic text-sm"}>(pdf uniquement)</span></label>
                            {file && !isSending &&

                            <div className={"flex"}>
                                <p>{file.name}
                                </p>
                                <button
                                    className={"ml-2"}
                                    onClick={() => {

                                        if (1 == 1 || file.url.indexOf(".pdf") == -1){
                                            window.open(file.url, "_blank")
                                        } else {

                                            setIsOpen({open: true, url: file.url, pictures: [], urlDownload: file.url})
                                        }
                                    }
                                    }>

                                    <FontAwesomeIcon style={{marginRight: 5}} icon={faEye}/>
                                </button>


                                <button
                                    className={"ml-2"}
                                    onClick={() => {
                                        setFile(null)
                                    }
                                    }>
                                    <FontAwesomeIcon style={{marginRight: 5}} icon={faTimesCircle}/>
                                </button>


                            </div>

                            }
                            {(!file || isSending) &&
                            <div onClick={() => _fileInput.click()}
                                 className={"overflow-hidden bg-gray-300 rounded-full flex flex-wrap justify-center content-center cursor-pointer hover:bg-gray-200 hover:text-gray-100 transition"}
                                 style={{width: 105, height: 45}}>

                                {isSending && <div className="loader"></div>}
                                {!file && !isSending && <p style={{fontSize: 40}}> + </p>}
                                <input ref={fileInput => _fileInput = fileInput} type="file" name="file"
                                       accept="application/pdf"
                                       onChange={onChangeHandler} className={"hidden"}/>
                            </div>
                            }
                        </div>


                    </div>



                <span className={"italic"}>* : Obligatoire</span>
                <div className={"flex justify-end"}>
                    <SquareButton onClick={() => {
                        save()
                    }} text={id === "create" ? "Créer" : "Modifier"} className={"text-white main-bg-1 bg-opacity-20"}/>

                </div>
                        {id !== "create" &&
                        <div className={"flex justify-end"}>
                            <SquareButton onClick={() => {

                                confirmAlert({
                                    title: "Supression",
                                    message: form.archived ? "Vouez-vous activer cette offre d'emploi ? Elle sera visible des candidats" : "Voulez-vous archiver cette offre d'emploi ? Elle ne sera plus visible des candidats",
                                    buttons: [
                                        {
                                            label: "Non",
                                            onClick: () => {
                                            }
                                        },
                                        {
                                            label: "Oui",
                                            onClick: () => {
                                                form.archived ? unarchiveCurrentJob() : archiveCurrentJob()

                                            }
                                        }
                                    ]
                                })
                            }} text={form.archived ? "Activer" : "Archiver"}
                                          className={" border-1-2 border-1 color-1-2  bg-opacity-20 mt-2"}/>

                        </div>
                        }
                </div>
                }
                {id !== "create" && isExpand &&
                <div className={"flex justify-end"}>
                    <SquareButton onClick={() => {

                        confirmAlert({
                            title: "Supression",
                            message: "Voulez-vous supprimer cette offre d'emploi ?",
                            buttons: [
                                {
                                    label: "Non",
                                    onClick: () => {
                                    }
                                },
                                {
                                    label: "Oui",
                                    onClick: () => {
                                        deleteCurrentJob()
                                    }
                                }
                            ]
                        })
                    }} text={"Supprimer"} className={" color-1-2  bg-opacity-20 mt-2"}/>

                </div>
                }
            </div>

            <JobDetailMatch jobId={id}/>

            <Modal isOpen={modalDocumentIsOpen.open}  style={{zIndex : 1000}}>
                <div className={"relative"}>
                    <button className={"fixed " + css(styles.closeButton)} onClick={closeModal} > <FontAwesomeIcon className={"mx-auto"} icon={faTimesCircle}/></button>
                    <div className={css(styles.canvasContent)}>




                        <Document
                            className={"margin-auto mt10"}
                            file={ { url: modalDocumentIsOpen.url, httpHeaders: { 'Access-Control-Allow-Origin': '*' } }}

                            onLoadError={(e) => {
                                console.log(e)
                            }}
                            onLoadSuccess={onDocumentLoadSuccess}
                        >
                            {
                                Array.from(
                                    new Array(numPages),
                                    (el, index) => (
                                        <Page

                                            key={`page_${index + 1}`}
                                            pageNumber={index + 1}
                                        />
                                    ),
                                )
                            }
                        </Document>
                    </div>
                </div>
            </Modal>

        </div>
    );
};

export default JobDetail;
