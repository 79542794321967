import React, {useContext} from 'react';
import Cookies from 'js-cookie';
import {Redirect, Route, useHistory} from 'react-router-dom';
import UserContext from "../shared/context/UserContext";
import accountServices from "../services/account";
import User from "../shared/class/User";
import {InfoContext} from "../shared/context/InfoContext";

const PrivateRoute = ({component: Component, ...rest}) => {
    const context = useContext(UserContext);
    const {user, setUser} = useContext(InfoContext)
    const history = useHistory()

    const reloadToken = async () => {
        try {
            const tokenresult = await accountServices.token()
            const user = new User(tokenresult)
            setUser(user)
            context.update({...context.data, user: user})
        } catch(e) {
            history.push("/logout")
        }
    }
    // eslint-disable-next-line no-mixed-operators
    if (context.data.user == null || Object.keys(context.data.user).length === 0 && Cookies.get('hiofukt')){
        reloadToken()
    }
    return (
        <Route
            {...rest}
            render={props =>
                Cookies.get('hiofukt') ? (
                    <Component {...props} />
                ) : (
                    <Redirect to="/logout"/>
                )
            }
        />
    );
};

export default PrivateRoute;
