import React, {useContext, useEffect} from 'react';
import {Route, Switch, useHistory, useRouteMatch} from 'react-router-dom';
import Header from "../components/sections/Header";

import LoaderComponent from "../components/commons/Loader";
import JobsList from "../components/jobs/JobsList";
import JobDetail from "../components/jobs/JobDetail";
import MyCompany from "../components/company/MyCompany";
import MatchsList from "../components/matchs/Matchs";
import MatchDetail from "../components/matchs/MatchDetail";
import SearchList from "../components/search/SearchList";
import UserContext from "../shared/context/UserContext";
import AdminRoute from "../routes/AdminRoute";
import PrivateRoute from "../routes/PrivateRoute";
import {DashboardMain} from "../components/dashboard/DashboardMain";
import MyProfilComponent from "../components/myprofil/MyProfilComponent";

const Dashboard = () => {
    let {url} = useRouteMatch();
    const context = useContext(UserContext);
    const history = useHistory()
    const fetchCurrent = async () => {
    }

    

    useEffect(() => {
        if (context.data.user && context.data.user._id != null){
            if (context.data.user.getNextViewLogin() === "/auth/selectcompany"){
                history.push(context.data.user.getNextViewLogin())
            }
        }
    },[context])


    return (
        <div>
            <Header/>


                <div className={"h-full-header"}>
                    <Switch>
                        <Route exact path={`${url}/jobs`}
                               component={() => <JobsList></JobsList>}/>
                        <Route exact path={`${url}/jobs/:id`}>
                            <JobDetail/>
                        </Route>
                        <Route exact path={`${url}/main`}>
                            <DashboardMain/>
                        </Route>

                        <Route exact path={`${url}/mycompany`}>
                            <MyCompany/>
                        </Route>

                        <Route exact path={`${url}/myprofil`}>
                            <MyProfilComponent/>
                        </Route>
                        <Route exact path={`${url}/search`}>
                            <SearchList/>
                        </Route>
                        <Route exact path={`${url}/matchs`}>
                            <MatchsList/>
                        </Route>
                        <Route exact path={`${url}/matchs/:id`}>
                            <MatchDetail/>
                        </Route>
                    </Switch>
                </div>

        </div>
    )
};

export default Dashboard;
