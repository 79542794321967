import request from '../shared/lib/AxiosWrap';
import {getAuthToken} from "../shared/function/generic";

async function getAdminCompany() {
    return request({
        url: `/v1/admin/company`,
        method: 'GET',
        headers: {'Authorization': getAuthToken()}
    });
}

async function getAdminCompanyById(id) {
    return request({
        url: `/v1/admin/company/${id}`,
        method: 'GET',
        headers: {'Authorization': getAuthToken()}
    });
}

async function updateAdminCompany(id, company) {
    return request({
        url: `/v1/admin/company/${id}`,
        method: 'POST',
        data : company,
        headers: {'Authorization': getAuthToken()}
    });
}


async function deleteAdminCompany(id) {
    return request({
        url: `/v1/admin/company/${id}`,
        method: 'DELETE',
        data : JSON.stringify({}),
        headers: {'Authorization': getAuthToken()}
    });
}


async function getAdminCompanyJobsById(id, page) {
    return request({
        url: `/v1/admin/company/${id}/jobs?page=${page}&nbrByPage=10`,
        method: 'GET',
        headers: {'Authorization': getAuthToken()}
    });
}


async function getJobById(id) {
    return request({
        url: `/v1/admin/jobs/${id}`,
        method: 'GET',
        headers: {'Authorization': getAuthToken()}
    });
}



async function deleteJob(id) {
    return request({
        url : `/v1/admin/jobs/${id}`,
        method : 'DELETE',
        headers: {'Authorization': getAuthToken()}
    })
}

async function updateJobById(id, job){
    return request({
        url: `/v1/admin/jobs/${id}`,
        method: 'POST',
        data : job,
        headers: {'Authorization': getAuthToken()}
    });
}

async function getJobMatch(id){
    return request({
        url: `/v1/admin/jobs/${id}/matchs`,
        method: 'GET',
        headers: {'Authorization': getAuthToken()}
    });
}



async function getData(){
    return request({
        url: `/v1/admin/data`,
        method: 'GET',
        headers: {'Authorization': getAuthToken()}
    });
}

async function getNews(limit) {
    return request({
        url: `/v1/admin/news?limit=${limit}`,
        method: 'GET',
        headers: {'Authorization': getAuthToken()}
    })
}


const adminServices = {
    getAdminCompany,
    updateAdminCompany,
    getAdminCompanyById,
    getAdminCompanyJobsById,
    getJobById,
    deleteJob,
    updateJobById,
    getJobMatch,
    deleteAdminCompany,
    getData,
    getNews
};

export default adminServices;
