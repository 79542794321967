
import {getAuthToken} from "../shared/function/generic";
import request from "../shared/lib/AxiosWrap";



async function getAll() {
    return  request({
        url: `/v1/config/all`,
        method: 'GET',
        headers: {
            'Authorization': getAuthToken(),
            'Content-Type': 'multipart/form-data'
        }
    })
}

async function save(config) {
    console.log("REQUEST")
    console.log(config)
    return  request({
        url: `/v1/config/`,
        method: 'POST',
        headers: {
            'Authorization': getAuthToken()
        },
        data : config
    })
}


async function getNotations() {
    return  request({
        url: `/v1/config/notation`,
        method: 'GET',
        headers: {
            'Authorization': getAuthToken(),
            'Content-Type': 'multipart/form-data'
        }
    })
}


async function getActivities() {
    return  request({
        url: `/v1/config/activities`,
        method: 'GET',
        headers: {
            'Authorization': getAuthToken(),
            'Content-Type': 'multipart/form-data'
        }
    })
}

async function getContractType() {
    return  request({
        url: `/v1/config/contractType`,
        method: 'GET',
        headers: {
            'Authorization': getAuthToken(),
            'Content-Type': 'multipart/form-data'
        }
    })
}

async function getLevelEducation() {
    return  request({
        url: `/v1/config/levelEducation`,
        method: 'GET',
        headers: {
            'Authorization': getAuthToken(),
            'Content-Type': 'multipart/form-data'
        }
    })
}
const configsServices = {
    getActivities,
    getContractType,
    getLevelEducation,
    getNotations,
    getAll,
    save
};

export default configsServices;
