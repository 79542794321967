
export default class WSManager {

    static _shared = null;


    messages = [];
    listener = []
    /**
     * @returns {CommonDataManager}
     */
    static shared() {
        if (WSManager._shared == null) {
            WSManager._shared = new WSManager();
        }

        return this._shared;
    }

    dispatchListener(json){

        console.log("Dispatch 1")
        if (json.type){
            console.log("Dispatch listener " + json.type)
            this.listener.forEach(elem => {
                if (elem.type === json.type){
                    try {
                        elem.func(json)
                    } catch (e){}
                }
            })
        }
    }
    addListener(type, name, func){
        console.log("Add listener " + type)
        this.listener.push({type : type, name: name, func : func})
    }

    removeListener(type, name){
        console.log("Remove listener " + type)
        this.listener = this.listener.filter((elem) => {
            return !(elem.type === type && elem.name === name)
        })
    }

    setSendMessage(value){
        this.sendMessage = value
        this.flushMessage()
    }


    flushMessage(){
        if (this.sendMessage != null) {
            this.messages.forEach((elem) => {
                this.sendMessage(JSON.stringify(elem))
            })
            this.messages = []
        }
    }

    actionSendMessage(message){
        console.log("[WS] SEND MESSAGE " + JSON.stringify(message))
        if (this.sendMessage !== null) {
            this.sendMessage(JSON.stringify(message))
        } else {
            this.messages.push(message)
        }
    }


}
