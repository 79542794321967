import React, {useState, useEffect} from 'react';
import {useHistory, useParams} from 'react-router-dom';
import DatePicker from "react-datepicker";
import 'react-confirm-alert/src/react-confirm-alert.css';
import "react-datepicker/dist/react-datepicker.css";
import {toastError} from "../../shared/function/generic";
import SquareButton from "../commons/SquareButton";
import SquareInput from "../commons/SquareInput";
import Autocomplete from "react-google-autocomplete";
import configsServices from "../../services/config";
import jobsServices from "../../services/jobs";
import {confirmAlert} from 'react-confirm-alert';
import utilsServices from "../../services/utils";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEye} from "@fortawesome/free-regular-svg-icons";
import {faCross, faTimesCircle, faWindowClose} from "@fortawesome/free-solid-svg-icons";

const PromotionCell = ({elem, index,  setFileDetail, onDelete}) => {

    const [isHover, setIsHover] = useState(false)
    var imageUrl = elem.url
    if (elem.type === "video"){
        var url = elem.url.split("v=")[1]
        imageUrl = "https://img.youtube.com/vi/"+url+"/0.jpg"
    }
    return (
        <div className={"col-span-1 cursor-pointer relative"}  key={"file"+index} >
            <button
                onMouseEnter={() => setIsHover(true)}
                onMouseLeave={() => setIsHover(false)}
                className={"absolute right-0 top-1"}
                onClick={() => {
                    onDelete()
                }
                }>
                <FontAwesomeIcon style={{marginRight: 5, color : isHover ? "#d79cc3" : "#BB2687", fontSize : 22}} icon={faTimesCircle}/>
            </button>
            <div onClick={() => {
                setFileDetail(elem)
            }}>
            <img className={"w-full"} style={{objectFit: "contain"}} src={imageUrl}/>
            </div>
        </div>
    )
};

export default PromotionCell;
