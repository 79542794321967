export default class User {
  firstName;
  lastName;
  email;
  fonction;
  company;
  _company;
  token;
  admin;

  _id;
  constructor(json) {
    this.firstName = json.firstName;
    this.admin = json.admin || false;
    this._id = json._id;
    this.fonction = json.fonction;
    this.lastName = json.lastName;
    this.token = json.token;
    this.email = json.email;
    this._company = json._company || null;
    this.company = json.company;
  }

  getNextViewLogin() {
    if (this.admin) {
      return '/admin';
    } else if (this._company == null) {
      return '/auth/selectcompany';
    } else {
      return '/dashboard';
    }
  }
}
