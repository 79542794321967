/**
 * Axios Request Wrapper
 * ---------------------
 *
 * @author  Sheharyar Naseer (@sheharyarn)
 * @license MIT
 *
 */

import axios from 'axios';
import {API_HOST} from '../../config';

/**
 * Create an Axios Client with defaults
 */

const client = axios.create({
    baseURL: API_HOST
});

const basic = axios.create({
    baseURL: ''
});

const request = function (options, special = false) {
    const onSuccess = function (response) {
        return response.data;
    };

    const onError = function (error) {
        console.debug('Request Failed:', error.config);
        if (error.response) {
            if (error.response.status === 401) {
                // Unauthorized case, remove token & redirect to login
            }
            console.debug('Status:', error.response.status);
            console.debug('Data:', error.response.data);
            console.debug('Headers:', error.response.headers);
        } else {
            console.debug('Error Message:', error.message);
        }
        return Promise.reject(error.response || error.message);
    };

    if (special === false) {
        return client(options)
            .then(onSuccess)
            .catch(onError);
    } else {
        return basic(options)
            .then(onSuccess)
            .catch(onError);
    }
};

export default request;
