import React, {useEffect, useState} from 'react';
import Loader from 'react-loader-spinner';

const LoaderComponent = ({fetch, dependency, children}) => {
    const [loader, setLoader] = useState(false);
    const [oldDependency, setOldDependency] = useState(null)
    useEffect(() => {

        async function fetchData() {
            await fetch();
            setLoader(true);
        }

        if (oldDependency !== dependency) {

            setOldDependency(dependency)
            fetchData();
        }


    }, [dependency, fetch, oldDependency]);


    return (
        <div>
            {loader === false ? <div style={{
             width : 80,
                height : 80,
                margin: "auto",
                position : "absolute",
                left : "50%",
                top : "50%",
                marginTop : -40,
                marginLeft : -40
            }}><Loader styles={"m-auto"} type="ThreeDots" color="#BB2687" height={80} width={80}/></div> : children}
        </div>
    );
};

export default LoaderComponent;
