import React, {useEffect} from "react";
import jobsServices from "../../services/jobs";
import {useHistory, useParams} from "react-router-dom";
import {toast} from "react-toastify";

export function JobActiveRedirect({isActive}) {
  const {id} = useParams();
  const history = useHistory();

  useEffect(() => {
    redirect();
  }, [])

  const redirect = async () => {
    if (!id) return history.push('/');

    if (isActive) {
      await jobsServices.jobActive(id);
      history.push('/');
      toast.success("Offre mise à jour !", {autoClose: 2000})
    } else {
      await jobsServices.jobInactive(id);
      history.push('/');
      toast.success("Offre mise à jour !", {autoClose: 2000})
    }
  }

  return <></>
}
