import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faEye, faThumbsDown, faThumbsUp} from "@fortawesome/free-regular-svg-icons";

const TitleMatchFormatter = (data) =>{

    var type = ""
    var title =  ""
    if (data.row._job){
        type = "Offre"
        title = data.row._job.name
    } else if (data.row._company){
        type = "Entreprise"
        title = data.row._company.denomination
    }
    return (
        <div>
            <p>{type} : {title}</p>
        </div>
    )
}

export default TitleMatchFormatter
