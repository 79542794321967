import React, {useState, useEffect} from 'react';
import 'react-confirm-alert/src/react-confirm-alert.css';
import "react-datepicker/dist/react-datepicker.css";
import SquareButton from "../commons/SquareButton";
import configsServices from "../../services/config";
import Modal from 'react-modal';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faStar} from "@fortawesome/free-regular-svg-icons/faStar";
import SpanSelected from "./SpanSelected";
import {scoreCompany} from "../../shared/function/scoreCompany";


const customStyles = {
    content: {
        width : 900,
        maxHeight : "98%",
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)'
    },
    stepDiv : {
        paddingTop : 20,
        paddingBottom : 20,
        borderWidth : 1,
        borderColor : "red"
    },
    stepTitle : {
        fontWeight : "bold"
    }
};

const ScoreCompany = ({company, isOpen, close}) => {

    //const [companyObj, setCompany] = useState(company)
    //const [score, setScore] = useState(0)
    const [steps, setSteps] = useState([])

    const [currentStep, setCurrentStep] = useState(null)

    const [form, setForm] = useState({

    })

    const reloadSteps = async () => {
        const notations = await configsServices.getNotations()
        setSteps(notations)
    }

    useEffect(() => {
        reloadSteps()

    }, [])


  /*  const reloadScore = async () => {
        var score = await scoreCompany(company)
        setScore(score)
    }
    useEffect(() => {
        reloadScore()

    }, [form])*/


    useEffect(() => {
        //setCompany(company)
        setForm({...form, ...company.score})
    }, [company])

    const save = () => {
        close(form)
    }

    const getScoreInt = (step) => {
        var total = 0
        var current = 0


        step.form.forEach((elem) => {
            if (elem.form){
                var result = getScoreInt(elem)
                total += result.total
                current += result.current
            } else {
                total += elem.score
                if (elem.idCompany){
                    current += (company[elem.idCompany] || 0)
                } else {
                    current += (form[elem.id] ? elem.score : 0)
                }
            }
        })

        return {total : total, current : current}
    }

    const getScore = (step) => {
         var result = getScoreInt(step)
        return result.current + "/" + result.total
    }

    const getTotalScore = () => {
        var total = 0
        steps.forEach((elem) => {
            total += getScoreInt(elem).current
        })
        return parseFloat((total / 20).toFixed(2))
    }
    const generateStep = (elem, index, subtitle = false, subsubtile = false) => {



        if (elem.form){
            var className = "font-bold text-2xl mb-2"
            if (subtitle){
                className = "font-bold text-xl mb-2"
            }
            var add = []//<p className={className} key={elem.title}>{elem.title} {!subtitle ? getScore(elem) : ""}</p>]
            if (elem.titleShow){
                add.push(<p className={className } key={elem.title}>{elem.title} {!subtitle ? getScore(elem) : ""}</p>)
            }
            var result = elem.form.map((elem, index) => {
                return generateStep(elem, index, false, subtitle === false)
            })
            add.push(result)//
            if (elem.titleShow){
                add.push(<p style={{height : 25}}></p>)
            }
            return add
        } else {
            if (elem.idCompany) {
                return  [<div className={"border-b pb-4 pt-2 border-gray-300 flex flex-row mb-2 items-center " + (subsubtile ? "" : "")}>
                    <p className={"flex-grow mr-4"}>{elem.title}</p>
                    <p>{company[elem.idCompany]}/{elem.score}</p>
                </div>]
            }
            return [
                <div className={"border-b border-gray-300  pb-2  flex flex-row mb-2 items-center " + (subsubtile ? "" : "")}>
                    <p className={"flex-grow mr-4"}>{elem.title}</p>
                    <SpanSelected  form={form} name={elem.id} setForm={setForm}></SpanSelected>
                </div>]
        }




    }


    return (

        <Modal isOpen={isOpen}
               style={customStyles}
        >

            <div className={"bg-white h-full relative bg-opacity-90"}>

                    <p className={"text-center font-bold mb-4 text-xl"}>Formulaire de notation {currentStep != null ? "(" + (currentStep + 1) + "/" + steps.length + ")" : ""}</p>


                {
                    currentStep == null &&
                    <div>
                        {steps.map((elem, index) => {
                            return (

                                generateStep(steps[index], 0)

                            )
                        })}

                            <div className={"w-full text-center mb-4"}>
                                <p className={"text-black font-bold"}>Votre note :</p>
                                <p className={"text-3xl text-black font-bold"}>{getTotalScore()}/5 <FontAwesomeIcon style={{marginRight : 5}} icon={faStar} /></p>
                            </div>

                        <div className={"flex justify-end"}>
                            <SquareButton onClick={() => {
                                save()
                            }} text={"Sauvegarder " } className={"text-white main-bg-1 bg-opacity-20"}/>
                        </div>

                    </div>
                }

                { currentStep !== null &&
                <div >
                    <div className={"grid grid-cols-2 mb-4"}>
                        <div>
                            <button className={`transition-2 hover:opacity-75 px-2 rounded py-2 font-bold color-button-violet`}
                                    onClick={() => {
                                        setCurrentStep(null)
                                    }}>
                                Retour
                            </button>
                        </div>
                        <div className={"text-right"}>
                            {currentStep > 0 &&
                            <button
                                className={`transition-2 hover:opacity-75 mr-2 px-2 rounded py-2 font-bold color-button-violet`}
                                onClick={() => {
                                    setCurrentStep(currentStep - 1)
                                }}>
                                Precedent
                            </button>
                            }


                            <button
                                className={`transition-2 hover:opacity-75 px-2 rounded py-2 font-bold color-button-violet`}
                                onClick={() => {
                                    if (currentStep < steps.length - 1) {
                                        setCurrentStep(currentStep + 1)
                                    }  else {
                                        setCurrentStep(null)
                                    }
                                }}>
                                {currentStep < steps.length - 1 ? "Suivant" : "Terminer"}

                            </button>

                        </div>
                    </div>
                    {


                        generateStep(steps[currentStep], 0)

                    }


                    <div className={"grid grid-cols-1 mb-4 mt-10"}>

                        <div className={"text-right"}>
                            {currentStep > 0 &&
                            <button
                                className={`transition-2 hover:opacity-75 mr-2 px-2 rounded py-2 font-bold color-button-violet`}
                                onClick={() => {
                                    setCurrentStep(currentStep - 1)
                                }}>
                                Precedent
                            </button>
                            }


                            <button
                                className={`transition-2 hover:opacity-75 px-2 rounded py-2 font-bold color-button-violet`}
                                onClick={() => {
                                    if (currentStep < steps.length - 1) {
                                        setCurrentStep(currentStep + 1)
                                    }  else {
                                        setCurrentStep(null)
                                    }
                                }}>
                                {currentStep < steps.length - 1 ? "Suivant" : "Terminer"}

                            </button>

                        </div>
                    </div>
                </div>
                }

            </div>
        </Modal>

    );
};

export default ScoreCompany;
