import React, {useState, useContext, useEffect} from 'react';
import SquareButton from "../../commons/SquareButton";
import configsServices from "../../../services/config";
import ConfigOneElem from "./ConfigOneElem";


const AdministrationConfig = () => {

    const [config, setConfig] = useState(null)

    const reloadData = async () => {
        const configAll = await configsServices.getAll()
        setConfig({
            ...configAll
        })
    }

    useEffect(() => {
        reloadData()
    }, [])

    const save= async () => {
        await configsServices.save(config)
        reloadData()
    }
    return (
        <div className={"w-3/4 m-auto py-10"}>

            <SquareButton onClick={() => {
                save()

            }} text={"Sauvegarder"} className={"color-button-violet mb-4"} style={{width  :"100%"}}/>
            <div className={"grid grid-cols-2 gap-4 "}>
                <ConfigOneElem configTemplate={config} name={"contractType"} title={"Type de contrats"} onUpdate={(name, items) => setConfig({...config, [name] : items})}/>
                <ConfigOneElem configTemplate={config} name={"levelEducation"} title={"Niveau d'étude"} onUpdate={(name, items) => setConfig({...config, [name] : items})}/>
                <ConfigOneElem configTemplate={config} name={"language"} title={"Langues"} onUpdate={(name, items) => setConfig({...config, [name] : items})}/>
                <ConfigOneElem configTemplate={config} name={"activities"} title={"Secteur d'activité"} onUpdate={(name, items) => setConfig({...config, [name] : items})}/>
                <ConfigOneElem className={"col-span-2"} configTemplate={config} name={"training"} title={"Formations"} onUpdate={(name, items) => setConfig({...config, [name] : items})}/>
            </div>

        </div>
    );
};

export default AdministrationConfig;
