import React, {useState, useEffect} from 'react';
import {useHistory, useParams} from 'react-router-dom';
import DatePicker from "react-datepicker";
import 'react-confirm-alert/src/react-confirm-alert.css';
import "react-datepicker/dist/react-datepicker.css";
import Modal from 'react-modal';
import SquareButton from "./SquareButton";
import SquareInput from "./SquareInput";
import utilsServices from "../../services/utils";
import {toastError} from "../../shared/function/generic";
import ReactPlayer from "react-player"
import {confirmAlert} from "react-confirm-alert";

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)'
    }
};

const DetailFileModal = ({file, isOpen, close}) => {




    return (

        <Modal isOpen={isOpen}
               style={customStyles}
               shouldCloseOnOverlayClick={true}
               onRequestClose={() => {
                   close(null)
               }}>



            {file && file.type === "video" &&
            <div>
                <ReactPlayer
                    url={file.url}
                />
            </div>
            }
            {file && file.type === "photo" &&
            <div>
                 <img alt={"logo"} src={file.url} style={{ objectFit : "contain", maxHeight : 700}}/>
            </div>
            }
            <SquareButton onClick={() => {
                confirmAlert({
                    title: "Supression",
                    message: "Voulez-vous supprimer ce média ?",
                    buttons: [
                        {
                            label: "Non",
                            onClick: () => { }
                        },
                        {
                            label: "Oui",
                            onClick: () => {
                                close(true, file)
                            }
                        }
                    ]
                })


            }} text={"Supprimer"} className={"color-button-violet mb-3 mt-3"}/>

        </Modal>

    );
};

export default DetailFileModal;
