import React, { useContext, useEffect, useState } from "react";

export const DeleteAccount = () => {
    return (
        <div className={"rounded-xl p-1 md:w-3/4 lg:w-2/4"} style={{ margin: "auto" }}>
            <div class="max-w-md mx-auto bg-white p-8 border rounded shadow-md">
                <h1 class="text-2xl font-semibold mb-4">Politique de confidentialité et suppression de compte</h1>

                <p class="mb-4">
                    Bienvenue sur l'application <strong>CTM Jobs</strong>, développée par <strong>Usekey</strong>.
                </p>

                <h2 class="text-lg font-semibold mb-2">Suppression de compte</h2>
                <p class="mb-4">Pour demander la suppression de votre compte, veuillez suivre les étapes ci-dessous :</p>
                <ol class="list-decimal pl-6">
                    <li>Connectez-vous à votre compte.</li>
                    <li>Accédez aux paramètres du compte.</li>
                    <li>Recherchez l'option de suppression de compte.</li>
                    <li>Suivez les instructions pour confirmer la suppression.</li>
                </ol>

                <h2 class="text-lg font-semibold mb-2 mt-4">Données</h2>
                <p class="mb-4">Nous supprimons les types de données suivants lors de la suppression de votre compte :</p>
                <ul class="list-disc pl-6">
                    <li>Informations de profil</li>
                    <li>Historique d'utilisation</li>
                </ul>

                <h2 class="text-lg font-semibold mb-2 mt-4">Durée de conservation supplémentaire</h2>
                <p class="mb-4">Aucune donnée n'est conservée au-delà de la suppression du compte.</p>
            </div>
        </div>
    );
};
