import React, {useState, useEffect} from 'react';
import 'react-confirm-alert/src/react-confirm-alert.css';
import "react-datepicker/dist/react-datepicker.css";
import NavItem from "../sections/NavItem";

const UserExperience = ({experience}) => {

    return (
        <div className={"border p-2 mt-2 rounded-md"}>
            <p><span className={"font-bold"}>{experience.title} - {experience.company}</span> <span className={"italic"}>{experience.contractType}</span></p>
            <p className={"text-sm mb-2"}>{experience.startDate} - {experience.endDate}</p>

            <p>{experience.description}</p>
        </div>
    );
};

export default UserExperience;
