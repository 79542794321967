import React, {useState, useEffect} from 'react';
import 'react-confirm-alert/src/react-confirm-alert.css';
import "react-datepicker/dist/react-datepicker.css";
import NavItem from "../sections/NavItem";
import UserExperience from "./UserExperience";

const UserTile = ({user}) => {

    return (
        <div className={""}>

            <div className="border-2 border-1-2 bg-white shadow overflow-hidden sm:rounded-lg w-full p-4">

                <div className={"w-24 h-24 object-cover rounded-full overflow-hidden relative"}>
                    <img className={""} alt="Scankey"
                         src={process.env.PUBLIC_URL + '/assets/default_user.png'}/>
                    <img style={{
                        position : "absolute",
                        top : 0,
                        width : "100%",
                        objectFit : "inherit",
                        height : "100%"
                    }} src={user.picture}/>
                </div>
                <p className={"mb-2 text-xl"}>{user.firstName} {user.lastName}</p>

                <p className={"mb-2"}><span className={"font-bold"}>Mobile :</span> {user.isMobile ? "Oui" : "Non"}</p>
                <p className={"mb-2"}><span className={"font-bold"}>Formation :</span> {user.training}</p>
                <p className={"mb-2"}><span className={"font-bold"}>Type de contrat recherché : </span>
                    {user.contractType.map((elem, index) => {
                        return (<span key={"contracttype"+index} >{elem}</span>)
                    })}
                </p>
                <p className={"mb-2"}><span className={"font-bold"}>Niveau d'étude :</span> {user.levelEducation}</p>
                <p className={"mb-2"}><span className={"font-bold"}>Langues :</span>
                    {user.language.map((elem, index) => {
                        return <p  key={"language"+index} >- {elem}</p>
                    })}
                </p>
                <p><span className={"font-bold"}>Description :</span></p>
                <p className={"mb-2"}>{user.description}</p>
                <p  className={"mb-1"}><span className={"font-bold"}>Mot clé pour se définir :</span></p>
                <div className={"mb-4 flex flex-wrap gap-2 "}>
                {user.tags.map((elem, index) => {
                    return (<span  key={"tag"+index} className={"text-white px-2 py-1 rounded-full  main-bg-1"}>{elem}</span>)
                })}
                </div>
                <p><span className={"font-bold"}>Experiences :</span></p>
                {user.experiences.map((elem, index) =>{
                    return (<UserExperience key={"userexperience"+index} experience={elem}/>)
                })}
            </div>



        </div>
    );
};

export default UserTile;
