import React, {useState, useEffect} from 'react';
import 'react-confirm-alert/src/react-confirm-alert.css';
import "react-datepicker/dist/react-datepicker.css";
import NavItem from "../sections/NavItem";
import {decl} from "postcss";
import roomServices from "../../services/room";

const MatchValidate = ({room, onUpdate}) => {

    var type = <p></p>
    if (room._job){
        type = <span>votre offre d'emploi :  <span className={"font-bold"}>{room._job.name}</span></span>
    } else if (room._company){
        type = <span><span className={"font-bold"}>votre entreprise</span></span>
    }

    const accept = async () => {
        await roomServices.acceptById(room._id)
        onUpdate()
    }

    const decline = async () => {
        await roomServices.declineById(room._id)
        onUpdate()
    }

    return (
        <div className="border-2 border-1-2 mb-4 bg-white shadow overflow-hidden sm:rounded-lg w-full p-4">
            <p className={""}>Vous avez matché à propos de {type}</p>
        </div>
    );
};

export default MatchValidate;
