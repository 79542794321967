import React from 'react';

const SquareInput = ({className,parentClassName, name, state, setValues, value, placeholder, type, step, checking, disable, isInt, isFloat, onCustomClick = null, tint, autoComplete}) => {
    const handleInputChange = e => {

        var {name, value} = e.target;
        if (isInt === true) {
            value = parseInt(value) || 0
        } else if (isFloat === true) {
            value = parseFloat(value) || 0
        }
        var valueChecking = check(value)
        name ? setValues({...state, [name]: valueChecking}) : setValues(value);
    };

    const check = e => {
        var result = e
        if (checking) {

        }
        return result
    }
    return (
        <div className={parentClassName + " mt-1 flex " }>
            <input
                onClick={() => {
                    if (onCustomClick) {
                        onCustomClick()
                    }
                }}
                autoComplete={autoComplete}
                disabled={disable === true}
                name={name} onChange={handleInputChange} value={value} type={type}

                step={step}
                className={`transition-2 block bg-gray-50 outline-none w-full rounded border p-2 py-1 border-gray-300 ${className} ${tint ? "border-r-0 rounded-r-none" : ""}`}
                placeholder={placeholder}/>

            {tint &&
            <span
                className="h-full inline-flex  py-1 items-center px-3 border cursor-pointer border-l-0 border-r-0 border-gray-300 bg-gray-200   main-bg-1 text-white  font-bold text-center border-r rounded-r-lg">
                {tint}
            </span>
            }
        </div>
    );
};

export default SquareInput;
