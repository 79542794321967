import React, {useState, useEffect} from 'react';
import 'react-confirm-alert/src/react-confirm-alert.css';
import "react-datepicker/dist/react-datepicker.css";
import NavItem from "../sections/NavItem";
import {decl} from "postcss";
import roomServices from "../../services/room";

const MatchWaitingMe = ({room, onUpdate, onLoading}) => {

    var type = <p></p>
    if (room._job){
        type = <span>votre offre d'emploi :  <span className={"font-bold"}>{room._job.name}</span></span>
    } else if (room._company){
        type = <span><span className={"font-bold"}>votre entreprise</span></span>
    }

    const accept = async () => {
        onLoading(true)
        try {
            await roomServices.acceptById(room._id)
            onUpdate()
        } catch (e){
            onLoading(false)
        }

    }

    const decline = async () => {
        onLoading(true)
        try {
            await roomServices.declineById(room._id)
            onUpdate()
        } catch (e){
            onLoading(false)
        }

    }

    return (
        <div className="border-2 border-1-2  bg-white shadow overflow-hidden sm:rounded-lg w-full p-4">
            <p className={"mb-8"}>Le candidat à matché avec {type}</p>
            <p className={"mb-2"}>Si son profil vous intéresse, cliquez sur <span className={"font-bold"}>"je suis intéressé(e)"</span> pour lui signaler votre intéret et rentrer en discussion avec lui".</p>
            <p className={"mb-6"}>Le profil ne vous correspond pas ? Cliquez sur <span className={"font-bold"}>"je ne suis pas intéressé(e)"</span></p>
            <button className={`transition-2 hover:opacity-75 px-2 rounded py-2 font-bold border-1-2 border-2 mb-4 md:mb-0 w-full md:w-max`}
                    onClick={() => {
                        decline()
                    }}>
                Je ne suis pas interessé(e)
            </button>
            <button className={`transition-2 hover:opacity-75 px-2 rounded py-2 font-bold color-button-violet md:ml-4  w-full md:w-max`}
                    onClick={() => {
                        accept()
                    }}>
                Je suis interessé(e)
            </button>
            <p className={"mt-4"}>Le candidat recevra votre réponse directement après votre choix.</p>
        </div>
    );
};

export default MatchWaitingMe;
