import firebase from "firebase";
import i18next from "i18next";
import detector from "i18next-browser-languagedetector";
import React, { useContext, useEffect, useState } from "react";
import { I18nextProvider, initReactI18next, withTranslation } from "react-i18next";
import Modal from "react-modal";
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import useWebSocket, { ReadyState } from "react-use-websocket";
import AdministrationConfig from "./components/admin/config/AdministrationConfig";
import DashboardAdmin from "./components/admin/DashboardAdmin";
import { DeleteAccount } from "./components/deleteaccount/deleteaccount.view";
import Logout from "./components/authentification/Logout";
import Background from "./components/commons/Background";
import Footer from "./components/sections/Footer";
import { dev } from "./config/index";
import AdminRoute from "./routes/AdminRoute";
import PrivateRoute from "./routes/PrivateRoute";
import PublicRoute from "./routes/PublicRoute";
import { InfoProvider } from "./shared/context/InfoContext";
import UserContext, { UserProvider } from "./shared/context/UserContext";
import WSManager from "./shared/Singleton/WSManager";
import common_en from "./translations/en/common.json";
import common_fr from "./translations/fr/common.json";
import Authentication from "./views/Authentication";
import Dashboard from "./views/Dashboard";
import NotFound from "./views/NotFound";
import { JobActiveRedirect } from "./components/jobs/JobActiveRedirect";
import { PrivacyView } from "./components/privacy/privacy.view";
i18next
    .use(detector)
    .use(initReactI18next)
    .init({
        interpolation: { escapeValue: false },
        fallbackLng: "fr",
        lng: "fr",
        resources: {
            en: {
                common: common_en,
            },
            fr: {
                common: common_fr,
            },
        },
    });

const firebaseConfig = {
    apiKey: "AIzaSyC83tIlgMo2l9KIzxLfEb-BCW57am_bI3A",
    authDomain: "snct-a2a0f.firebaseapp.com",
    projectId: "snct-a2a0f",
    storageBucket: "snct-a2a0f.appspot.com",
    messagingSenderId: "590546791957",
    appId: "1:590546791957:web:97f8b39548099a5174281c",
    measurementId: "G-7DGT654ED6",
};

firebase.initializeApp(firebaseConfig);
Modal.setAppElement("body");

if (window.location.protocol == "http:" && !window.location.href.includes("localhost")) {
    console.log("you are accessing us via " + "an insecure protocol (HTTP). " + "Redirecting you to HTTPS.");
    window.location.href = window.location.href.replace("http:", "https:");
} else if (window.location.protocol == "https:") {
    console.log("you are accessing us via" + " our secure HTTPS protocol.");
}

function App() {
    const context = useContext(UserContext);
    const [user, setUser] = useState({
        user: {},
        update: () => {},
    });

    //const [socketUrl] = useState('ws://172.20.10.6:4012/');
    const [socketUrl] = useState(dev ? "ws://172.20.10.6:3000/" : "ws://ctm-jobs-server.herokuapp.com/");

    const { sendMessage, readyState } = useWebSocket(socketUrl, {
        onOpen: () => console.log("opened"),
        //Will attempt to reconnect on all close events, such as server shutting down
        shouldReconnect: (closeEvent) => true,
        onMessage: (msg) => {
            if (msg.data) {
                var json = JSON.parse(msg.data);
                if (json.type) {
                    switch (json.type) {
                        case "hello":
                            //UserManager.shared().wsUser()

                            if (user && user.user && user.user.company) {
                                WSManager.shared().actionSendMessage({
                                    type: "loginCompany",
                                    id: user.user.company,
                                });
                            }
                            break;
                        default:
                            WSManager.shared().dispatchListener(json);
                            console.log("Type not found " + json.type);
                    }
                }
            }
        },
    });

    useEffect(() => {
        if (user && user.user && user.user.company) {
            WSManager.shared().actionSendMessage({
                type: "loginCompany",
                id: user.user.company,
            });
        }
    }, [user]);

    WSManager.shared().setSendMessage(sendMessage);

    const connectionStatus = {
        [ReadyState.CONNECTING]: "Connecting",
        [ReadyState.OPEN]: "Open",
        [ReadyState.CLOSING]: "Closing",
        [ReadyState.CLOSED]: "Closed",
        [ReadyState.UNINSTANTIATED]: "Uninstantiated",
    }[readyState];

    return (
        <I18nextProvider i18n={i18next}>
            <UserProvider
                value={{
                    data: user,
                    update: (newUser) => setUser(newUser),
                }}
            >
                <InfoProvider>
                    <BrowserRouter>
                        <ToastContainer />
                        <Switch>
                            <Route exact path="/">
                                <Redirect to="/auth/login" />
                            </Route>
                            <PrivateRoute exact path="/auth/selectCompany" component={Authentication} />
                            <Route exact path="/logout" component={Logout} />
                            <PublicRoute path="/auth" component={Authentication} />
                            <PublicRoute path="/config" component={AdministrationConfig} />

                            <PublicRoute exact path="/jobs/:id/active">
                                <JobActiveRedirect isActive={true} />
                            </PublicRoute>
                            <PublicRoute exact path="/deleteaccount">
                                <DeleteAccount />
                            </PublicRoute>
                            <PublicRoute exact path="/privacy">
                                <PrivacyView />
                            </PublicRoute>
                            <PublicRoute exact path="/jobs/:id/inactive">
                                <JobActiveRedirect isActive={false} />
                            </PublicRoute>

                            <Route exact path="/dashboard">
                                <Redirect to="/dashboard/main" />
                            </Route>

                            <PrivateRoute path="/dashboard" component={Dashboard} />

                            <Route exact path="/admin">
                                <Redirect to="/admin/config" />
                            </Route>
                            <AdminRoute path="/admin" component={DashboardAdmin} />

                            <Route path="*" component={NotFound} />
                        </Switch>
                        <Footer />
                    </BrowserRouter>
                </InfoProvider>
            </UserProvider>
            <Background></Background>
        </I18nextProvider>
    );
}

export default withTranslation("common")(App);
