import Cookies from 'js-cookie';
import React, { useContext, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import accountServices from '../../services/account';
import User from '../../shared/class/User';
import { InfoContext } from '../../shared/context/InfoContext';
import UserContext from '../../shared/context/UserContext';
import { toastError } from '../../shared/function/generic';
import SquareButton from '../commons/SquareButton';
import SquareInput from '../commons/SquareInput';

const Register = () => {
  const [form, setForm] = useState({ email: '', password: '', firstName: '', lastName: '', fonction: '' });
  const [pwdType, setPwdType] = useState('password');
  const history = useHistory();
  const { user, setUser } = useContext(InfoContext);
  const context = useContext(UserContext);

  const register = async () => {
    if (!form.password || !form.email || !form.firstName || !form.lastName) {
      return toastError('Merci de remplir le formulaire');
    }
    try {
      const register = await accountServices.register(form.email, form.password, form.firstName, form.lastName, form.fonction);
      const user = new User(register);
      context.update(user);
      setUser(user);
      Cookies.set('hiofukt', user.token);
      Cookies.set('hiofuktperm', '1');
      history.push(user.getNextViewLogin());
    } catch (e) {
      toastError(e.data);
    }
  };

  return (
    <div style={{ width: 350, margin: 'auto' }} className={'bg-white p-4 rounded-lg bg-opacity-90'}>
      <p className={'text-center mb-4'}>
        {'Vous avez déjà un compte ?'}
        <Link className={'font-bold  hover:text-blue-700 cursor-pointer transition-2 color-1-1'} to="/auth/login">
          &nbsp;{'Se connecter'}
        </Link>
      </p>
      <form
        onKeyPress={(e) => {
          e.key === 'Enter' && register() && e.preventDefault();
        }}
        className={'mb-4'}
      >
        <label className={'block font-semibold'}>{'Nom'}</label>
        <SquareInput
          type={'text'}
          className={'focus:border-blue-300 focus:border-2 mb-5'}
          name={'lastName'}
          setValues={setForm}
          autoComplete={'family-name'}
          state={form}
          value={form.lastName}
          placeholder={''}
        />

        <label className={'block font-semibold'}>{'Prénom'}</label>
        <SquareInput
          type={'text'}
          className={'focus:border-blue-300 focus:border-2 mb-5'}
          name={'firstName'}
          setValues={setForm}
          autoComplete={'given-name'}
          state={form}
          value={form.firstName}
          placeholder={''}
        />
        <label className={'block font-semibold'}>{'Email'}</label>
        <SquareInput
          type={'text'}
          className={'focus:border-blue-300 focus:border-2 mb-5'}
          name={'email'}
          setValues={setForm}
          autoComplete={'email'}
          state={form}
          value={form.email}
          placeholder={''}
        />
        <div className={'w-full '}>
          <label className={'inline-block font-semibold'}>{'Mot de passe'}</label>
          <p
            onClick={() => {
              pwdType === 'text' ? setPwdType('password') : setPwdType('text');
            }}
            className={'inline-block float-right link cursor-pointer transition-2 font-bold text-sm color-1-1'}
          >
            {pwdType === 'text' ? 'Cacher' : 'Voir'}
            {' le mot de passe'}
          </p>
        </div>
        <SquareInput
          type={pwdType}
          className={'focus:border-blue-300 focus:border-2 mb-5'}
          name={'password'}
          setValues={setForm}
          autoComplete={'new-password'}
          state={form}
          value={form.password}
          placeholder={''}
        />
        <label className={'block font-semibold'}>{'Fonction'}</label>
        <SquareInput
          type={'text'}
          className={'focus:border-blue-300 focus:border-2 '}
          name={'fonction'}
          setValues={setForm}
          state={form}
          value={form.fonction}
          placeholder={''}
        />
      </form>
      <SquareButton onClick={() => register()} text={"S'inscrire"} className={'color-button-violet'} />
    </div>
  );
};

export default Register;
