import React, { useEffect } from "react";
import { Doughnut, Pie } from "react-chartjs-2";

import { ArcElement, Chart as ChartJS, Legend, Tooltip } from 'chart.js';
import { useState } from "react/cjs/react.development";
import adminServices from "../../services/admin";
ChartJS.register(ArcElement, Tooltip, Legend);

const DataInfo = ({ label, value }) => {
  return (
    <div className="border-2 rounded p-2">
      <p>{label}</p>
      <p className="text-3xl font-bold">{value}</p>
    </div>
  )
}
const DataAdmin = () => {
  const [data, setData] = useState({})
  const reloadData = async () => {
    const arr = await adminServices.getData()
    setData(arr)
  }
  useEffect(() => {
    reloadData()
  }, [])


  const dataSwipeUser = {
    labels: ['Negative', 'Positive'],
    datasets: [
      {
        label: 'Swipe d\'utilisateur pour une entreprise',
        data: [
          data.usersToCompanyResultsStateNeg,
          data.usersToCompanyResultsStatePositif
        ],
        backgroundColor: [
          'rgba(255, 0, 0, 0.5)',
          'rgba(0, 255, 0, 0.5)',
        ],
        borderColor: [
          'rgba(255, 0, 0, 1)',
          'rgba(0, 255, 0, 1)',
        ],
        borderWidth: 1,
      },
    ],
  };

  const dataSwipeUserToJobs = {
    labels: ['Negative', 'Positive'],
    datasets: [
      {
        label: 'Swipe d\'utilisateur pour une entreprise',
        data: [
          data.usersCompanyJobsResultsStateNeg,
          data.usersCompanyJobsResultsStatePositif
        ],
        backgroundColor: [
          'rgba(255, 0, 0, 0.5)',
          'rgba(0, 255, 0, 0.5)',
        ],
        borderColor: [
          'rgba(255, 0, 0, 1)',
          'rgba(0, 255, 0, 1)',
        ],
        borderWidth: 1,
      },
    ],
  };

  const dataSwipeCompanyToUser = {
    labels: ['Negative', 'Positive'],
    datasets: [
      {
        label: 'Swipe d\'utilisateur pour une entreprise',
        data: [
          data.companyToUserSwipeZero,
          data.companyToUserSwipePositif
        ],
        backgroundColor: [
          'rgba(255, 0, 0, 0.5)',
          'rgba(0, 255, 0, 0.5)',
        ],
        borderColor: [
          'rgba(255, 0, 0, 1)',
          'rgba(0, 255, 0, 1)',
        ],
        borderWidth: 1,
      },
    ],
  };


  const dataRoom = {
    labels: ['En attente de l\'utilisateur', 'En attente de l\'entreprise',
      'Valdié par l\'utilisateur',
      'Validé par l\'entreprise',
      'Refusé par l\'utilisateur',
      'Refusé par l\'entreprise'],
    datasets: [
      {
        label: 'Swipe d\'utilisateur pour une entreprise',
        data: [
          data.roomsWaitFromCompany,
          data.roomsWaitFromUser,
          data.roomsValidateFromCompany,
          data.roomsValidateFromUser,
          data.roomsNotValidateFromCompany,
          data.roomsNotValidateFromUser

        ],
        backgroundColor: [

          'rgba(255, 153, 51, 0.75)',
          'rgba(204, 102, 0, 0.75)',
          'rgba(0, 255, 0, 0.75)',
          'rgba(0, 153, 0, 0.75)',
          'rgba(255, 0, 0, 0.75)',
          'rgba(153, 0, 0, 0.75)',
        ],
        borderColor: [
          'rgba(0, 26, 255, 1)',
          'rgba(222, 0, 255, 1)',
          'rgba(0, 26, 255, 1)',
          'rgba(222, 0, 255, 1)',
          'rgba(0, 26, 255, 1)',
          'rgba(222, 0, 255, 1)',
        ],
        borderWidth: 2,
      },
    ],
  };

  return (
    <div>

      <div className="grid grid-cols-3 gap-4 p-4 w-max lg:w-2/3 m-auto">
        <DataInfo label="Nombre d'entreprise" value={data.company} />
        <DataInfo label="Nombre de poste" value={data.jobs} />
        <DataInfo label="Nombre d'utilisateur" value={data.users} />
        <DataInfo label="Nombre de recruteur" value={data.recruiter} />
        <DataInfo label="Nombre de match" value={data.rooms} />
        <DataInfo label="Nombre de messages" value={data.messages} />
        <DataInfo label="Nombre de swipe Users -> Company" value={data.usersCompanyResults} />
        <DataInfo label="Nombre de swipe Users -> Jobs" value={data.usersJobResults} />

        <DataInfo label="Nombre de swipe Company -> Users" value={data.companyUserResult} />

        <div className="border-2 rounded p-2">
          <p>Swipe d'utilisateur pour une entreprise</p>
          <Pie data={dataSwipeUser} />
        </div>
        <div className="border-2 rounded p-2">
          <p>Swipe d'utilisateur pour une offre d'emploi</p>
          <Pie data={dataSwipeUserToJobs} />
        </div>
        <div className="border-2 rounded p-2">
          <p>Swipe d'une entreprise pour un utilisateur</p>
          <Pie data={dataSwipeCompanyToUser} />
        </div>
        <div className="border-2 rounded p-2 col-span-3">
          <p>Resultat des matchs</p>
          <Doughnut data={dataRoom} />
        </div>


      </div>
    </div>
  );
};

export default DataAdmin;
