import * as md5 from 'md5';
import { getAuthToken } from '../shared/function/generic';
import request from '../shared/lib/AxiosWrap';

async function token() {
  return request({
    url: `/v1/recruiter/account/token`,
    method: 'GET',
    headers: { Authorization: getAuthToken() },
  });
}

async function login(email, password) {
  return request({
    url: `/v1/recruiter/account/login`,
    method: 'POST',
    data: {
      email: email,
      password: md5(password),
    },
  });
}

async function register(email, password, firstName, lastName, fonction) {
  return request({
    url: `/v1/recruiter/account/register`,
    method: 'POST',
    data: {
      email: email,
      password: md5(password),
      firstName: firstName,
      lastName: lastName,
      fonction: fonction,
    },
  });
}

async function updateUser(firstName, lastName, fonction) {
  return request({
    url: `/v1/recruiter/account/update`,
    method: 'POST',
    data: {
      firstName: firstName,
      lastName: lastName,
      fonction: fonction,
    },
    headers: { Authorization: getAuthToken() },
  });
}

async function updatePassword(password) {
  return request({
    url: `/v1/recruiter/account/updatepassword`,
    method: 'POST',
    data: {
      password: md5(password),
    },
    headers: { Authorization: getAuthToken() },
  });
}

async function forgotPassword(email) {
  return request({
    url: `/v1/recruiter/account/forgotpwd`,
    method: 'POST',
    data: {
      email: email,
    },
  });
}
async function updateForgotPassword(password, id) {
  return request({
    url: `/v1/recruiter/account/forgotpwd/` + id,
    method: 'POST',
    data: {
      password: md5(password),
    },
  });
}

async function forgotPasswordUser(email) {
  return request({
    url: `/v1/users/account/forgotpwd`,
    method: 'POST',
    data: {
      email: email,
    },
  });
}

async function updateForgotPasswordUser(password, id) {
  return request({
    url: `/v1/users/account/forgotpwd/` + id,
    method: 'POST',
    data: {
      password: password,
    },
  });
}

const accountServices = {
  login,
  register,
  token,
  updateUser,
  updatePassword,
  forgotPassword,
  forgotPasswordUser,
  updateForgotPassword,
  updateForgotPasswordUser,
};

export default accountServices;
